import moment from "moment";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import axios from "axios";
const Map = forwardRef(({ ...props }, ref) => {
  const mapRef = useRef(null);
  useEffect(() => {
    // googleMap();
  }, []);

  useImperativeHandle(ref, () => ({
    googleMap(data) {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBuSl-d3X4h8vTSAkJcG57Qdjpr-lA9XNI&libraries=geometry`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);

      googleMapScript.addEventListener("load", () => {
        const map = new window.google.maps.Map(mapRef.current, {
          center: { lat: 13.0827, lng: 80.2707 },
          zoom: 18,
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        });

        var infowindow = new window.google.maps.InfoWindow();
        for (let j = 0; j < data.length; j++) {
          let waypoints = [];
          let markers = [];
          const latlng = data[j].start_time_latlong.split(",");
          const clickedLat = parseFloat(latlng[0]);
          const clickedLng = parseFloat(latlng[1]);
          // debugger
          map.setCenter({
            lat: clickedLat,
            lng: clickedLng,
          });

          const marker = new window.google.maps.Marker({
            position: {
              lat: parseFloat(clickedLat),
              lng: parseFloat(clickedLng),
            },
            map,
          });

          markers.push({ lat: clickedLat, lng: clickedLng });
          waypoints.push({
            location: { lat: clickedLat, lng: clickedLng },
            stopover: true,
          });

          window.google.maps.event.addListener(
            marker,
            "click",
            (function (marker) {
              return function () {
                var contentString = "Project Name: " + data[j].project_name;
                infowindow.setContent(contentString);
                infowindow.open(map, marker);
              };
            })(marker)
          );
        }
      });
    },
  }));
  return <div style={{ height: "500px" }} ref={mapRef} />;
});

export default Map;
