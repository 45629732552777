import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";

import {
  Button,
  Grid,
  Typography,
  Tooltip,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import axios from "axios";
import Layout from "../../layout/Layout";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { config, employeeAttandenceAPI, handleHrMasterAPI } from "../../config";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
//tab library
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { _getDropdownListData } from "../../service";
import { LoadingButton } from "@mui/lab";
import DataTable from "react-data-table-component";

function EmployeeAttendance() {
  const navigate = useNavigate();
  const limit = 10;

  const [attendanceReport, setAttendanceReport] = useState([]);
  const [monthlyAttendanceReport, setMonthlyAttendanceReport] = useState([]);

  const downloadDailyAttendanceExcel = () => {
    const data = JSON.stringify({
      txtStartTime: dayjs(fromDate).format("YYYY-MM-DD"),
      txtEndTime: dayjs(toDate).format("YYYY-MM-DD"),
      intEmployeeID: employeeID,
      intType: 6,
      intLimit: totalCount, 
      intOffset: 0,
    });

    config.method = "POST";
    config.data = data;
    config.url = employeeAttandenceAPI;

    axios(config)
      .then(function (response) {
        console.log("resposne", response.data.result);
        const newData = response.data.result.map((row) => {
          return {
            'Employee Name': row.employee_name,
            'First In': row.start_time
              ? moment(row.start_time).format("MMM DD, YYYY, hh:mm a")
              : "NA",
            'Last Out': row.end_time
              ? moment(row.end_time).format("MMM DD, YYYY, hh:mm a")
              : "NA",
            'Projects': row.projects || "NA", // Assuming projects are in row.projects
            'Total Hours': row.total_hours || "NA", // Assuming total hours are in row.total_hours
            'Shift': !row.Shift
              ? "NA"
              : row.Shift == 1
              ? "Day Shift"
              : "Night Shift",
            'Attendance Status': row.attandence_status == 0
              ? "Leave"
              : row.attandence_status == 1
              ? "Full Day"
              : "Half Day"
          };
        });
  
        console.log(newData, "daily attendance data")

        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "students");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(
          workBook,
          `TT-ATTENDANCE-${moment().format("DD-MM-YYYY")}.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };

  const downloadMonthlyAttendanceExcel = () => {
    const data = JSON.stringify({
      txtStartTime: dayjs(fromDate).format("YYYY-MM-DD"),
      txtEndTime: dayjs(toDate).format("YYYY-MM-DD"),
      intEmployeeID: employeeID,
      intType: 7,
    });
  
    config.method = "POST";
    config.data = data;
    config.url = employeeAttandenceAPI;
  
    axios(config)
      .then(function (response) {
        const newData = response.data["result"].map((row) => {
          let fullDayCount = 0;
          let halfDayCount = 0;
          let leaveCount = 0;
  
          // Count the attendance types
          for (let day in row) {
            if (day !== "employee_name" && day !== "employee_id") {
              if (row[day] == 1) fullDayCount++;
              else if (row[day] == 2) halfDayCount++;
              else if (row[day] == 3) leaveCount++;
            }
          }
  
          const newRow = {
            employee_name: row.employee_name,
            fullDay: fullDayCount,
            halfDay: halfDayCount,
            leave: leaveCount,
          };
  
          // Add the attendance data for each day
          daysinMonth.forEach((day) => {
            newRow[day] =
              row[day] == 0
                ? "NA"
                : row[day] == 1
                ? "F"
                : row[day] == 2
                ? "H"
                : row[day] == 3
                ? "L"
                : moment(new Date()).format("DD") < day
                ? "-"
                : "NA";
          });
  
          return newRow;
        });
  
        // Create the Excel sheet
        const workSheetData = [
          [
            "Employee Name / Date",
            "Full Day",
            "Half Day",
            "Leave",
            ...daysinMonth.map((date) => {
              const currentDate = new Date(fromDate);
              let newDate = "";
              let display_date = "";
  
              if (date <= 26) {
                newDate = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  date
                );
                display_date = moment(newDate).format("ddd");
              } else {
                newDate = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth() - 1,
                  date
                );
                display_date = moment(newDate).format("ddd");
              }
  
              return `${date}\n${display_date}`;
            }),
          ],
        ];
  
        newData.forEach((row) => {
          const rowData = [
            row.employee_name,
            row.fullDay,
            row.halfDay,
            row.leave,
            ...daysinMonth.map((day) => row[day]),
          ];
          workSheetData.push(rowData);
        });
        const workSheet = XLSX.utils.aoa_to_sheet(workSheetData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Attendance");
  
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(
          workBook,
          `TT-ATTENDANCE-${moment().format("DD-MM-YYYY")}.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if ("2" == newValue) {
      handleMonthlyAttendanceReport();
      setDaysinMonth(new Date().getFullYear(), new Date().getMonth());
    } else {
      fetchAttendanceData(1);
    }
  };

  const [fromDate, setFromDate] = React.useState(dayjs().format("YYYY-MM-DD"));
  const [toDate, setToDate] = React.useState(dayjs().format("YYYY-MM-DD"));

  const [daysinMonth, handleDaysinMonth] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeID, setEmployeeID] = useState(0);

  let assetsRef = useRef(false);

  let temprefs1 = useRef(false);

  // useEffect(() => {
  //   if (temprefs1.current === false) {
  //     fetchAttendanceData(1, 6);
  //   }
  // }, []);

  const fetchAttendanceData = async (page, rowsPerPage, type = 6) => {
    // if (temprefs1.current === false) {
      const data = JSON.stringify({
        intLimit: rowsPerPage ? rowsPerPage : 10,
        intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
        txtStartTime: dayjs(fromDate).format("YYYY-MM-DD"),
        txtEndTime: dayjs(toDate).format("YYYY-MM-DD"),
        intEmployeeID: employeeID,
        intType: type,
      });
      console.log(data)
      config.method = "POST";
      config.data = data;
      config.url = employeeAttandenceAPI;
      axios(config)
        .then(function (response) {
          console.log(response.data);
          setAttendanceReport(response.data["result"]);
          setTotalCount(response.data.totalCount);
        })
        .catch(function (error) {
          console.log(error);
        });
    // }
    // temprefs1.current = true;
  };

  const handleMonthlyAttendanceReport = () => {
    const data = JSON.stringify({
      txtStartTime: dayjs(fromDate).format("YYYY-MM-DD"),
      intType: 7,
    });

    config.method = "POST";
    config.data = data;
    config.url = employeeAttandenceAPI;
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setMonthlyAttendanceReport(response.data["result"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    fetchAttendanceData(page, rowsPerPage);
    // downloadExcel(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!employeeList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchAttendanceData again
    temprefs1.current = false;
    fetchAttendanceData(1, newRowsPerPage);
    // downloadExcel(1, rowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // if (assetsRef.current === false) {
      const data = JSON.stringify({
        intType: 7,
      });

      config.method = "POST";
      config.data = data;
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          // console.log(response.data)
          setEmployeeList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        assetsRef.current = true;
      };
    // }
  }, []);

  // const hanldeAttendanceReport = () => {

  //   setDaysinMonth(new Date().getFullYear(), new Date().getMonth());

  //   const data = JSON.stringify({
  //     txtStartTime: dayjs(fromDate).format("YYYY-MM-DD HH:mm:ss"),
  //     txtEndTime: dayjs(toDate).format("YYYY-MM-DD HH:mm:ss"),
  //     intEmployeeID: employeeID,
  //     intType: 9,
  //   });

  //   config.method = "POST";
  //   config.data = data;
  //   config.url = employeeAttandenceAPI;

  //   axios(config)
  //     .then(function (response) {
  //       setAttendanceReport(response.data.result);
  //       setTotalCount(response.data.totalCount);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });

  // };

  const setDaysinMonth = (year, month) => {
    let daysInPreviousMonth = new Date(year, month, 0).getDate();
    console.log(year, month);
    let days = [];

    for (let i = 26; i <= daysInPreviousMonth; i++) {
      if (i < 10) {
        i = "0" + i;
      }
      days.push("" + i);

      if (daysInPreviousMonth == i) {
        for (let j = 1; j < 26; j++) {
          if (j < 10) {
            j = "0" + j;
          }
          days.push("" + j);
        }
      }
      handleDaysinMonth(days);
    }
  };

  const columns = useMemo(() => [
    {
      name: "Employee Name",
      field: "id",
      sortable: true,
      width: "22%",
      selector: (row) => row.employee_name,
    },
    // {
    //   name: "Date",
    //   field: "id",
    //   sortable: true,
    //   selector: row => row.date ? row.date : "NA",

    // },
    {
      name: "First In",
      field: "id",
      width: "20%",
      sortable: true,
      selector: (row) =>
        row.start_time
          ? moment(row.start_time).format("MMM DD, YYYY, hh:mm a")
          : "NA",
    },
    {
      name: "Last Out",
      field: "id",
      width: "20%",
      sortable: true,
      selector: (row) =>
        row.end_time
          ? moment(row.end_time).format("MMM DD, YYYY, hh:mm a")
          : "NA",
    },
    {
      name: "Project(s) Name",
      field: "id",
      sortable: true,
      selector: (row) =>
        row.projects ? (
          <Tooltip title={row.projects}>
            <InfoIcon className="icon_colorinfo" />
          </Tooltip>
        ) : (
          "NA"
        ),
    },
    {
      name: "Total Hours",
      field: "id",
      sortable: true,
      width: "15%",
      selector: (row) => (row.working_hours ? row.working_hours : "NA"),
    },
    {
      name: "Shift",
      field: "id",
      width: "12%",
      sortable: true,
      selector: (row) =>
        !row.Shift ? "NA" : row.Shift == 1 ? "Day Shift" : "Night Shift",
    },
    {
      name: "Attendance Status",
      field: "id",
      cell: (row) =>
        !row.attandence_status ? (
          row.start_time ? (
            <IconButton
              variant="outlined"
              className="align-cancelbutton"
              color="success"
            >
              <DoneIcon />
            </IconButton>
          ) : (
            <IconButton
              variant="outlined"
              className="align-cancelbutton"
              color="error"
            >
              <CloseIcon />
            </IconButton>
          )
        ) : row.attandence_status == 1 ? (
          "Full Day"
        ) : row.attandence_status == 2 ? (
          "Half Day"
        ) : (
          "Leave"
        ),
    },
  ]);

  const [currentDate, setCurrentDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );
  // console.log(monthlyAttendanceReport);
  return (
    <Layout>
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Employee Attendance
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Employee</Typography>
              <Typography color="text.primary">Attendance</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid className="form_design">
          <Grid>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="attendance-report"
                  >
                    <Tab label="Daily Attendance Report" value="1" />
                    <Tab label="Monthly Attendance Report" value="2" />
                    {/* <Tab label="Yearly Attendance Report" value="3" />
                    <Tab label="Employee Project Report" value="4" /> */}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {/* <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Employee Name</StyledTableCell>
                        <StyledTableCell align="right">First In</StyledTableCell>
                        <StyledTableCell align="right">Last Out</StyledTableCell>
                        <StyledTableCell align="right">Total Hours</StyledTableCell>
                        <StyledTableCell align="right">Shift</StyledTableCell>
                        <StyledTableCell align="right">Attendance Status</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      {attendanceReport.length > 0 ? attendanceReport.map((row) => (
                        <>
                          <StyledTableRow key={row.employee_name}>
                            <StyledTableCell component="th" scope="row">
                              {row.employee_name}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.start_time ? moment.utc(row.start_time).utcOffset("-02:30").format("MMM DD, YYYY, hh:mm A") : "-"}</StyledTableCell>
                            <StyledTableCell align="right">{row.end_time ? moment.utc(row.end_time).utcOffset("-02:30").format("MMM DD, YYYY, hh:mm A") : "-"}</StyledTableCell>
                            <StyledTableCell align="right">{row.working_hours ? row.working_hours : "-"}</StyledTableCell>
                            <StyledTableCell align="right">{(row.Shift) == 1 ? "Day Shift" : "Night Shift"}</StyledTableCell>
                            <StyledTableCell align="right">{(row.attandence_status) == 0 ? "leave" : (row.attandence_status) == 1 ? "Full Day" : "Half Day"}</StyledTableCell>
                          </StyledTableRow>
                        </>
                      )) :
                        <></>}
                    </TableBody>
                  </Table>
                </TableContainer> */}
                  <Grid className="excel_button" item xs={12} lg={12} md={12}>
                    <Button
                      className="btn-primary"
                      onClick={() => downloadDailyAttendanceExcel()}
                    >
                      Export to Excel
                    </Button>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    margin={`15px 0px`}
                    padding={`10px 15px 0px 0px`}
                  >
                    <Grid item xs={12} lg={4} md={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        format="DD-MM-YYYY"
                      >
                        <MobileDatePicker
                          label="Select Date"
                          value={fromDate}
                          inputFormat="DD/MM/YYYY"
                          fullWidth
                          views={
                            value == "1"
                              ? ["year", "month", "day"]
                              : ["year", "month"]
                          }
                          onChange={(e) => {
                            setFromDate(e);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        format="YYYY-MM-DD"
                      >
                        <MobileDatePicker
                          label="To Date"
                          value={toDate}
                          inputFormat="DD/MM/YYYY"
                          fullWidth
                          views={
                            value == "1"
                              ? ["year", "month", "day"]
                              : ["year", "month"]
                          }
                          onChange={(e) => {
                            setToDate(e);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    {value == 1 && (
                      <Grid item xs={12} md={4} lg={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Employee List
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={employeeID}
                            inputFormat="DD/MM/YYYY"
                            label="Employee List"
                            onChange={(e) => {
                              setEmployeeID(e.target.value);
                            }}
                          >
                            {employeeList.map((row) => (
                              <MenuItem key={row.id} value={row.id}>
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} lg={12} md={12} textAlign="right">
                      <LoadingButton
                        size="large"
                        variant="contained"
                        className="button_yellow button_first"
                        onClick={() => {
                          setEmployeeID(0);
                          temprefs1.current = false;
                          fetchAttendanceData(1);
                        }}
                      >
                        Clear
                      </LoadingButton>
                      <LoadingButton
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          temprefs1.current = false;
                          fetchAttendanceData(1);
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <Grid>
                    <DataTable
                      pagination
                      paginationServer
                      // fixedHeader
                      fixedHeaderScrollHeight="550px"
                      columns={columns}
                      data={attendanceReport}
                      progressPending={loading}
                      paginationTotalRows={totalCount}
                      paginationDefaultPage={currentPage}
                      paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
                      paginationPerPage={rowsPerPage}
                      onChangePage={handlePageChange}
                      // onSort={handleSort}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                    />
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid
                    container
                    spacing={2}
                    margin={`15px 0px`}
                    padding={`10px 15px 0px 0px`}
                  >
                    <Grid item xs={12} lg={4} md={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        format="DD-MM-YYYY"
                      >
                        <MobileDatePicker
                          label="Select Date"
                          value={fromDate}
                          inputFormat="DD/MM/YYYY"
                          fullWidth
                          views={
                            value == "1"
                              ? ["year", "month", "day"]
                              : ["year", "month"]
                          }
                          onChange={(e) => {
                            setFromDate(e);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} textAlign="right">
                      <LoadingButton
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          temprefs1.current = false;
                          handleMonthlyAttendanceReport();
                          setDaysinMonth(
                            new Date(fromDate).getFullYear(),
                            new Date(fromDate).getMonth()
                          );
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <Grid className="excel_button" item xs={12} lg={12} md={12}>
                    <Button
                      className="btn-primary"
                      onClick={() => downloadMonthlyAttendanceExcel()}
                    >
                      Export to Excel
                    </Button>
                  </Grid>

                  <h1>
                    {new Date(fromDate).toLocaleString("default", {
                      month: "long",
                    })}{" "}
                    Attendance List
                  </h1>
                  <Grid overflow="scroll">
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            Employee Name / Date
                          </StyledTableCell>
                          <StyledTableCell>Full Day</StyledTableCell>
                          <StyledTableCell>Half Day</StyledTableCell>
                          <StyledTableCell>Leave</StyledTableCell>
                          {daysinMonth.map((date) => {
                            let newDate = "";
                            let display_date = "";
                            const currentDate = new Date(fromDate);

                            if (date <= 26) {
                              newDate = new Date(
                                currentDate.getFullYear(),
                                currentDate.getMonth(),
                                date
                              );
                              display_date = moment(newDate).format("ddd");
                            } else {
                              newDate = new Date(
                                currentDate.getFullYear(),
                                currentDate.getMonth() - 1,
                                date
                              );
                              display_date = moment(newDate).format("ddd");
                            }

                            return (
                              <StyledTableCell>
                                <span>
                                  {date}
                                  {/* {console.log(newDate)} */}
                                  {"\n"}
                                  {display_date}
                                </span>
                              </StyledTableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {monthlyAttendanceReport.length > 0 ? (
                          monthlyAttendanceReport.map((employee) => {
                            let fullday = 0;
                            let halfday = 0;
                            let leave = 0;

                            for (let key in employee) {
                              if (employee[key] === 1) {
                                fullday++;
                              } else if (employee[key] === 2) {
                                halfday++;
                              } else if (employee[key] === 3) {
                                leave++;
                              }
                            }
                            return (
                              <StyledTableRow key={employee.employee_name}>
                                <StyledTableCell align="right">
                                  <Link
                                    href={`/employee-attendance/${employee.employee_id}`}
                                  >
                                    {employee.employee_name}
                                  </Link>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {fullday}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {halfday}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {leave}
                                </StyledTableCell>
                                {daysinMonth.map((day) =>
                                  Object.keys(employee).map(
                                    (attandence_date) => {
                                      var d = new Date();
                                      var newDate = new Date(
                                        d.getFullYear(),
                                        d.getMonth(),
                                        day
                                      );

                                      return (
                                        day == attandence_date && (
                                          <StyledTableCell align="right">
                                            {employee[day] == 0 ? (
                                              "NA"
                                            ) : employee[day] == 1 ? (
                                              <a
                                                className="full-day-attendance"
                                                onClick={(e) => {
                                                  navigate(
                                                    `/employee-attendance-report/${employee.employee_id +
                                                    "-" +
                                                    moment(newDate).format(
                                                      "YYYY.MM.DD"
                                                    )
                                                    }`
                                                  );
                                                }}
                                              >
                                                F
                                              </a>
                                            ) : employee[day] == 2 ? (
                                              <a
                                                className="full-day-attendance"
                                                onClick={(e) => {
                                                  navigate(
                                                    `/employee-attendance-report/${employee.employee_id +
                                                    "-" +
                                                    moment(newDate).format(
                                                      "YYYY.MM.DD"
                                                    )
                                                    }`
                                                  );
                                                }}
                                              >
                                                H
                                              </a>
                                            ) : employee[day] == 3 ? (
                                              <span className="leave-attendance">
                                                L
                                              </span>
                                            ) : moment(new Date()).format(
                                              "DD"
                                            ) < day ? (
                                              "-"
                                            ) : newDate.getDay() == 0 ? (
                                              <span className="sunday-attendance">
                                                S
                                              </span>
                                            ) : (
                                              <span className="nodata-attendance">
                                                NA
                                              </span>
                                            )}
                                          </StyledTableCell>
                                        )
                                      );
                                    }
                                  )
                                )}
                              </StyledTableRow>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                </TabPanel>
                <TabPanel value="3">
                  <Grid
                    container
                    spacing={2}
                    margin={`15px 0px`}
                    padding={`10px 15px 0px 0px`}
                  >
                    <Grid item xs={12} lg={4} md={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        format="DD-MM-YYYY"
                      >
                        <MobileDatePicker
                          label="From Date"
                          value={fromDate}
                          inputFormat="DD/MM/YYYY"
                          fullWidth
                          views={["year", "month"]}
                          onChange={(e) => {
                            setFromDate(e);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        format="YYYY-MM-DD"
                      >
                        <MobileDatePicker
                          label="To Date"
                          value={toDate}
                          inputFormat="DD/MM/YYYY"
                          fullWidth
                          views={["year", "month"]}
                          onChange={(e) => {
                            setToDate(e);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} textAlign="right">
                      <LoadingButton
                        size="large"
                        variant="contained"
                        className="button_yellow button_first"
                        onClick={() => {
                          temprefs1.current = false;
                          fetchAttendanceData(1, 10, 8);
                        }}
                      >
                        Clear
                      </LoadingButton>
                      <LoadingButton
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          temprefs1.current = false;
                          fetchAttendanceData(1, 10, 10);
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Typography></Typography>
                  </Grid>
                </TabPanel>
                <TabPanel value="4">
                  <Grid
                    container
                    spacing={2}
                    margin={`15px 0px`}
                    padding={`10px 15px 0px 0px`}
                  >
                    <Grid item xs={12} md={4} lg={4}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Employee List
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={employeeID}
                          label="Employee List"
                          onChange={(e) => {
                            setEmployeeID(e.target.value);
                          }}
                        >
                          {employeeList.map((row) => (
                            <MenuItem key={row.id} value={row.id}>
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        format="DD-MM-YYYY"
                      >
                        <MobileDatePicker
                          label="Date of Birth"
                          value={currentDate}
                          inputFormat="DD/MM/YYYY"
                          fullWidth
                          // minDate={new Date("02-01-2020")}
                          // maxDate={new Date("02-29-2020")}
                          onChange={(newValue) => {
                            setCurrentDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                            // helperText={errors.txtDob?.message}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <LoadingButton
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          temprefs1.current = false;
                          fetchAttendanceData(1);
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default EmployeeAttendance;
