import React, { useEffect, useRef } from "react";

import Layout from "../../../layout/Layout";
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

import { useState } from "react";
import { config, statusMasterAPI, termsAndConditionsAPI } from "../../../config";
import axios from "axios";
import { AlertMessage } from "../../../components/AlertMessage";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

// {
//   //  <script src="https://cdn.tiny.cloud/1/x8aqjkiexwpgpg2hkdzkcwfjjpvryho0lnk3b8bl4cmj37pb/tinymce/6/tinymce.min.js"></script> 
// }

function TermsConditionsEdit() {
  const childRef = useRef();
  const [txtTitle, setTitle] = useState("");
  const { termsID } = useParams();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    // txtClientName: Yup.string().required("Client Name is required"),
    // txtEmail: Yup.string().required("Emali ID is required"),
    // intMobileNumber: Yup.string().required("Mobile Number is required"),
    // intSecMobileNumber: Yup.string().required(
    //   "Secondary Mobile Number is required"
    // ),
    // txtVendorCode: Yup.string().required("Vendor Code is required"),
    // txtClientType: Yup.string().required("Client Type is required"),
    // txtClientGST: Yup.string().required("Client GST Number is required"),
    // txtClientPAN: Yup.string().required("Client PAN Number is required"),
    // txtAddress: Yup.string().required("Address is required"),
    // txtCity: Yup.string().required("State / City is required"),
    // intPincode: Yup.string().required("Pincode is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;


  const handleSubmit = () => {
    // editing text area items
    let invDesc = window.tinymce.get("textarea").getContent();
    let newInvDesc = invDesc.replaceAll("<p>", "");
    let finalInvDesc = newInvDesc.replaceAll("</p>", "");
    // debugger;
    const data = JSON.stringify({
      intID: termsID,
      txtTitle: txtTitle,
      intStatus: termsStatus,
      txtDescription: finalInvDesc,
      intType: 2,
    });
    config.method = "POST";
    config.data = data;
    config.url = termsAndConditionsAPI;

    axios(config)
      .then(function (response) {
        let messageType = "success";

        if (response.data.code === 500) {
          messageType = "warning";
        }

        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: messageType,
          })
        );

        setTimeout(function () {
          navigate("/termsandconditions/list");
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(function () {
    const data = JSON.stringify({
      intID: termsID,
      intType: 4,
    });
    config.method = "POST";
    config.data = data;
    config.url = termsAndConditionsAPI;

    axios(config)
      .then(function (response) {
        console.log({Response: response.data.result[0]});
        setTermsStatus(response.data.result[0].status)
        setTitle(response.data.result[0].title);
        window.tinymce.init({
          selector: "#textarea",
          width: "100%",
          menubar: false,
          plugins:
            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
          toolbar:
            "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
        });
        setTimeout(function () {
          window.tinymce
            .get("textarea")
            .setContent(response.data.result[0].description);
        }, 1150);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  let assetsRef = useRef(false);

  const [termsStatus, setTermsStatus] = useState("")
  const [termsStatusList, setTermsStatusList] = React.useState([]);

  useEffect(() => {
    if (assetsRef.current === false) {
      const data = {
        intType: 2,
        intParentId: 1,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          console.log({ "response": response.data.result})
          setTermsStatusList(response.data.result);
          // console.log(clientStatusList);
          // return;
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        assetsRef.current = true;
      }
    }
  }, [])


  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Terms and Conditions
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Terms and Conditions</Typography>
              <Typography color="text.primary">Edit</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="secondary"
              className="button_first"
              href="/termsandconditions/list"
            >
              List Terms and Conditions
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            textAlign={`right`}
            display={`flex`}
            justifyContent={`flex-end`}
          >
            <TextField
              fullWidth
              value={txtTitle}
              onChange={(e) => setTitle(e.target.value)}
              label="Title"
            />
          </Grid>
          <div className="empty-space-20"></div>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            textAlign={`right`}
            display={`flex`}
            justifyContent={`flex-end`}
          >
            <TextField
              fullWidth
              id="textarea"
              onChange={(e) => console.log(e.target.value)}
            />
          </Grid>
          {/* terms and condition status */}
          <div className="empty-space-20"></div>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl
                    fullWidth
                    error={errors.intTermsStatus ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">Client Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Client Status"
                      id="demo-simple-select"
                      {...register("intTermsStatus")}
                      value={termsStatus}
                      onChange={(e) => {
                        setTermsStatus(e.target.value);
                        setValue("intTermsStatus", e.target.value);
                      }}
                    >
                      {termsStatusList.map((row) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="invalid-feedback">
                      {errors.intTermsStatus?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

          <div className="empty-space-20"></div>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            textAlign={`right`}
            display={`flex`}
            justifyContent={`flex-end`}
          >
            <Button
              variant="primary"
              className={`btn-primary`}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default TermsConditionsEdit;
