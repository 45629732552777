import {
  AppBar,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  MenuItem,
  Menu,
  Grid,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";

import UserAvatar from "../components/UserAvatar";
import {
  MenuOpen as MenuOpenIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
//Avatar
import Divider from "@mui/material/Divider";
import { userData } from "../config";

// const StyledBadge = styled(Badge)(({ theme }) => ({
//   "& .MuiBadge-badge": {
//     backgroundColor: "#44b700",
//     color: "#44b700",
//     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//     "&::after": {
//       position: "absolute",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: "100%",
//       borderRadius: "50%",
//       animation: "ripple 1.2s infinite ease-in-out",
//       border: "1px solid currentColor",
//       content: '""',
//     },
//   },
//   "@keyframes ripple": {
//     "0%": {
//       transform: "scale(.8)",
//       opacity: 1,
//     },
//     "100%": {
//       transform: "scale(2.4)",
//       opacity: 0,
//     },
//   },
// }));

// const SmallAvatar = styled(Avatar)(({ theme }) => ({
//   width: 22,
//   height: 22,
//   border: `2px solid ${theme.palette.background.paper}`,
// }));

function Header(props) {
  const [appBarPosition, setAppBarPosition] = useState("static");
  const settings = ["Profile", "Account", "Dashboard", "Logout"];
  const [userName, setUserName] = useState("Test");
  const [userEmail, setUserEmail] = useState("test@gmail.com");
  //   const [menuOpenedFlag, setMenuOpenedFlag] = useState(false);

  // const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    console.log(user.emp_id);
    return () => true;
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("projectID");
    window.location.reload(false);
    // navigate('/login')
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userName = JSON.parse(localStorage.getItem("user")).username
    console.log(userName)

    setUserName(userName);
    // setUserEmail(user.email);

    function handleTransparentNavbar() {
      setAppBarPosition("sticky");
      if (window.scrollY === 0) {
        setAppBarPosition("static");
      }
    }

    window.addEventListener("scroll", handleTransparentNavbar);

    return () => true;
  }, []);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };

  // Menu Avatar
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickAvatar = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar className={`appbar-design ${appBarPosition}`}>
        <Toolbar className="header_alignment" disableGutters>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="1rem"
            sx={{
              mr: 2,
              display: { xs: "none", md: "none" },
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Dashboard
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Grid
              display={`flex`}
              alignItems={`left`}
              gap={`15px`}
              className="menu-bar"
            >
              <Tooltip>
                <Grid
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                  display="flex"
                  alignItems="center"
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClickAvatar}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <UserAvatar name={userName} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 45,
                          height: 45,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 25,
                          width: 15,
                          height: 15,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {/* <MenuItem className="title_bold">
                      {userData.username}
                    </MenuItem> */}

                    {userData.role_id == 34 || userData.role_id == 35 ? (
                      <MenuItem>
                        <Link href={`/dashboard`} style={{ color: 'inherit', textDecoration: 'none' }}>
                          {userData.username}
                        </Link>
                      </MenuItem>
                    ) : (
                      <MenuItem href={`/`} className="title_bold">{userData.username}</MenuItem>
                    )}

                    {userData.role_id == 34 || userData.role_id == 35 ? (
                      <MenuItem>
                        <Link href={`/employee/view/${userData.emp_id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                          {userData.email}
                        </Link>
                      </MenuItem>
                    ) : (
                      <MenuItem href={`/employee/view/${userData.emp_id}`} className="title_bold">{userData.email}</MenuItem>
                    )}
                    <Divider />
                    <MenuItem className="email_menu" onClick={handleLogout}>
                      Logout
                    </MenuItem>
                  </Menu>
                </Grid>
              </Tooltip>
              <Grid className="menu_icon">
                {props.menuOpenedFlag ? (
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => props.menuAction()}
                    color="inherit"
                    className="btn"
                  >
                    <MenuIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => props.menuAction()}
                    color="inherit"
                    className="btn"
                  >
                    <MenuOpenIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              className={`profile-setting`}
            >
              <Grid className="profile-name">
                <UserAvatar name={userName} />
                <Typography fontWeight={600} fontSize="1rem">
                  Hii, {userName}
                </Typography>
                <Typography fontSize="0.85rem">{userEmail}</Typography>
              </Grid>
              <hr className="hrLine" />
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElNotification}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNotification)}
              onClose={handleCloseNotificationMenu}
              className={`profile-setting`}
            >
              <hr className="hrLine" />
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseNotificationMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            {/* Notification End */}
          </Box>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
    </>
  );
}

export default Header;
