import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import { config, departmentMasterAPI } from "../config";
import { AlertMessage } from "./AlertMessage";

export const AddDepartments = forwardRef(({ ...props }, ref) => {
  const [Status, setStatus] = useState(2),
    [DepartmentName, setDepartmentName] = useState(""),
    [DepartmentID, setDepartmentID] = useState(0)

  useImperativeHandle(ref, () => ({
    setData(data) {
      const tempData = JSON.parse(data);
      setDepartmentID(tempData.departmentID);
      setDepartmentName(tempData.txtDepartmentName);
      setStatus(tempData.status);
    },
  }));

  let tempRefs = useRef(false);

  useEffect(() => { }, []);

  const handleSubmit = () => {

    const data = JSON.stringify({
      txtDepartmentName: DepartmentName,
      intStatus: Status,
      intDepartmentID: DepartmentID,
      intType: 1,
    });

    if ("" == DepartmentName) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Department Name Can't be empty",
          messageType: "error",
        })
      )
      return false;
    }

    config.method = "POST";
    config.data = data;
    config.url = departmentMasterAPI;
    axios(config)
      .then(function (response) {
        window.location.reload(false);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setDepartmentName("");
        setDepartmentID(0);
        setStatus(2);
        setDepartmentID(0);

        props.fetchData(1);
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: error.data.message,
            messageType: "error",
          })
        );
      });
  };

  const childRef = useRef();
  return (
    <Grid container spacing={2} className={`bg-white`}>
      <AlertMessage ref={childRef} />
      <Grid item xs={12} md={5} lg={5}>
        <FormControl className={`form-control`} fullWidth>
          <TextField
            id="DepartmentName"
            name="DepartmentName"
            label="Department Name"
            value={DepartmentName}
            onChange={(e) => setDepartmentName(e.target.value)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={5} lg={5}>
        <FormControl className={`form-control`} fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Status"
            value={Status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            name="ddlType"
          >
            <MenuItem value={`2`}>Active</MenuItem>
            <MenuItem value={`3`}>Inactive</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={2} lg={2} textAlign="right">
        <Button
          variant="primary"
          className={`btn-primary`}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
});