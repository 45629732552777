import * as React from "react";
import { styled } from "@mui/system";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red, purple } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import moment from "moment";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";

const GradientCard = styled(Card)(({ theme }) => ({
  background: `linear-gradient(to bottom, #240046, #5a189a, #9d4edd)`,
  color: theme.palette.getContrastText(purple[500]),
  borderRadius: "16px",
  boxShadow: theme.shadows[5],
}));

const WhiteTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledTableContainer = styled(TableContainer)({
  marginBottom: "16px",
  borderRadius: "16px",
});

const StyledTable = styled(Table)({
  minWidth: 300,
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.disabledBackground,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const truncateDescription = (description, wordLimit = 5) => {
  const words = description ? description.split(" ") : "-";
  if (words.length <= wordLimit) return description;
  return `${words.slice(0, wordLimit).join(" ")}...`;
};

const FormDescription = ({
  projectNames,
  descriptions,
  showFullDescription = false,
}) => {
  const splitAndFormatData = (value) => (value ? value.split("|") : ["-"]);

  const formatDescriptions = (projectNames, descriptions) => {
    const projectList = splitAndFormatData(projectNames);
    const descriptionList = splitAndFormatData(descriptions);
    return projectList.map((project, index) => {
      const description = descriptionList[index] || "-";
      return { project: project.trim(), description: description.trim() };
    });
  };

  return (
    <>
      {formatDescriptions(projectNames, descriptions).map((item, index) => (
        <Typography key={index} paragraph style={{ marginBottom: "8px" }}>
          <span style={{ fontWeight: "bold", fontSize: "0.900rem" }}>
            {item.project}
          </span>{" "}
          <span style={{ fontSize: "0.875rem" }}>
            :{" "}
            {showFullDescription
              ? item.description
              : truncateDescription(item.description)}
          </span>
        </Typography>
      ))}
    </>
  );
};



export default function EodReportCard({ data }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formatTime = (time) => {
    return moment(time).format("hh:mm a");
  };

  const handleEmailClick = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const subject = `EOD Report for ${data.employee_names} on ${moment(
      data.report_date
    ).format("DD-MM-YYYY")}`;

    const body = `
    Dear ${data.employee_names},

    I hope this email finds you well. Below is the End-of-Day (EOD) report for your activities on ${moment(
      data.report_date
    ).format("DD-MM-YYYY")}.

    Employee Name: ${data.employee_names}
    Department: ${data.department_names}
    Projects: ${data.project_names ? data.project_names
      .split("|")
      .map(
        (project, index) =>
          `(${project.trim()}) : ${data.descriptions.split("|")[index] || "-"}`
      )
      .join("\n") : "-"}
    Start Time: ${data.start_times ? data.start_times : "-"}
    End Time: ${data.end_times ? data.end_times : "-"}
    Total Hours of Working: ${
      data.hours_of_working ? data.hours_of_working : "-"
    }
    Check-in Descriptions: ${data.project_names ? data.project_names
      .split("|")
      .map(
        (project, index) =>
          `(${project.trim()}) : ${
            data.project_checkin_descriptions.split("|")[index] || "-"
          }`
      )
      .join("\n") : "-"}

    Best regards,
    ${user.username}
  `;

    const mailtoLink = `mailto:${data.emails}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  return (
    <GradientCard>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {data.employee_names.charAt(0)}
          </Avatar>
        }
        title={data.employee_names}
        subheader={
          <div>
            <WhiteTypography variant="subtitle4">
              {data.department_names}
            </WhiteTypography>
            <WhiteTypography variant="subtitle1">
              {moment(data.report_date).format("DD-MM-YYYY")}
            </WhiteTypography>
          </div>
        }
      />
      <CardMedia
        component="img"
        height="204"
        image={data.photos}
        alt={`${data.employee_names}-photo`}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <WhiteTypography
              variant="body2"
              color="text.secondary"
              style={{ fontWeight: "bold" }}
            >
              Project :
            </WhiteTypography>
          </Grid>
          <Grid item xs={12}>
            <WhiteTypography variant="body2" color="text.primary">
              {data.project_names
                ? data.project_names.replace(/\|/g, ",")
                : "-"}
            </WhiteTypography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 1 }} />
        <StyledTableContainer component={Paper}>
          <StyledTable>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={3} align="center">
                  <Typography variant="body2" color="text.secondary">
                    <strong>Timings</strong>
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="center">
                  <Typography variant="body2" color="text.secondary">
                    <strong>Start</strong>
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="body2" color="text.secondary">
                    <strong>End</strong>
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="body2" color="text.secondary">
                    <strong>Total</strong>
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              {data.start_times
                ? data.start_times.split("|").map((start, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <Typography variant="body2" color="text.secondary">
                          {start ? formatTime(start) : "-"}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography variant="body2" color="text.secondary">
                          {data.end_times &&
                          data.end_times.split("|")[index] !== undefined
                            ? formatTime(data.end_times.split("|")[index])
                            : "-"}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography variant="body2" color="text.secondary">
                          {data.hours_of_working &&
                          data.hours_of_working.split("|")[index]
                            ? data.hours_of_working.split("|")[index]
                            : "-"}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : "-"}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Divider sx={{ my: 1 }} />
        <WhiteTypography
          variant="body2"
          color="text.secondary"
          style={{
            fontWeight: "bold",
            fontSize: "1.1rem",
          }}
        >
          <strong>Check-in Description:</strong>{" "}
        </WhiteTypography>
        <WhiteTypography variant="body2" color="text.secondary">
          <FormDescription
            projectNames={data.project_names}
            descriptions={data.project_checkin_descriptions}
            showFullDescription={true} // Show full description here
          />
        </WhiteTypography>
        <Divider sx={{ my: 1 }} />
        <WhiteTypography
          variant="body2"
          color="text.secondary"
          style={{
            fontWeight: "bold",
            fontSize: "1.25rem",
          }}
        >
          <strong>Report:</strong>
        </WhiteTypography>
        <WhiteTypography variant="body2" color="text.secondary">
          <FormDescription
            projectNames={data.project_names}
            descriptions={data.descriptions}
          />
        </WhiteTypography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          aria-label="share"
          onClick={handleEmailClick}
          sx={{ color: (theme) => theme.palette.common.white }}
        >
          <AlternateEmailIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{ color: (theme) => theme.palette.common.white }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <WhiteTypography
            paragraph
            style={{
              fontWeight: "bold",
              fontSize: "1.25rem",
            }}
          >
            Full EOD Report:
          </WhiteTypography>
          <FormDescription
            projectNames={data.project_names}
            descriptions={data.descriptions}
            showFullDescription={true} // Show full description in Full EOD Report
          />
        </CardContent>
      </Collapse>
    </GradientCard>
  );
}
