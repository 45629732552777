import React from "react";
import { _getDropdownListData } from "../../service";
import { employeeAttandenceAPI } from "../../config";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { Box, Button, Grid, Paper } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Profile from "../../assets/images/profile.png";
import { useNavigate } from "react-router-dom";

function DashBoardAttendance() {
  const navigate = useNavigate();
  const ADMIN_USER = JSON.parse(localStorage.getItem("user"));
  // console.log(ADMIN_USER);

  const [AttendanceData, setAttendanceData] = useState([]);
  useEffect(() => {
    let data = {
      requestData: {
        intType: 10,
        txtDate: moment().format("YYYY-MM-DD"),
      },
      API: employeeAttandenceAPI,
      setData: setAttendanceData,
      // loader: setLoading,
    };
    _getDropdownListData(data);
  }, []);

  // console.log(AttendanceData);

  function handleCardButtonClick(id) {
    navigate(`/employeeActivity/view/${id}`);
  }

  return (
    <>
      {(ADMIN_USER.role_id === 34 || ADMIN_USER.role_id === 35) && (
        <>
          <Typography
            sx={{
              fontSize: 35,
              textAlign: "center",
              margin: "auto",
              fontWeight: "bold",
              mb: 2.5,
            }}
          >
            Employee Activity
          </Typography>

          <Grid
            className="form_design"
            style={{ boxShadow: "0px 3px 6px #9d4edd" }}
          >
            <Typography
              sx={{
                fontSize: 33,
                // textAlign: "center",
                margin: "auto",
                mb: 2.5,
              }}
              color="text.secondary"
            >
              Present Employees
              <hr />
            </Typography>
            <Grid container spacing={2}>
              {AttendanceData.length > 0 &&
                AttendanceData.map((employee, id) =>
                  employee.start_time !== null ? (
                    <Grid item xs={6} sm={4} md={4} lg={3} xl={2} key={id}>
                      <div
                        onClick={() =>
                          handleCardButtonClick(employee.employee_id)
                        }
                        style={{
                          padding: "0", // Adjust padding as needed
                          borderRadius: "0", // Adjust border radius as needed
                          boxShadow: "none",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <Card
                          sx={{
                            minWidth: 170,
                            textAlign: "center",
                            boxShadow: "0px 2px 8px #74c69d",
                            "&:hover": {
                              boxShadow: "0px 7px 15px #440A67",
                              backgroundColor: "#d7ffe6", //"#b7efc5",
                            },
                          }}
                        >
                          <CardMedia
                            component="img"
                            style={{
                              height: "90px",
                              width: "auto",
                              display: "block",
                              margin: "10px auto 0",
                              border:
                                employee.start_time === null
                                  ? "0.5px solid red"
                                  : "0.5px solid green", // Set border color conditionally
                              borderRadius: "5rem",
                              backgroundColor: "#A7EDE7",
                            }}
                            image={employee.photo}
                            alt={employee.employee_name}
                          />
                          <CardContent>
                            <Typography
                              sx={{
                                mb: 0.1,
                                fontSize: 14,
                                fontWeight: "bold",
                              }}
                              component="div"
                            >
                              {employee.employee_name}
                            </Typography>
                            <hr style={{ border: "1px solid black" }} />

                            <Typography
                              variant="body2"
                              sx={{
                                mb: 0.5,
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                            >
                              In Time {"->"} {employee.in_time}
                              <hr />
                              Out Time {"->"} {employee.out_time}
                            </Typography>
                            <hr />

                            <Typography
                              sx={{ mb: 0.5, fontSize: 12, fontWeight: "bold" }}
                              color="text.secondary"
                            >
                              {employee.project_name}
                            </Typography>
                          </CardContent>

                          {/* {employee.start_time != null && (
                            <CardActions sx={{ justifyContent: "center" }}>
                              <Button
                                size="small"
                                onClick={() =>
                                  handleCardButtonClick(employee.employee_id)
                                }
                                sx={{
                                  padding: "5px 10px",
                                  mb: 1.5,
                                  backgroundColor: "#440A67",
                                  borderRadius: "10px",
                                  boxShadow: "none",
                                  color: "white",
                                  "&:hover": {
                                    color: "#fff",
                                    fontWeight: "bold",
                                    boxShadow: "0px 3px 6px #000",
                                    backgroundColor: "#40916c",
                                  },
                                }}
                              >
                                View Activity
                              </Button>
                            </CardActions>
                          )} */}
                        </Card>
                      </div>
                    </Grid>
                  ) : null
                )}
            </Grid>
          </Grid>

          {/* Display a heading for Absent Employees */}
          {/* {AttendanceData.some((employee) => employee.start_time === null) && (
            <Typography sx={{ fontSize: 33 }} color="text.secondary">
              Absent Employees
            </Typography>
          )} */}

          <Grid
            className="form_design"
            style={{ boxShadow: "0px 3px 6px #9d4edd" }}
          >
            <Typography
              sx={{
                fontSize: 33,
                // textAlign: "center",
                margin: "auto",
                mb: 2.5,
              }}
              color="text.secondary"
            >
              Absent Employees
              <hr />
            </Typography>
            <Grid container spacing={2}>
              {AttendanceData.length > 0 &&
                AttendanceData.map((employee, id) =>
                  employee.start_time === null ? (
                    <Grid item xs={6} sm={4} md={4} lg={3} xl={2} key={id}>
                      <div
                        style={{
                          padding: "0", // Adjust padding as needed
                          borderRadius: "0", // Adjust border radius as needed
                          boxShadow: "none",
                          backgroundColor: "transparent",
                          // cursor: "pointer",
                        }}
                      >
                        <Card
                          sx={{
                            minWidth: 140,
                            textAlign: "center",
                            boxShadow: "0px 2px 8px #ff4d6d",
                            "&:hover": {
                              boxShadow: "0px 3px 5px #d00000",
                              backgroundColor: "#fff0f3",
                            },
                          }}
                        >
                          <CardMedia
                            component="img"
                            style={{
                              height: "90px",
                              width: "auto",
                              display: "block",
                              margin: "10px auto 0",
                              border: "0.5px solid red",
                              borderRadius: "5rem",
                              backgroundColor: "#FAD4D4",
                            }}
                            image={employee.photo}
                            alt={employee.employee_name}
                          />
                          <CardContent>
                            <Typography
                              sx={{ fontSize: 13 }}
                              color="text.secondary"
                              gutterBottom
                            ></Typography>
                            <Typography
                              sx={{
                                mb: 0.1,
                                fontSize: 14,
                              }}
                              component="div"
                            >
                              {employee.employee_name}
                            </Typography>
                            <hr style={{ border: "1px solid black" }} />

                            <Typography
                              variant="body2"
                              sx={{
                                mb: 0.5,
                                fontSize: 12,
                              }}
                            >
                              In Time {"->"} {employee.in_time}
                              <hr />
                              Out Time {"->"} {employee.out_time}
                            </Typography>
                            <hr />

                            <Typography
                              sx={{ mb: 0.5, fontSize: 12, fontWeight: "bold" }}
                              color="text.secondary"
                            >
                              {employee.project_name}
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    </Grid>
                  ) : null
                )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default DashBoardAttendance;
