import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import {
  config,
  positionMasterAPI,
  statusMasterAPI,
  departmentMasterAPI,
} from "../config";
import { AlertMessage } from "./AlertMessage";

export const AddPosition = forwardRef(({ ...props }, ref) => {
  const [Status, setStatus] = useState(2),
    [positionName, setPositionName] = useState(""),
    [positionID, setPositionID] = useState(null),
    [roleID, setRoleID] = useState(""),
    [roleList, setRoleList] = useState([]);

  useImperativeHandle(ref, () => ({
    setData(data) {
      const tempData = JSON.parse(data);
      console.log(tempData);
      setPositionName(tempData.positionName);
      setPositionID(tempData.intPositionID);
      setStatus(tempData.status);
      setRoleID(tempData.intRoleID);
      setDepartmentname(tempData.intDepartmentID);
    },
  }));

  let tempRefs = useRef(false);

  //On Submit
  const handleSubmit = () => {
    const data = JSON.stringify({
      txtPositionName: positionName,
      intPositionStatus: Status,
      intDepartmentID: intDepartmentID,
      intRoleID: roleID,
      intPositionID: positionID,
      intType: 1,
    });

    if ("" == positionName) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Position Name Can't be empty",
          messageType: "error",
        })
      )
      return false;
    } else if (!intDepartmentID) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Department Name Can't be empty",
          messageType: "error",
        })
      )
      return false;
    } else if (!roleID) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Role Can't be empty",
          messageType: "error",
        })
      )
      return false;
    }
    
    config.method = "POST";
    config.data = data;
    config.url = positionMasterAPI;
    axios(config)
      .then(function (response) {
        window.location.reload(false);
        const tempData = response.data.message;
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setPositionName("");
        setRoleID(0);
        setDepartmentname(0);
        setStatus(2);
        props.fetchData(1);
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: error.data.message,
            messageType: "error",
          })
        );
      });
  };

  const childRef = useRef();

  //Department Details
  const [intDepartmentID, setDepartmentname] = useState(""); //Load Data
  const [Department, setDepartment] = useState([]); // Fetch Data
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 3,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = departmentMasterAPI;

      axios(config)
        .then(function (response) {
          setDepartment(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 33,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setRoleList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  return (
    <Grid container spacing={2} className={`bg-white`}>
      <AlertMessage ref={childRef} />
      <Grid item xs={12} md={3} lg={3}>
        <FormControl className={`form-control`} fullWidth>
          <TextField
            id="RoleName"
            name="RoleName"
            label="Position Name"
            value={positionName}
            onChange={(e) => setPositionName(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <FormControl className={`form-control`} fullWidth>
          <InputLabel id="demo-simple-select-label">Department</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Department"
            // {...register("txtGender")}
            value={intDepartmentID}
            onChange={(e) => {
              setDepartmentname(e.target.value);
              console.log(e.target.value);
            }}
            name="ddlType"
          >
            {Department.map((row, index) => (
              <MenuItem value={row.id}>{row.department_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <FormControl className={`form-control`} fullWidth>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Role"
            value={roleID}
            onChange={(e) => {
              setRoleID(e.target.value);
            }}
            name="ddlType"
          >
            {roleList.map((row, index) => (
              <MenuItem value={row.id}>{row.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <FormControl className={`form-control`} fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Status"
            value={Status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            name="ddlType"
          >
            <MenuItem value={`2`}>Active</MenuItem>
            <MenuItem value={`3`}>Inactive</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12} lg={12} textAlign="right">
        <Button
          variant="primary"
          className={`btn-primary`}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
});
