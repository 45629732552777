import React, { useEffect, useMemo, useRef } from "react";

//design imports from material
import {
  Grid,
  FormLabel,
  TextField,
  Typography,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";

//layout import
import Layout from "../../../layout/Layout";

//form imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//date picker
import dayjs from "dayjs";
import DataTable from "react-data-table-component";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";

import axios from "axios";
import {
  config,
  labourWagesAPI,
  statusMasterAPI,
  userData,
} from "../../../config";
import { AlertMessage } from "../../../components/AlertMessage";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

import { useNavigate } from "react-router-dom";
import { _getDropdownListData } from "../../../service";
import moment from "moment";

function LabourWages() {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    intLabourType: Yup.string().required("Labour Type is required"),
    txtLabourName: Yup.string().required("Labour Name is required"),
    intFullDayAmount: Yup.string().required("Full day Amount is required"),
    intHalfDayAmount: Yup.string().required("Half day Amount is required"),
    intMobileNumber: Yup.string().required(
      "^[0-9]{10}$",
      "Please Enter Valid Mobile Number"
    ),
  });

  const [labourList, setLabourList] = React.useState([]);

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const [labourTypesList, setLabourTypesList] = React.useState([])

  useEffect(() => {
    let labourData = {
      requestData: {
        intType: 2,
        intParentId: 46,
      },
      API: statusMasterAPI,
      setData: setLabourTypesList,
    };
    _getDropdownListData(labourData);
  }, []);

  function onSubmit(data) {
    data.intType = 1;
    config.method = "POST";
    data.intUserID = userData.emp_id;
    data.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
    config.data = JSON.stringify(data);
    config.url = labourWagesAPI;

    axios(config)
      .then(function (response) {
        handleFormReset();
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: response.data.messageType,
          })
        );
        setTimeout(function () {
          window.location.reload(true);
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    handleFormReset();
  }, []);

  const handleFormReset = () => {
    let defaultValues = {};
    // defaultValues.txtEndDate = endDate;
    reset({ ...defaultValues });
  };

  const statusStyle = {
    // display: 'flex',
    // fontSize: '20px',
    // fontWeight: 'bold',
    // gap: '10px',
    // width: "6rem",
    // align: "center"
  };

  const handleEditRole = (id) => {
    navigate(`/config/labour-wages/edit/${id}`);
  };

  const columns = useMemo(() => [
    {
      name: "Labour type",
      field: "id",
      // sortable: true,
      selector: (row) => (
        <>
          {
            row.labour_type == 48
              ? (
                <Typography variant="caption" align="center">Mason</Typography>
              )
              : row.labour_type == 49
                ? (
                  <Typography variant="caption" align="center">Mason Helper</Typography>
                )
                : row.labour_type == 50
                  ? (
                    <Typography variant="caption" align="center">Carpenter</Typography>
                  )
                  : row.labour_type == 51
                    ? (
                      <Typography variant="caption" align="center">Plumber</Typography>
                    )
                    : row.labour_type == 52
                      ? (
                        <Typography variant="caption" align="center">Electrician</Typography>
                      )
                      : row.labour_type == 53
                        ? (
                          <Typography variant="caption" align="center">Painter</Typography>
                        )
                        : ""
          }

        </>
      ),
    },
    {
      name: "Labour name",
      field: "id",
      // sortable: true,
      selector: (row) => row.labour_name,
    },
    {
      name: "Full day amount",
      field: "id",
      // sortable: true,
      selector: (row) => row.full_day_amount,
    },
    {
      name: "Half day amount",
      field: "id",
      // sortable: true,
      selector: (row) => row.half_day_amount,
    },
    {
      name: "Mobile number",
      field: "id",
      // sortable: true,
      selector: (row) => row.mobile_number,
    },
    {
      name: "Status",
      field: "id",
      // sortable: true,
      selector: (row) => (
        <>
          {
            row.status == 2
              ? (
                <Typography className={`active-status`} style={statusStyle} align="center">Active</Typography>
              )
              : row.status == 3
                ? (
                  <Typography className={`inactive-status`} style={statusStyle} align="center">Inactive </Typography>
                )
                : ""
          }
        </>
      ),
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <IconButton
          color="success"
          onClick={() => handleEditRole(row.id)}
          aria-label="add an alarm"
        >
          <EditLocationOutlinedIcon />
        </IconButton>
      ),
    },
  ]);

  let tempRefs = useRef(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = React.useState(1);
  // const [labourList, setLabourList] = React.useState([])

  useEffect(() => {
    // if (tempRefs.current === false) {
    fetchUsers();
    // }
  }, []);

  const fetchUsers = async () => {
    // if (tempRefs.current === false) {
    const data = JSON.stringify({
      // intLimit: rowsPerPage ? rowsPerPage : 10,
      // intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      intType: 4,
    });

    config.method = "POST";
    config.data = data;
    config.url = labourWagesAPI;
    // console.log(data);
    axios(config)
      .then(function (response) {
        console.log(response.data["result"]);
        setLabourList(response.data["result"]);
        // setTotalCount(response.data.totalCount);
      })
      .catch(function (error) {
        console.log(error);
      });
    // }
    // tempRefs.current = true;
  };

  // const handlePageChange = (page) => {
  //   tempRefs.current = false;
  //   fetchUsers(page, rowsPerPage);
  //   setCurrentPage(page);
  // };

  // const handleRowsPerPageChange = async (newRowsPerPage) => {
  //   if (!labourList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
  //   tempRefs.current = false;
  //   fetchUsers(1, newRowsPerPage);
  //   setRowsPerPage(newRowsPerPage);
  //   setCurrentPage(1);
  // };

  const childRef = useRef();

  return (
    <Layout>
      <Grid className="employee-form">
        <AlertMessage ref={childRef} />
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Labour
            </Typography>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>

        <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <FormControl
                fullWidth
                error={errors.intLabourType ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Labour Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Labour Type"
                  id="demo-simple-select"
                  {...register("intLabourType")}
                >
                  {labourTypesList.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="invalid-feedback">
                  {errors.intLabourType?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                type="text"
                fullWidth
                label="Labour Name"
                error={errors.txtLabourName ? true : false}
                {...register("txtLabourName")}
                helperText={errors.txtLabourName?.message}
              />
            </Grid>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                type="number"
                fullWidth
                label="Full Day Amount"
                error={errors.intFullDayAmount ? true : false}
                {...register("intFullDayAmount")}
                helperText={errors.intFullDayAmount?.message}
              />
            </Grid>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                type="number"
                fullWidth
                label="Half Day Amount"
                error={errors.intHalfDayAmount ? true : false}
                {...register("intHalfDayAmount")}
                helperText={errors.intHalfDayAmount?.message}
              />
            </Grid>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                type="number"
                fullWidth
                label="Mobile Number"
                error={errors.intMobileNumber ? true : false}
                {...register("intMobileNumber")}
                helperText={errors.intMobileNumber?.message}
              />
            </Grid>
          </Grid>
          <div className="empty-space-20"></div>
          <Grid
            item
            className="form-lable"
            xs={12}
            lg={12}
            md={12}
            marginTop="20px"
            textAlign="right"
          >
            <Button className={`btn-primary`} type="submit">
              Submit
            </Button>
          </Grid>
        </form>
        <div className="empty-space-20"></div>
        <Grid className="small_table">
          <DataTable
            pagination
            paginationServer
            fixedHeader
            fixedHeaderScrollHeight="550px"
            columns={columns}
            data={labourList}
            // progressPending={loading}
            // paginationTotalRows={totalCount}
            paginationDefaultPage={currentPage}
            paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
            paginationPerPage={rowsPerPage}
          // onChangePage={handlePageChange}
          // onSort={handleSort}
          // onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default LabourWages;
