import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import { Button, Grid, Typography, Tooltip } from "@mui/material";
import axios from "axios";
import Layout from "../../layout/Layout";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { config, projectMasterAPI } from "../../config";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import IconButton from "@mui/material/IconButton";

// import { FcOk, FcHighPriority, FcMediumPriority, FcApproval, FcCheckmark } from "react-icons/fc";

let field = "id",
  orderBy = "desc";

function ProjectMasterReport() {
  const navigate = useNavigate();

  const [roleMasterList, setroleMasterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (page, rowsPerPage) => {
    if (tempRefs.current === false) {
      const data = JSON.stringify({
        intLimit: rowsPerPage ? rowsPerPage : 10,
        intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
        intType: 3,
      });

      config.method = "POST";
      config.data = data;
      config.url = projectMasterAPI;
      axios(config)
        .then(function (response) {
          console.log(response.data)
          setroleMasterList(response.data["result"]);
          setTotalCount(response.data.totalCount);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    tempRefs.current = true
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!roleMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setTimeout(() => {
      field = column.field;
      orderBy = sortDirection;
      fetchUsers(1);
      setLoading(false);
    }, 100);
  };

  const handleEditRole = (id) => {
    navigate(`/project/edit/${id}`);
  };

  const projectStatus = (status) => {
    if (status == "114") {
      return "active"
    } else {
      return "inactive"
    }
  }

  const statusStyle = {
    // display: 'flex',
    // fontSize: '20px',
    // fontWeight: 'bold',
    // gap: '10px',
    width: "6rem",
    align: "center"
  };


  const columns = useMemo(() => [
    {
      name: "Name",
      field: "id",
      sortable: true,
      width: "22%",
      selector: (row) => row.name,
      // cell: (row) => row.employee_no,
    },
    {
      name: "Client Name",
      field: "id",
      sortable: true,
      width: "24%",
      selector: (row) => row.client_name,
      // cell: (row) => row.employee_name,
    },
    {
      name: "Company Name",
      field: "id",
      sortable: true,
      width: "17%",
      selector: (row) => row.company_name,
      // cell: (row) => row.secondary_number,
    },
    {
      name: "Type of Project",
      field: "id",
      sortable: true,
      width: "15%",
      selector: (row) => row.project_type,
      // cell: (row) => row.position_name,
    },
    {
      name: "Type of Activity",
      field: "id",
      sortable: true,
      width: "25%",
      selector: (row) => row.project_activity_type,
      // cell: (row) => row.position_name,
    },
    {
      name: "Project Status",
      field: "id",
      sortable: true,
      width: "15%",
      selector: (row) => (
        <>
          {row.status == 115 ? (
            <Typography
              className={`active-status`}
              style={statusStyle}
              align="center"
            >
              Active
            </Typography>
          ) : row.status == 137 ? (
            <Typography
              className={`inactive-status`}
              style={statusStyle}
              align="center"
            >
              Inactive{" "}
            </Typography>
          ) : row.status == 117 ? (
            <Typography
              className={`hold-status`}
              style={statusStyle}
              align="center"
            >
              Hold
            </Typography>
          ) : row.status == 118 ? (
            <Typography
              className={`completed-status`}
              style={statusStyle}
              align="center"
            >
              Completed
            </Typography>
          ) : (
            ""
          )}
        </>
      ),
      // cell: (row) => row.position_name,
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <IconButton
          color="success"
          onClick={() => handleEditRole(row.id)}
          aria-label="add an alarm"
        >
          <EditLocationOutlinedIcon />
        </IconButton>
      ),
    },
  ]);
  let defaultValues = {
    txtName: "",
    txtLOB: "",
    txtStatus: "",
  };
  const handleEdit = (id) => {
    // defaultValues.txtName = `Central`;
    // defaultValues.txtLOB = `1`;
    // defaultValues.txtStatus = `2`;
    // reset({ ...defaultValues });
    // setIsEdit(true);
  };

  // let intOffset = 0;
  // const limit = 10;
  // const [totalCount, setTotalCount] = useState(0);
  // const [roleMasterList, setroleMasterList] = useState([]);

  // const handleReportPagination = (e) => {
  //   console.log(e.target.textContent);
  //   // setIsDataLoading(true);
  //   intOffset =
  //     e.target.innerText === "1"
  //       ? 0
  //       : (parseInt(e.target.innerText) - 1) * limit;
  // };

  // let assetsRef = useRef(false);
  // const childRef = useRef();

  // useEffect(function () {
  //   if (assetsRef.current === false) {
  //     fetchData(1);
  //     return () => {
  //       assetsRef.current = true;
  //     };
  //   }
  // }, []);

  // const handleEditRole = (id) => {
  //   navigate(`/project/edit/${id}`);
  // };

  // const fetchData = (action) => {
  //   if (action === 1) {
  //     intOffset = 0;
  //   }

  //   const data = JSON.stringify({
  //     intLimit: limit,
  //     intOffset: 0,
  //     intType: 3,
  //   });

  //   config.method = "POST";
  //   config.data = data;
  //   config.url = projectMasterAPI;

  //   axios(config)
  //     .then(function (response) {
  //       setroleMasterList(response.data["result"]);
  //       setTotalCount(Math.round(response.data.totalCount / limit));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  return (
    <Layout>
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Project List
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Project</Typography>
              <Typography color="text.primary">List</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="light_Purple"
              className="button_first"
              href="/project/add"
              startIcon={<AddIcon />}
            >
              Add Project
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
          <Grid>
            <DataTable
              pagination
              paginationServer
              // fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={roleMasterList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              // onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        container
        spacing={2}
        margin={`15px 0px`}
        padding={`10px 15px 0px 0px`}
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          textAlign={`right`}
          display={`flex`}
          justifyContent={`flex-end`}
        >
          <Suspense fallback={<div></div>}>
            <Pagination
              totalCount={totalCount}
              handleReportPagination={handleReportPagination}
            />
          </Suspense>
        </Grid>
      </Grid>

      <div className={`table-responsive`}>
        <table className={`table`}>
          <thead>
            <th>
              <Typography variant="h6">S.No</Typography>
            </th>
            <th>
              <Typography variant="h6">Name</Typography>
            </th>
            <th>
              <Typography variant="h6">Client Name </Typography>
            </th>
            <th>
              <Typography variant="h6">Company Name </Typography>
            </th>
            <th>
              <Typography variant="h6">Type of Project </Typography>
            </th>
            <th>
              <Typography variant="h6">Type of Activity </Typography>
            </th>

            <th>
              <Typography variant="h6">Status </Typography>
            </th>
            <th>
              <Typography variant="h6">Action </Typography>
            </th>
          </thead>
          <tbody>
            {roleMasterList.length > 0 ? (
              roleMasterList.map((row, index) => {
                return (
                  <tr key={index}>
                    <td className={`text-center`}>
                      <Typography>{index + 1}</Typography>
                    </td>

                    <td>
                      <Typography>{row.name}</Typography>
                    </td>
                    <td>
                      <Typography>{row.client_name}</Typography>
                    </td>
                    <td>
                      <Typography>{row.company_name}</Typography>
                    </td>
                    <td>
                      <Typography>{row.type_of_project}</Typography>
                    </td>
                    <td>
                      <Typography>{row.type_of_activity}</Typography>
                    </td>

                    <td className={`text-center`}>
                      <Typography
                        className={`${
                          row.status === 2 ? `active-status` : `inactive-status`
                        } `}
                      >
                        {row.status_name}
                      </Typography>
                    </td>
                    <td className={`text-center`}>
                      <Tooltip title={`Edit Role`}>
                        <Create
                          cursor={`pointer`}
                          onClick={() => handleEditRole(row.id)}
                        />
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} className={`text-center`}>
                  <NotFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div> */}
    </Layout>
  );
}

export default ProjectMasterReport;
