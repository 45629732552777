import React from "react";
import { _getDropdownListData } from "../../service";
import {
  config,
  attandenceAPI,
  employeeAttandenceAPI,
  handleHrMasterAPI,
} from "../../config";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { Box, Button, Grid, Paper } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Profile from "../../assets/images/profile.png";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const DashBoardEmployeeActivityView = () => {
  const navigate = useNavigate();
  const { emp_id } = useParams();
  const [activityDetails, setActivityDetails] = useState([]);
  const [profile, setProfile] = useState([]);
  const [hrData, setHrData] = useState([]);
  useEffect(() => {
    let projectData = {
      requestData: {
        intType: 8,
        intEmployeeID: emp_id,
      },
      API: attandenceAPI,
      setData: setActivityDetails,
    };
    _getDropdownListData(projectData);

    let hrData = {
      requestData: {
        intType: 7,
      },
      API: handleHrMasterAPI,
      setData: setHrData,
    };
    _getDropdownListData(hrData);

    let profile = {
      requestData: {
        intType: 8,
        intEmployeeAutoID: emp_id,
      },
      API: handleHrMasterAPI,
      setData: setProfile,
    };
    _getDropdownListData(profile);



    let data = {
      requestData: {
        intType: 17,
        intEmployeeID: emp_id,
        txtDate: moment().format("YYYY-MM-DD"),
      },
      API: employeeAttandenceAPI,
      setData: setAttendanceData,
      // loader: setLoading,
    };
    _getDropdownListData(data);
  }, []);

  // useEffect(() => {
  //     const data = {
  //       intType: 5,
  //       // intParentId: 59,
  //     };

  //     config.method = "POST";
  //     config.data = JSON.stringify(data);
  //     config.url = clientMasterAPI;

  //     axios(config)
  //       .then(function (response) {
  //         console.log(response.data.result);
  //         setVendorList(response.data.result);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //     return () => {
  //       tempRefs.current = true;
  //     };
  // }, []);

  const [AttendanceData, setAttendanceData] = useState([]);

  // function calculateTimeDifference(startTime, endTime) {
  //   // Convert time values to 24-hour format
  //   const startTime24 = convert12HourTo24HourFormat(startTime);
  //   const endTime24 = convert12HourTo24HourFormat(endTime);

  //   // Parse time strings to Date objects
  //   const start = new Date(`2000-01-01 ${startTime24}`);
  //   const end = new Date(`2000-01-01 ${endTime24}`);

  //   const timeDifference = end - start;

  //   // Calculate hours, minutes, and seconds
  //   const hours = Math.floor(timeDifference / (60 * 60 * 1000));
  //   const minutes = Math.floor(
  //     (timeDifference % (60 * 60 * 1000)) / (60 * 1000)
  //   );
  //   const seconds = Math.floor((timeDifference % (60 * 1000)) / 1000);

  //   return `${hours}h:${minutes}m:${seconds}s`;
  // }

  // function convert12HourTo24HourFormat(time12Hour) {
  //   const [timePart, ampm] = time12Hour.split(" ");
  //   const [hours, minutes] = timePart.split(":");

  //   let hours24 = parseInt(hours);
  //   if (ampm === "PM" && hours24 !== 12) {
  //     hours24 += 12;
  //   } else if (ampm === "AM" && hours24 === 12) {
  //     hours24 = 0;
  //   }

  //   return `${hours24}:${minutes}`;
  // }

  function convertTimeFormat(timeString) {
    const [hours, minutes, seconds] = timeString.split(":");
    const formattedTime = `${parseInt(hours, 10)}h ${parseInt(
      minutes,
      10
    )}m ${parseInt(seconds, 10)}s`;
    return formattedTime;
  }
  function getTimeDifference(startTime, endTime) {
    const start = new Date(`2023-01-01 ${startTime}`);
    const end = new Date(`2023-01-01 ${endTime}`);

    const diffMilliseconds = end - start;
    const diffMinutes = diffMilliseconds / (1000 * 60);

    const hours = Math.floor(diffMinutes / 60);
    const minutes = Math.round(diffMinutes % 60);

    return `${hours}h ${minutes}m`;
  }

  console.log("AttendanceData", AttendanceData);
  console.log("profile", profile[0]);
  const activityArray = activityDetails;
  console.log("activityArray", activityDetails);

  return (
    <>
      <Layout>
        <Grid
          textAlign="right"
          item
          className="form-lable"
          xs={12}
          lg={12}
          md={12}
        >
          <LoadingButton
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </LoadingButton>
        </Grid>
        <Grid
          className="form_design"
          style={{ boxShadow: "0px 3px 6px #9d4edd" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card
                sx={{
                  minWidth: 140,
                  textAlign: "center",
                  backgroundColor: "#440A67", //"#e0aaff",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0.5rem", // Adjust the top position as needed
                    right: "0.5rem", // Adjust the right position as needed
                    backgroundColor: "#FFFFFF",
                    padding: "1rem 1rem",
                    borderRadius: "0.7rem",
                    fontSize: "16px",
                    fontWeight: "bold",
                    // position: "relative"
                  }}
                >
                  Date : {` `}
                  {moment().format("DD/MM/YYYY")} {/* Display current date */}
                </div>
                <CardMedia
                  component="img"
                  style={{
                    height: "110px",
                    width: "auto",
                    display: "block",
                    margin: "8px auto 0.1px",
                    borderRadius: "5rem",
                    border: "5px solid #A084E8",
                  }}
                  image={profile.length > 0 ? profile[0].photo : Profile}
                  alt={
                    profile.length > 0
                      ? profile[0].employee_name
                      : "Employee Photo"
                  }
                />
                <CardContent
                  sx={{
                    width: "60%", // Set width to 50%
                    margin: "0 auto", // Center the content horizontally
                  }}
                >
                  <Typography
                    sx={{
                      mb: 0.1,
                      backgroundColor: "#dac3e8",
                      borderRadius: "1rem",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                      component="div"
                    >
                      {profile[0]?.employee_name}
                    </Typography>
                    <Typography sx={{ fontSize: 15 }} color="text.secondary">
                      {profile[0]?.employee_no}
                    </Typography>

                    {/* {AttendanceData.map(
                      (data, idx) =>
                        idx == 0 && (
                          <Card
                            sx={{
                              minWidth: 140,
                              textAlign: "center",
                              backgroundColor: "#fff",
                              borderRadius: "1rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "1rem",
                            }}
                          >
                            <Typography
                              sx={{
                                flex: "1", // Occupy remaining space on the left
                                textAlign: "center",
                                backgroundColor: "#A7EDE7",
                                padding: "2px",
                                marginRight: "1rem",
                                borderRadius: "3rem",
                                fontSize: "14px",
                                color: "#2d6a4f",
                              }}
                            >
                              {data.start_time != 0 ? data.start_time : "-"}
                            </Typography>

                            <div
                              style={{
                                width: "50%",
                                borderBottom: "0.5px solid #000",
                                margin: "0",
                                position: "relative",
                              }}
                            >
                              <Typography
                                style={{
                                  position: "absolute",
                                  top: "-1.5rem", // Adjust this value to position the message above the line
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "#fff",
                                  padding: "0 0.5rem",
                                  zIndex: "1",
                                }}
                              >
                                Total Time
                              </Typography>

                              <Typography
                                style={{
                                  position: "absolute",
                                  top: "-0.5rem",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  padding: "0.7rem",
                                  zIndex: "1",
                                  fontSize: "15px",
                                  paddingBottom: "3px",
                                }}
                              >
                                {data.working_hours
                                  ? convertTimeFormat(data.working_hours)
                                  : "-"}
                              </Typography>
                            </div>

                            {data.end_time == 0 ? (
                              <Typography
                                sx={{
                                  flex: "1", // Occupy remaining space on the right
                                  textAlign: "center",
                                  width: "20%",
                                  backgroundColor: "#d8f3dc",
                                  color: "#52b788",
                                  padding: "4px",
                                  marginLeft: "1rem",
                                  borderRadius: "3rem",
                                  fontSize: "14px",
                                }}
                              >
                                Present
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  flex: "1", // Occupy remaining space on the right
                                  textAlign: "center",
                                  width: "20%",
                                  backgroundColor: "#FAD4D4",
                                  padding: "4px",
                                  marginLeft: "1rem",
                                  borderRadius: "3rem",
                                  fontSize: "14px",
                                  color: "#d90429",
                                }}
                              >
                                {data.end_time != 0 ? data.end_time : "-"}
                              </Typography>
                            )}
                          </Card>
                        )
                    )} */}

                    {activityArray.map(
                      (data, idx) =>
                        idx == 0 && (
                          <Card
                            sx={{
                              minWidth: 140,
                              textAlign: "center",
                              backgroundColor: "#fff",
                              borderRadius: "1rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "1rem",
                            }}
                          >
                            <Typography
                              sx={{
                                flex: "1", // Occupy remaining space on the left
                                textAlign: "center",
                                backgroundColor: "#A7EDE7",
                                padding: "2px",
                                marginRight: "1rem",
                                borderRadius: "3rem",
                                fontSize: "14px",
                                color: "#2d6a4f",
                              }}
                            >
                              {activityArray[0].project_start_time !== 0
                                ? activityArray[0].project_start_time
                                : "-"}
                            </Typography>

                            <div
                              style={{
                                width: "50%",
                                borderBottom: "0.5px solid #000",
                                margin: "0",
                                position: "relative",
                              }}
                            >
                              <Typography
                                style={{
                                  position: "absolute",
                                  top: "-1.5rem", // Adjust this value to position the message above the line
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "#fff",
                                  padding: "0 0.5rem",
                                  zIndex: "1",
                                }}
                              >
                                Total Time
                              </Typography>

                              <Typography
                                style={{
                                  position: "absolute",
                                  top: "-0.5rem",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  padding: "0.7rem",
                                  zIndex: "1",
                                  fontSize: "15px",
                                  paddingBottom: "3px",
                                }}
                              >
                                {activityArray[0].project_start_time != null &&
                                activityArray[activityArray.length - 1]
                                  .project_end_time != null
                                  ? getTimeDifference(
                                      activityArray[0].project_start_time,
                                      activityArray[activityArray.length - 1]
                                        .project_end_time
                                    )
                                  : "-"}
                              </Typography>
                            </div>

                            {data.project_end_time == null ? (
                              <Typography
                                sx={{
                                  flex: "1", // Occupy remaining space on the right
                                  textAlign: "center",
                                  width: "20%",
                                  backgroundColor: "#d8f3dc",
                                  color: "#52b788",
                                  padding: "4px",
                                  marginLeft: "1rem",
                                  borderRadius: "3rem",
                                  fontSize: "14px",
                                }}
                              >
                                Present
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  flex: "1", // Occupy remaining space on the right
                                  textAlign: "center",
                                  width: "20%",
                                  backgroundColor: "#FAD4D4",
                                  padding: "4px",
                                  marginLeft: "1rem",
                                  borderRadius: "3rem",
                                  fontSize: "14px",
                                  color: "#d90429",
                                }}
                              >
                                {activityArray[activityArray.length - 1]
                                  .project_end_time != null
                                  ? activityArray[activityArray.length - 1]
                                      .project_end_time
                                  : "Did Not Sign Out"}
                              </Typography>
                            )}
                          </Card>
                        )
                    )}
                  </Typography>
                </CardContent>
              </Card>

              {/* <div>
                {AttendanceData.map((data) =>
                  data.employee_id == profile[0].ID ? (
                    <Card
                      sx={{
                        minWidth: 140,
                        textAlign: "center",
                        backgroundColor: "#440A67", //"#e0aaff",
                      }}
                    >
                      <CardContent
                        sx={{
                          width: "60%", // Set width to 50%
                          margin: "0 auto", // Center the content horizontally
                        }}
                      >
                        <Typography
                          sx={{
                            mb: 0.1,
                            backgroundColor: "#dac3e8",
                            borderRadius: "1rem",
                            padding: "5px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 20, fontWeight: "bold" }}
                            component="div"
                          >
                            {profile[0]?.employee_name}
                          </Typography>
                          <Typography
                            sx={{ fontSize: 15 }}
                            color="text.secondary"
                          >
                            {profile[0]?.employee_no}
                          </Typography>
                        </Typography>
                      </CardContent>
                    </Card>
                  ) : (
                    ""
                  )
                )}
              </div> */}
            </Grid>
            {activityDetails.map((activity, id) => (
              <Grid item xs={12} sm={12} md={12} lg={12} key={id}>
                <Typography
                  variant="h6"
                  style={{ color: "#440A67" }}
                  gutterBottom
                >
                  {activity.project_name}
                </Typography>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "10%" }} align="center">
                          Start Time
                        </TableCell>
                        <TableCell style={{ width: "10%" }} align="center">
                          End Time
                        </TableCell>
                        <TableCell style={{ width: "20%" }} align="center">
                          Work Details
                        </TableCell>
                        <TableCell style={{ width: "10%" }} align="center">
                          Total Working Hours
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Sample data */}
                      <TableRow>
                        <TableCell align="center">
                          {activity.project_start_time}
                        </TableCell>
                        <TableCell align="center">
                          {activity.project_end_time
                            ? activity.project_end_time
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ whiteSpace: "pre-line", fontSize: "12px" }}
                        >
                          {activity.work_description
                            ? activity.work_description
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {activity.hours_of_working == "00:00:00"
                            ? "-"
                            : activity.hours_of_working}
                        </TableCell>
                      </TableRow>
                      {/* Repeat the above TableRow for more rows */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default DashBoardEmployeeActivityView;
