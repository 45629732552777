import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#9c27b0', // Purple color for primary elements
        },
        green: {
            main: '#85E6C5'
        },
        light_Purple: {
            main: '#DFCCFB'
        }
    },
});

export default theme;