import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import Layout from "../../../layout/Layout";
import { config, positionMasterAPI } from "../../../config";
import { AddPosition } from "../../../components/AddPosition";
import NotFound from "../../../components/NotFount";
import { Create } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import IconButton from "@mui/material/IconButton";

let field = "id",
  orderBy = "desc";

const Pagination = lazy(() => import("../../../components/ReactPagination"));

function AddRoleMaster() {
  const navigate = useNavigate();

  let intOffset = 0;
  const limit = 10;
  const [totalCount, setTotalCount] = useState(10);
  const [positionMasterList, setpositionMasterList] = useState([]);

  const handleReportPagination = (e) => {
    console.log(e.target.textContent);
    intOffset =
      e.target.innerText === "1"
        ? 0
        : (parseInt(e.target.innerText) - 1) * limit;
  };

  let assetsRef = useRef(false);
  const childRef = useRef();

  // useEffect(function () {
  //   if (assetsRef.current === false) {
  //     fetchData(1);
  //     return () => {
  //       assetsRef.current = true;
  //     };
  //   }
  // }, []);

  const handleEditPositions = (
    id,
    Role,
    positionName,
    department_id,
    Status
  ) => {
    childRef.current.setData(
      JSON.stringify({
        intPositionID: id,
        intRoleID: Role,
        positionName: positionName,
        intDepartmentID: department_id,
        status: Status,
        intType: 3,
      })
    );
  };

  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (page, rowsPerPage) => {

    console.log("⛔ ➡️ file: AddPositionMaster.jsx:91 ➡️ page, rowsPerPage:", page, rowsPerPage);

    if (tempRefs.current === false) {
      const data = JSON.stringify({
        intLimit: rowsPerPage || 10,
        intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
        intType: 3,
      });

      config.method = "POST";
      config.data = data;
      config.url = positionMasterAPI;
      console.log(data);
      axios(config)
        .then(function (response) {

          console.log("⛔ ➡️ file: AddPositionMaster.jsx:104 ➡️ response:", response);

          console.log(response.data["result"]);
          setpositionMasterList(response.data["result"]);
          setTotalCount(response.data.totalCount);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    tempRefs.current = true;
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!positionMasterList.length) return;
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setTimeout(() => {
      field = column.field;
      orderBy = sortDirection;
      fetchUsers(1);
      setLoading(false);
    }, 100);
  };

  const columns = useMemo(() => [
    {
      name: "Position Name",
      field: "id",
      sortable: true,
      selector: (row) => row.position_name,
    },
    {
      name: "Department Name",
      field: "id",
      sortable: true,
      selector: (row) => row.department_name,
    },
    {
      name: "Role Name",
      field: "id",
      sortable: true,
      selector: (row) => row.role_name,
    },
    {
      name: "Status",
      field: "id",
      cell: (row) => (
        <Typography
          className={`${
            row.status_name === "Active" ? `active-status` : `inactive-status`
          } `}
        >
          {row.status_name}
        </Typography>
      ),
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <IconButton
          color="success"
          onClick={() =>
            handleEditPositions(
              row.id,
              row.role_id,
              row.position_name,
              row.department_id,
              row.status
            )
          }
          aria-label="add an alarm"
        >
          <EditLocationOutlinedIcon />
        </IconButton>
      ),
    },
  ]);

  //Fetch Data Table
  const fetchData = (action) => {
    if (action === 1) {
      intOffset = 0;
    }
  };

  //   const data = JSON.stringify({
  //     intType: 3,
  //   });
  //   config.method = "POST";
  //   config.data = data;
  //   config.url = positionMasterAPI;

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response);
  //       setpositionMasterList(response.data["result"]);
  //       setTotalCount(response.data["count"]);
  //       setTotalCount(Math.round(response.data.totalCount / limit));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  return (
    <Layout>
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Position Master
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Position</Typography>
              <Typography color="text.primary">Add</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="light_Purple"
              className="button_first"
              href="/config/departments-master"
              startIcon={<AddIcon />}
            >
              Add Department
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
          <AddPosition ref={childRef} fetchData={fetchData} />
          <div className="empty-space-20"></div>
          <Grid className="small_table">
            <DataTable
              pagination
              paginationServer
              // fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={positionMasterList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              // onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid
        container
        spacing={2}
        margin={`15px 0px`}
        padding={`10px 15px 0px 0px`}
      >
        <AddPosition ref={childRef} fetchData={fetchData} />
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          textAlign={`right`}
          display={`flex`}
          justifyContent={`flex-end`}
        >
          <Suspense fallback={<div></div>}>
            <Pagination
              totalCount={totalCount}
              handleReportPagination={handleReportPagination}
            />
          </Suspense>
        </Grid>
      </Grid>

      <div className={`table-responsive`}>
        <table className={`table`}>
          <thead>
            <th>
              <Typography variant="h6">S.No</Typography>
            </th>
            <th>
              <Typography variant="h6">Position</Typography>
            </th>
            <th>
              <Typography variant="h6">Department</Typography>
            </th>
            <th>
              <Typography variant="h6">Role</Typography>
            </th>
            <th>
              <Typography variant="h6">Status </Typography>
            </th>
            <th>
              <Typography variant="h6">Action </Typography>
            </th>
          </thead>
          <tbody>
            {positionMasterList.length > 0 ? (
              positionMasterList.map((row, index) => {
                return (
                  <tr key={index}>
                    <td className={`text-center`}>
                      <Typography>{index + 1}</Typography>
                    </td>
                    <td className={`text-center`}>
                      <Typography>{row.position_name}</Typography>
                    </td>
                    <td className={`text-center`}>
                      <Typography>{row.department_name}</Typography>
                    </td>
                    <td className={`text-center`}>
                      <Typography>{row.role_name}</Typography>
                    </td>
                    <td className={`text-center`}>
                      <Typography
                        className={`${
                          row.status_name === "Active"
                            ? `active-status`
                            : `inactive-status`
                        } `}
                      >
                        {row.status_name}
                      </Typography>
                    </td>
                    <td className={`text-center`}>
                      <Tooltip title={`Edit Role`}>
                        <Create
                          onClick={() =>
                            handleEditPositions(
                              row.id,
                              row.role_id,
                              row.position_name,
                              row.department_id,
                              row.status
                            )
                          }
                        />
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} className={`text-center`}>
                  <NotFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div> */}
    </Layout>
  );
}

export default AddRoleMaster;
