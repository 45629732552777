import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import { Button, Grid, Typography, Tooltip } from "@mui/material";
import axios from "axios";
import Layout from "../../../layout/Layout";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { config, handleHrMasterAPI } from "../../../config";
import NotFound from "../../../components/NotFount";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import IconButton from "@mui/material/IconButton";
import { purple } from "@mui/material/colors";

let field = "id",
  orderBy = "desc";

function ListEmployee() {
  const navigate = useNavigate();

  const [HrMasterList, setHrMasterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (page, rowsPerPage) => {

    if (tempRefs.current === false) {

      const data = JSON.stringify({
        intLimit: rowsPerPage ? rowsPerPage : 10,
        intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
        intType: 2,
      });

      config.method = "POST";
      config.data = data;
      config.url = handleHrMasterAPI;
      axios(config)
        .then(function (response) {
          console.log(response.data)
          setHrMasterList(response.data["result"]);
          setTotalCount(response.data.totalCount);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    tempRefs.current = true
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!HrMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setTimeout(() => {
      field = column.field;
      orderBy = sortDirection;
      fetchUsers(1);
      setLoading(false);
    }, 100);
  };

  const handleEditRole = (id) => {
    navigate(`/employee/view/${id}`);
  };

  const statusStyle = {
    // display: 'flex',
    fontSize: '14px',
    // fontWeight: 'bold',
    // gap: '10px',
    width: "7rem",
    align: "center",
  };

  const columns = useMemo(() => [
    {
      name: "Employee No",
      field: "id",
      sortable: true,
      selector: (row) => row.employee_no,
      // cell: (row) => row.employee_no,
    },
    {
      name: "Name",
      field: "id",
      sortable: true,
      width: "20%",
      selector: (row) => row.employee_name,
      // cell: (row) => row.employee_name,
    },
    {
      name: "Phone",
      field: "id",
      sortable: true,
      width: "13%",
      selector: (row) => row.phone,
      // cell: (row) => row.secondary_number,
    },
    {
      name: "Company",
      field: "id",
      sortable: true,
      width: "15%",
      selector: (row) => row.company_name,
      // cell: (row) => row.secondary_number,
    },
    {
      name: "Position",
      field: "id",
      sortable: true,
      width: "23%",
      selector: (row) => row.position_name,
      // cell: (row) => row.position_name,
    },
    {
      name: "Employee Pin",
      field: "id",
      cell: (row) => (row.pin ? row.pin : "-"),
    },
    {
      name: "Employee Status",
      field: "id",
      width: "15%",
      cell: (row) => (
        <>
          {row.reason_for_relieve == "" ? (
            <Typography
              className={`active-status`}
              style={statusStyle}
              align="center"
            >
              Active
            </Typography>
          ) : (
            <Typography
              className={`inactive-status`}
              style={statusStyle}
              align="center"
            >
              {row.reason_for_relieve}
            </Typography>
          )}
        </>
      ),
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <IconButton
          color="success"
          onClick={() => handleEditRole(row.ID)}
          aria-label="add an alarm"
        >
          <EditLocationOutlinedIcon />
        </IconButton>
      ),
    },
  ]);

  const downloadExcel = () => {
    const data = {
      intType: 2,
      intLimit: totalCount
    };

    console.log("⛔ ➡️ file: ListEmployee.jsx:202 ➡️ data:", data);

    
    config.method = "POST";
    config.data = data;
    config.url = handleHrMasterAPI;

    axios(config)
      .then(function (response) {
        console.log(response.data);
        
        const newData = response.data["result"].map((row) => {
          return row;
        });

        console.log(newData);
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "students");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(
          workBook,
          `Tracker-Employee-report-${moment().format("DD-MM-YYYY")}.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };

  const handleEdit = (id) => {
    // defaultValues.txtName = `Central`;
    // defaultValues.txtLOB = `1`;
    // defaultValues.txtStatus = `2`;
    // reset({ ...defaultValues });
    // setIsEdit(true);
  };

  // const navigate = useNavigate();
  // let intOffset = 0;
  // const limit = 20;
  // const [totalCount, setTotalCount] = useState(0);

  // const [hrMasterList, setHrMasterList] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [totalRows, setTotalRows] = useState(0);
  // const [perPage, setPerPage] = useState(10);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [List, setList] = useState([]);

  // const columns = useMemo(() => [
  //   {
  //     name: "Employee No",
  //     field: "id",
  //     sortable: true,
  //     cell: (row) => row.employee_no,
  //   },
  //   {
  //     name: "Name",
  //     field: "id",
  //     sortable: true,
  //     cell: (row) => row.employee_name,
  //   },
  //   {
  //     name: "Phone",
  //     field: "id",
  //     sortable: true,
  //     cell: (row) => row.secondary_number,
  //   },
  //   {
  //     name: "Position",
  //     field: "id",
  //     sortable: true,
  //     cell: (row) => row.position_name,
  //   },
  //   {
  //     name: "Employee Pin",
  //     field: "id",
  //     sortable: true,
  //     cell: (row) => (row.pin ? row.pin : "-"),
  //   },
  //   {
  //     name: "Action",
  //     field: "id",
  //     sortable: true,
  //     cell: (row) => (
  //       <a href={row.title} onClick={() => handleEditRole(row.ID)} id="d1">
  //         Action
  //       </a>
  //     ),
  //   },
  // ]);

  // let assetsRef = useRef(false);

  // // useEffect(function () {
  // //   if (assetsRef.current === false) {
  // //     // const statusID = searchParams.get("id");

  // //     const data = JSON.stringify({
  // //       intLimit: limit,
  // //       intOffset: 0,
  // //       intType: 2,
  // //     });
  // //     config.method = "POST";
  // //     config.data = data;
  // //     config.url = handleHrMasterAPI;

  // //     axios(config)
  // //       .then(function (response) {
  // //         setHrMasterList(response.data["result"]);
  // //         setTotalCount(Math.round(response.data.totalCount / limit) + 1);
  // //       })
  // //       .catch(function (error) {
  // //         console.log(error);
  // //       });

  // //     return () => {
  // //       assetsRef.current = true;
  // //     };
  // //   }
  // // }, []);

  // const handleReportPagination = (e) => {
  //   console.log(e.target.textContent);
  //   // setIsDataLoading(true);
  //   intOffset =
  //     e.target.innerText === "1"
  //       ? 0
  //       : (parseInt(e.target.innerText) - 1) * limit;

  //   fetchData(2);
  // };

  // useEffect(function () {
  //   if (assetsRef.current === false) {
  //     fetchData(1);
  //     return () => {
  //       assetsRef.current = true;
  //     };
  //   }
  // }, []);

  // const fetchData = (action) => {
  //   if (action === 1) {
  //     intOffset = 0;
  //   }
  //   const data = JSON.stringify({
  //     intLimit: limit,
  //     intOffset: intOffset,
  //     intType: 2,
  //   });

  //   config.method = "POST";
  //   config.data = data;
  //   config.url = handleHrMasterAPI;
  //   axios(config)
  //     .then(function (response) {
  //       setHrMasterList(response.data["result"]);
  //       setTotalCount(Math.round(response.data.totalCount / limit) + 1);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const handleEditRole = (id) => {
  //   navigate(`/employee/view/${id}`);
  // };

  // const downloadExcel = () => {
  //   const data = JSON.stringify({
  //     intLimit: limit,
  //     intOffset: 0,
  //   });
  //   config.method = "POST";
  //   config.data = data;
  //   config.url = handleHrMasterAPI;

  //   axios(config)
  //     .then(function (response) {
  //       const newData = response.data["result"].map((row) => {
  //         delete row.year;
  //         delete row.month;
  //         delete row.day;
  //         delete row.hour;
  //         delete row.minute;
  //         delete row.second;
  //         delete row.ago;
  //         delete row.statusID;
  //         delete row.ticketID;
  //         delete row.tableData;

  //         row.created_at = moment(row.created_at).format(
  //           "ddd, DD MMM Y, hh:mm A"
  //         );
  //         return row;
  //       });
  //       const workSheet = XLSX.utils.json_to_sheet(newData);
  //       const workBook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(workBook, workSheet, "students");
  //       //Buffer
  //       let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  //       //Binary string
  //       XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  //       //Download
  //       XLSX.writeFile(
  //         workBook,
  //         `KG-HR-Master-${moment().format("ddd, DD MMM Y, hh:mm A")}.xlsx`
  //       );
  //     })
  //     .catch(function (error) {
  //       if (error.message === "Network Error") {
  //         localStorage.removeItem("user");
  //         window.location.href = window.location.origin + "/";
  //       }
  //       console.log(error);
  //     });
  // };

  return (
    <Layout>
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              List Employee
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">List Employee</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="light_Purple"
              className="button_first"
              href="/employee/add"
              startIcon={<AddIcon />}
            >
              Add Profile
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
       <Grid
            container
            spacing={2}
            margin={`15px 0px`}
            padding={`10px 15px 0px 0px`}
          >
            <Grid className="excel_button" item xs={12} lg={12} md={12}>
              <Button className="btn-primary" onClick={downloadExcel}>
                Export to Excel
              </Button>
            </Grid>
            </Grid>

              {/*  <Grid
              item
              lg={6}
              md={6}
              sm={6}
              textAlign={`right`}
              display={`flex`}
              justifyContent={`flex-end`}
            ></Grid>
            <Suspense>
              <Pagination
                totalCount={totalCount}
                handleReportPagination={handleReportPagination}
              />
            </Suspense>
          </Grid> */}
          {/* <div className={`table-responsive`}>
            <table className={`table`}>
              <thead>
                <th>
                  <Typography>S.No</Typography>
                </th>
                <th>
                  <Typography>Employee No</Typography>
                </th>
                <th>
                  <Typography>Name </Typography>
                </th>
                <th>
                  <Typography>Phone </Typography>
                </th>
                <th>
                  <Typography>Position </Typography>
                </th>
                <th>
                  <Typography>Employee Pin </Typography>
                </th>
                <th>
                  <Typography>Action </Typography>
                </th>
              </thead>
              <tbody>
                {hrMasterList.length > 0 ? (
                  hrMasterList.map((row, index) => {
                    const url = "http://localhost:3000/employee/view/" + row.id;
                    return (
                      <tr key={index}>
                        <td className={`text-center`}>
                          <Typography>{index + 1}</Typography>
                        </td>
                        <td className={`text-center`}>
                          <Typography>{row.employee_no}</Typography>
                        </td>
                        <td className={`text-center`}>
                          <Typography>{row.employee_name}</Typography>
                        </td>
                        <td className={`text-center`}>
                          <Typography>
                            {row.phone} / {row.secondary_number}
                          </Typography>
                        </td>
                        <td className={`text-center`}>
                          <Typography>{row.position_name}</Typography>
                        </td>
                        <td className={`text-center`}>
                          <Typography>{row.pin ? row.pin : "-"}</Typography>
                        </td>
                        <td className={`text-center`}>
                          <Button
                            onClick={() => handleEditRole(row.ID)}
                            className="align-cancelbutton button-33"
                          >
                            Edit Profile
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className={`text-center`}>
                      <NotFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div> */}
          <Grid>
            <DataTable
              pagination
              paginationServer
              // fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={HrMasterList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              // onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default ListEmployee;
