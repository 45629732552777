import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import axios from "axios";
import Layout from "../../layout/Layout";
import { useNavigate } from "react-router-dom";
import { config, projectMasterAPI, reportsAPI } from "../../config";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { LoadingButton } from "@mui/lab";
import DataTable from "react-data-table-component";

function PaymentReport() {
  const navigate = useNavigate();

  const [roleMasterList, setroleMasterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      onSearch(1);
    }
  }, []);

  const handlePageChange = (page) => {
    tempRefs.current = false;
    onSearch(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!roleMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    onSearch(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  // const handleSort = (column, sortDirection) => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     field = column.field;
  //     orderBy = sortDirection;
  //     onSearch(1);
  //     setLoading(false);
  //   }, 100);
  // };

  const ExpandedComponent = ({ data }) => {
    return (
      <table>
        <thead>
          <th>
            <Typography className="tbl-data">Employees worked</Typography>
          </th>
        </thead>
        <tr>
          <td>
            <Typography>{data.employee_names}</Typography>
          </td>
        </tr>
      </table>
    );
  };

  const columns = useMemo(() => [
    {
      name: "Project Name",
      field: "id",
      sortable: true,
      selector: (row) => (row.project_name ? row.project_name : "-"),
      // center: true,
    },
    {
      name: "Start Date",
      field: "id",
      sortable: true,
      selector: (row) =>
        row.project_created_date ? row.project_created_date : "-",
      center: true,
    },
    {
      name: "Total Worked Time",
      field: "id",
      sortable: true,
      center: true,

      selector: (row) => {
        const [hours, minutes, seconds] = row.total_time.split(":");
        return (
          <Grid>
            <Typography>{` ${hours} Hours & ${minutes} Minutes`}</Typography>
          </Grid>
        );
      },
    },
    {
      name: "Amount Spent",
      field: "id",
      sortable: true,
      center: true,

      selector: (row) =>
        row.total_amount_spent ? formatAmount(row.total_amount_spent) : "-",
    },
    {
      name: "Amount Received",
      field: "id",
      sortable: true,
      center: true,

      selector: (row) =>
        row.total_with_gst ? formatAmount(row.total_with_gst) : "-",
    },
    // {
    //   name: "Employees Name",
    //   field: "id",
    //   sortable: true,
    //   // width:"4rem",
    //   selector: (row) => (row.employee_names ? row.employee_names : "-"),
    //   center: true,
    // },
  ]);

  const [projectID, setProjectID] = useState(0);
  const [ProjectNameList, setProjectNameList] = useState([]);
  let tempRefs1 = useRef(false);

  useEffect(() => {
    if (tempRefs1.current === false) {
      const data = {
        intType: 6,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = projectMasterAPI;

      axios(config)
        .then(function (response) {
          setProjectNameList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs1.current = true;
      };
    }
  }, []);

  const formatAmount = (data) => {
    if (data) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "INR",
      });

      const formattedAmount = formatter.format(data);
      return formattedAmount;
    }
  };
  const [projectPaymentsReport, setProjectPaymentsReport] = useState([]);
  const onSearch = async (page, rowsPerPage) => {
    const data = {
      intProjectID: projectID,
      intLimit: rowsPerPage ? rowsPerPage : 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      intType: 1,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = reportsAPI;

    await axios(config)
      .then(async function (response) {
        console.log(response.data)
        setProjectPaymentsReport(response.data.result);
        setTotalCount(response.data.totalCount);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formatPrice = (price) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "INR",
    });

    const formattedAmount = formatter.format(price);
    return formattedAmount;
  };

  return (
    <Layout>
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Project Summary Report
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          margin={`15px 0px`}
          padding={`10px 15px 0px 0px`}
        >
          <Grid item xs={12} lg={3} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Project
              </InputLabel>
              <Select
                labelId="Select Project"
                id="demo-simple-select"
                value={projectID}
                label="Maristal Status"
                onChange={(e) => {
                  setProjectID(e.target.value);
                }}
              >
                {ProjectNameList.map((row, index) => (
                  <MenuItem value={row.id} key={index}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              onClick={() => {
                onSearch();
              }}
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid className="form_design">
          <Grid>
            <DataTable
              pagination
              paginationServer
              // fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={projectPaymentsReport}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              // onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default PaymentReport;
