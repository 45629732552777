import React, { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { reportsAPI, config, dashboardAPI } from "../../config";
import axios from "axios";

function AttendanceChart() {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  let tempRefs = useRef(false);
  useEffect(() => {
    let requestData = {
      intType: 4,
    };

    config.method = "POST";
    config.data = JSON.stringify(requestData);
    config.url = dashboardAPI;
    axios(config)
      .then(function (response) {
        const dataPoint1 = response.data.result;

        const series1 = dataPoint1.map((obj) => {
          const values = Object.entries(obj)
            .filter(([key, _]) => key !== "total_count")
            .map(([_, value]) => parseFloat(value));
          return values;
        });
        const series2 = [].concat(...series1);
        const labels1 = ["Logged In", "Not Logged In", "Leave"];
        // const colors1 = dataPoint1.map((dataPoint) => dataPoint.color);
        setSeries(series2);

        setOptions({
          chart: {
            height: 150,
            type: "pie",
            expandOnClick: true,
          },
          title: {
            text: "Attendance (Today)",
            align: "left",
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "22px",
                },
                value: {
                  fontSize: "16px",
                },
                total: {
                  show: true,
                  label: "Total",
                  formatter: function (w) {
                    return response.data.result[0].total_count;
                  },
                },
              },
              inverseOrder: false,
              hollow: {
                margin: 15,
                size: "65%",
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              return opts.w.globals.series[opts.seriesIndex] + "%";
            },
          },
          series: series2,
          labels: labels1,
          colors: ["#27E1C1", "#ff4d6d", "#540b0e"],
          legend: {
            show: true,
            position: "bottom",
            offsetX: -30,
            offsetY: -10,
            formatter: function (val, opts) {
              return (
                val + " - " + opts.w.globals.series[opts.seriesIndex] + "%"
              );
            },
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        height={350}
        // width={500}
      />
    </div>
  );
}

export default AttendanceChart;
