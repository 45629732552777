import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
import axios from "axios";
import Layout from "../../layout/Layout";
import { config, payslipAPI } from "../../config";
import NotFound from "../../components/NotFount";
import { Create } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, Button, TextField, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment/moment";
import { AlertMessage } from "../../components/AlertMessage";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

const Pagination = lazy(() => import("../../components/ReactPagination"));

function PayslipReport() {
  const childRef = useRef();
  const navigate = useNavigate();

  const [fromDate, setFromDate] = React.useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );

  let intOffset = 0;
  const limit = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [payslipReport, setPayslipReport] = useState([]);
  const [totalNetPay, setTotalNetPay] = useState("");

  const handleReportPagination = (e) => {
    console.log(e.target.textContent);
    intOffset =
      e.target.innerText === "1"
        ? 0
        : (parseInt(e.target.innerText) - 1) * limit;
  };

  const onSubmit = () => {
    const data = {
      intType: 3,
      txtPayslipFromDate: fromDate,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = payslipAPI;

    axios(config)
      .then(function (response) {
        if (response.data.result[0] && response.data.result[0].total_net_pay) {
          setTotalNetPay(response.data.result[0].total_net_pay);
        }
        setPayslipReport(response.data.result);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: error.data.message,
            messageType: "error",
          })
        );
      });
  };

  const downloadExcel = () => {
    const data = JSON.stringify({
      intType: 3,
      txtPayslipFromDate: fromDate,
    });
    config.method = "POST";
    config.data = data;
    config.url = payslipAPI;

    axios(config)
      .then(function (response) {
        const newData = response.data["result"].map((row) => {
          delete row.total_net_pay;
          return row;
        });

        console.log(newData);
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "students");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(
          workBook,
          `KG-PaySlip-${moment().format("MM-YYYY")}.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid
          textAlign="center"
          item
          className="form-lable"
          xs={12}
          lg={6}
          md={6}
        >
          <Typography className="page_title" textAlign="left">
            PaySlip Report
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard">
              Dashboard
            </Link>
            <Typography color="text.primary">payslip</Typography>
            <Typography color="text.primary">Report</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          textAlign="right"
          item
          className="form-lable"
          xs={12}
          lg={6}
          md={6}
        >
          <LoadingButton
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </LoadingButton>
        </Grid>
      </Grid>
      <AlertMessage ref={childRef} />
      <div className="empty-space-40"></div>
      <Grid container className="form_design" spacing={2}>
        <Grid item xs={12} md={6} lg={6}></Grid>
        <Grid container xs={12} md={6} lg={6}>
          <Grid item xs={12} md={9} lg={9}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Select Month and Year"
                value={fromDate}
                views={["year", "month"]}
                inputFormat="MM/YYYY"
                maxDate={new Date()}
                // {...register("txtFromDate")}
                onChange={(newValue) => {
                  setFromDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    // helperText={errors.txtDob?.message}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3} lg={3} className="align_button" textAlign="right">
            <Button
              variant="primary"
              className={`btn-primary`}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          textAlign={`right`}
          display={`flex`}
          justifyContent={`flex-end`}
        >
          <Suspense fallback={<div></div>}>
            <Pagination
              totalCount={totalCount}
              handleReportPagination={handleReportPagination}
            />
          </Suspense>
        </Grid>
      </Grid>
      {payslipReport.length > 0 ? (
        <>
          <Grid
            container
            spacing={2}
            margin={`2% 3%`}
            padding={`10px 15px 0px 0px`}
          >
            <Grid item xs={12} lg={6} md={6}>
              <Button className="btn-primary" onClick={downloadExcel}>
                Export to Excel
              </Button>
            </Grid>
            <div className="empty-space-20"></div>
            <Grid item xs={12} lg={6} md={6}>
              <Typography>Total Net Pay :{totalNetPay}</Typography>
            </Grid>
            <div className="empty-space-20"></div>
            <div className={`table-responsive`}>
              <table className={`table`}>
                <thead>
                  <th>
                    <Typography variant="h6">S.No</Typography>
                  </th>
                  <th>
                    <Typography variant="h6">Employee Name</Typography>
                  </th>
                  <th>
                    <Typography variant="h6">Employee Number</Typography>
                  </th>
                  <th>
                    <Typography variant="h6">No of leave Taken </Typography>
                  </th>
                  <th>
                    <Typography variant="h6">Salary Advance </Typography>
                  </th>
                  <th>
                    <Typography variant="h6">Personal Advance </Typography>
                  </th>
                  <th>
                    <Typography variant="h6">Total Deductions </Typography>
                  </th>
                  <th>
                    <Typography variant="h6">Net Pay </Typography>
                  </th>
                </thead>
                <tbody>
                  {payslipReport.length > 0 ? (
                    payslipReport.map((row, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td className={`text-center`}>
                              <Typography>{index + 1}</Typography>
                            </td>

                            <td>
                              <Typography>{row.employee_name}</Typography>
                            </td>
                            <td>
                              <Typography>{row.employee_no}</Typography>
                            </td>
                            <td>
                              <Typography>
                                {row.no_of_days_leave_taken}
                              </Typography>
                            </td>
                            <td>
                              <Typography>{row.salary_advance}</Typography>
                            </td>
                            <td>
                              <Typography>{row.personal_advance}</Typography>
                            </td>
                            <td>
                              <Typography>{row.total_deductions}</Typography>
                            </td>
                            <td>
                              <Typography>{row.net_pay}</Typography>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className={`text-center`}>
                        <NotFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
}

export default PayslipReport;
