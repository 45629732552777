import React, { useEffect, useRef } from "react";

import Layout from "../../../layout/Layout";
import { Button, Grid, TextField, Typography } from "@mui/material";

import { useState } from "react";
import { config, termsAndConditionsAPI } from "../../../config";
import axios from "axios";
import { AlertMessage } from "../../../components/AlertMessage";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
{/* <script src="https://cdn.tiny.cloud/1/x8aqjkiexwpgpg2hkdzkcwfjjpvryho0lnk3b8bl4cmj37pb/tinymce/6/tinymce.min.js"></script> */ }

function TermsConditions() {
  const childRef = useRef();
  const navigate = useNavigate();

  const [txtTitle, setTitle] = useState("");
  const handleSubmit = () => {
    // editing text area items
    let invDesc = window.tinymce.get("textarea").getContent();
    let newInvDesc = invDesc.replaceAll("<p>", "");
    let finalInvDesc = newInvDesc.replaceAll("</p>", "");

    // debugger;
    const data = JSON.stringify({
      txtTitle: txtTitle,
      txtDescription: finalInvDesc,
      intType: 1,
    });
    config.method = "POST";
    config.data = data;
    config.url = termsAndConditionsAPI;

    axios(config)
      .then(function (response) {
        let messageType = "success";

        if (response.data.code === 500) {
          messageType = "warning";
        }

        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: messageType,
          })
        );

        setTimeout(function () {
          navigate("/termsandconditions/list");
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(function () {
    window.tinymce.init({
      selector: "#textarea",
      width: "100%",
      menubar: false,
      plugins:
        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
      toolbar:
        "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
    });
  }, []);

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Terms and Conditions
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Terms and Conditions</Typography>
              <Typography color="text.primary">List</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="secondary"
              className="button_first"
              href="/termsandconditions/list"
            >
              List Terms and Conditions
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
          <Grid
            container
            spacing={2}
            margin={`15px 0px`}
            padding={`10px 15px 0px 0px`}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              textAlign={`right`}
              display={`flex`}
              justifyContent={`flex-end`}
            >
              <TextField
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                label="Title"
              />
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              textAlign={`right`}
              display={`flex`}
              justifyContent={`flex-end`}
            >
              <TextField
                fullWidth
                id="textarea"
                onChange={(e) => console.log(e.target.value)}
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              textAlign={`right`}
              display={`flex`}
              justifyContent={`flex-end`}
            >
              <Button
                variant="primary"
                className={`btn-primary`}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default TermsConditions;
