import React, { useEffect, useRef, useState, useMemo } from "react";
import { Grid, Typography, Button, Tooltip } from "@mui/material";
import { AlertMessage } from "../../../components/AlertMessage";
//layout import
import Layout from "../../../layout/Layout";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";

import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from '@mui/icons-material/DoneAll';

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";

// date
import dayjs from "dayjs";

import axios from "axios";
import { config, leaveAPI, userData } from "../../../config";

let field = "id",
  orderBy = "desc";

function ApproveLeave() {
  const navigate = useNavigate();
  const childRef = useRef();
  const validationSchema = Yup.object().shape({
    txtLeaveReason: Yup.string().required("Leave Reason is required"),
    txtLeaveSubject: Yup.string().required("Leave Subject is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  let assetsRef = useRef(false);
  // let intOffset = 0;
  // const limit = 10;
  // const [totalCount, setTotalCount] = useState(0);

  // useEffect(function () {
  //   if (assetsRef.current === false) {
  //     fetchData(1);
  //     return () => {
  //       assetsRef.current = true;
  //     };
  //   }
  // }, []);
  const [positionMasterList, setpositionMasterList] = useState([]);

  // const fetchData = (action) => {
  //   if (action === 1) {
  //     intOffset = 0;
  //   }

  //   const data = JSON.stringify({
  //     intType: 2,
  //   });
  //   config.method = "POST";
  //   config.data = data;
  //   config.url = leaveAPI;

  //   axios(config)
  //     .then(function (response) {
  //       setpositionMasterList(response.data["result"]);
  //       setTotalCount(Math.round(response.data.totalCount / limit));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const handleFormReset = () => {
  //   let defaultValues = {};
  //   reset({ ...defaultValues });
  // };

  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
      tempRefs.current = true
    }
  }, []);

  const fetchUsers = async (page, rowsPerPage) => {

    if (tempRefs.current === false) {
      const data = JSON.stringify({
        intLimit: rowsPerPage || 10,
        intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
        intType: 6,
      });

      config.method = "POST";
      config.data = data;
      config.url = leaveAPI;
      axios(config)
        .then(function (response) {
          console.log(response.data);
          setpositionMasterList(response.data.result);
          setTotalCount(response.data.totalCount);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    tempRefs.current = true
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!positionMasterList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setTimeout(() => {
      field = column.field;
      orderBy = sortDirection;
      fetchUsers(1);
      setLoading(false);
    }, 100);
  };

  const handleEditRole = (id) => {
    navigate(`/advance-payment/edit/${id}`);
  };

  function getFirstFiveWords(str) {
    const words = str.split(" ");
    return words.slice(0, 5).join(" ");
  }

  const columns = useMemo(() => [
    {
      name: "Employee No",
      field: "id",
      sortable: true,
      selector: (row) => row.employee_no,
    },
    {
      name: "Employee Name",
      field: "id",
      sortable: true,
      cell: (row) => (
        <Tooltip title={row.employee_name}>
          <span>{row.employee_name}</span>
        </Tooltip>
      ),
      width: "200px",
    },
    {
      name: "From Date",
      field: "id",
      sortable: true,
      cell: (row) => (
        <Tooltip title={`${row.leave_from_date ? row.leave_from_date : "" } ${row.leave_from_time ? row.leave_from_time : ""}`}>
          <span>{`${row.leave_from_date ? row.leave_from_date : ""} ${row.leave_from_time ? row.leave_from_time : ""}`}</span>
        </Tooltip>
      ),
    },
    {
      name: "To Date",
      field: "id",
      sortable: true,
      cell: (row) => (
        <Tooltip title={`${row.leave_to_date ? row.leave_to_date : ""} ${row.leave_to_time ? row.leave_to_time : ""}`}>
          <span>{`${row.leave_to_date ? row.leave_to_date : ""} ${row.leave_to_time}`}</span>
        </Tooltip>
      ),
    },
    {
      name: "Subject",
      field: "id",
      sortable: true,
      cell: (row) => (
        <Tooltip title={row.leave_subject}>
          <span>{getFirstFiveWords(row.leave_subject)}</span>
        </Tooltip>
      ),
    },
    // {
    //   name: "Reason",
    //   field: "id",
    //   sortable: true,
    //   selector: (row) => row.leave_reason,
    // },
    {
      name: "Reason",
      field: "id",
      sortable: true,
      cell: (row) => (
        <Tooltip title={row.leave_reason}>
          <span>{getFirstFiveWords(row.leave_reason)}</span>
        </Tooltip>
      ),
    },
    {
      name: "Action",
      field: "id",
      cell: (row) => (
        <>
          {/* {console.log(row.leave_status)} */}
          {row.leave_status == 84 ? (
            <>
              <Tooltip title="Accept Leave">
                <IconButton
                  color="success"
                  value={row.id}
                  onClick={(e) => handleLeave(row.id, 85)}
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Decline Leave">
                <IconButton
                  color="error"
                  value={row.id}
                  onClick={(e) => handleLeave(row.id, 86)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : row.leave_status == 85 ? (
            <Tooltip title="Leave Accepted">
              <DoneAllIcon color="success" />
            </Tooltip>
          ) : (
            <Tooltip title="Leave Denied">
              <CloseIcon color="error" />
            </Tooltip>
          )}
        </>
      ),
    },
  ]);

  const handleLeave = (LeaveID, status) => {
    const data = JSON.stringify({
      intType: 3,
      intLeaveID: LeaveID,
      intLeaveStatus: status,
      adminData: localStorage.user
    });

    config.method = "POST";
    config.data = data;
    config.url = leaveAPI;

    axios(config)
      .then(function (response) {
        window.location.reload(false);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      });
  };

  return (
    <Layout>
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Leave Approval
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Leave</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid className="form_design">
          {/* <table className={`table`}>
                  <thead>
                    <th>
                      <Typography variant="h6">S.No</Typography>
                    </th>
                    <th>
                      <Typography variant="h6">Employee Name</Typography>
                    </th>
                    <th>
                      <Typography variant="h6">From Date</Typography>
                    </th>
                    <th>
                      <Typography variant="h6">To Date</Typography>
                    </th>
                    <th>
                      <Typography variant="h6">Reason</Typography>
                    </th>
                    <th>
                      <Typography variant="h6">Subject</Typography>
                    </th>
                    <th>
                      <Typography variant="h6">Status</Typography>
                    </th>
                  </thead>
                  <tbody>
                    {positionMasterList.length > 0 ? (
                      positionMasterList.map((row, index) => {
                        return (
                          <tr key={index}>
                            <td className={`text-center`}>
                              <Typography>{index + 1}</Typography>
                            </td>
                            <td className={`text-center`}>
                              <Typography>{row.employee_name}</Typography>
                            </td>
                            <td className={`text-center`}>
                              <Typography>{row.leave_from_date}</Typography>
                            </td>
                            <td className={`text-center`}>
                              <Typography>{row.leave_to_date}</Typography>
                            </td>
                            <td className={`text-center`}>
                              <Typography>{row.leave_reason}</Typography>
                            </td>
                            <td className={`text-center`}>
                              <Typography>{row.leave_subject}</Typography>
                            </td>
                            <td className={`text-center`}>
                              {row.leave_status == 84 ? (
                                <>
                                  <Button
                                    variant="outlined"
                                    className="align-cancelbutton"
                                    color="success"
                                    value={row.id}
                                    onClick={(e) => handleLeave(row.id, 85)}
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    className="align-cancelbutton"
                                    color="error"
                                    value={row.id}
                                    onClick={(e) => handleLeave(row.id, 86)}
                                  >
                                    Decline
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    className={`${
                                      row.leave_status === 86
                                        ? "inactive-status"
                                        : row.leave_status === 85
                                        ? "active-status"
                                        : "pending-status"
                                    } `}
                                  >
                                    {row.leave_status === 86
                                      ? `Declined`
                                      : row.leave_status === 85
                                      ? `Approved`
                                      : "Pending"}
                                  </Typography>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className={`text-center`}>
                          <NotFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table> */}
          <Grid>
            <DataTable
              pagination
              paginationServer
              // fixedHeader
              fixedHeaderScrollHeight="550px"
              columns={columns}
              data={positionMasterList}
              progressPending={loading}
              paginationTotalRows={totalCount}
              paginationDefaultPage={currentPage}
              paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
              paginationPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              // onSort={handleSort}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}
export default ApproveLeave;
