import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState, useRef } from "react";
import Header from "./Header";

import { Link, Navigate } from "react-router-dom";

import LogoutIcon from "@mui/icons-material/Logout";
import companyLogo from "../assets/images/Logo/IMAGINET LOGO.png";

import {
  Close as CloseIcon,
  Dashboard as DashboardIcon,
} from "@mui/icons-material";
import "../assets/css/style.css";
import Sidenav, { SideNav } from "../layout/Sidenav";
import { useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";


function Layout(props) {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("projectID");
    navigate('/')
  };

  const [menuOpenedFlag, setMenuOpenedFlag] = useState(false);
  const showMenuIcon = () => {
    setMenuOpenedFlag(!menuOpenedFlag);
  };
  const activeUrl = window.location.pathname;
  const userData = JSON.parse(localStorage.getItem("user"));


  return (
    <Box>
      <Grid container>
        <Grid className={`sidenav ${menuOpenedFlag ? `open` : ``}`}>
          <Grid textAlign={`right`}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={showMenuIcon}
              color="inherit"
              className="btn"
            >
              <CloseIcon sx={{ color: `#440A67` }} />
            </IconButton>
          </Grid>
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  px: 2.5,
                }}
                style={{ backgroundColor: "white" }}
              >
                <img
                  src={companyLogo}
                  alt="KG"
                  width={`220px`}
                  height={`auto`}
                />
              </ListItemButton>
              <Grid className="left-menu">
                <Sidenav />
              </Grid>
              <Link onClick={handleLogout} className={`logout-btn`}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    px: 2.5,
                    display: "flex",
                    gap: "15px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                    }}
                  >
                    <PowerSettingsNewIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
        </Grid>
        <Grid className="topnav">
          <Header menuAction={showMenuIcon} menuOpenedFlag={menuOpenedFlag} />
          {props.children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Layout;
