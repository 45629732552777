import React, { useEffect, useRef } from "react";

//design imports from material
import {
  Grid,
  FormLabel,
  TextField,
  Typography,
  Button,
  FormControl,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LoadingButton } from "@mui/lab";

//layout import
import Layout from "../../../layout/Layout";

//form imports
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

//date picker
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import axios from "axios";
import { config, advancePaymentAPI } from "../../../config";
import { AlertMessage } from "../../../components/AlertMessage";
import { useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

// import { config, clientMasterAPI } from "../../config";

function AdvancePaymentAdd() {
  const nav = useNavigate();

  const [btnFlag, setBtnFlag] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    // txtEmployeeName: Yup.string().required("Employee Name is required"),
    intAmount: Yup.number()
      .integer()
      .typeError("Please Enter Valid Amount")
      .positive("Please Enter Valid Amount"),
    txtReason: Yup.string()
      .required("Reason is required")
      .typeError("Please Enter Valid Reason"),
  });

  const [eligibleEmployeeName, setLstEligibleEmployeeName] = useState([]);
  const [txtClientName, setClientName] = useState("");
  const [emplpyeeID, setEmployeeID] = useState("");
  const [emplpyeeNo, setEmployeeNo] = useState("");

  let tempRef1 = useRef(false);
  useEffect(() => {
    if (tempRef1.current === false) {
      const data = {
        intType: 4,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = advancePaymentAPI;

      axios(config)
        .then(function (response) {
          console.log(response.data.result);
          setEmployeeID(response.data.result[0].employee_id);
          setEmployeeNo(response.data.result[0].employee_no);
          setLstEligibleEmployeeName(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef1.current = true;
      };
    }
  }, []);

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const [DateofAdvance, setDateofAdvance] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );

  function onSubmit(data) {
    setBtnFlag(true);
    data.intType = 1;
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = advancePaymentAPI;

    axios(config)
      .then(function (response) {
        let messageType = "success";
        if (response.data.code == 201) {
          messageType = "warning";
        }
        handleFormReset();
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: messageType,
          })
        );
        // navigate("/hradvancepayment/list/");
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      })
      .finally(function () {
        setBtnFlag(false);
      });
  }

  useEffect(() => {
    handleFormReset();
  }, []);

  const handleFormReset = () => {
    let defaultValues = {};
    defaultValues.txtEmployeeName = null;
    defaultValues.intAmount = null;
    defaultValues.txtDateofAdvance = null;
    defaultValues.txtReason = null;
    defaultValues.txtPaymentMode = null;
    defaultValues.intNoOfInstallments = null;
    setClientName(null);
    setTransactionID(null);
    reset({ ...defaultValues });
  };

  const childRef = useRef();

  // Payment Type
  const [txtTransactionID, setTransactionID] = React.useState(false);

  const handleChange = (event) => {
    setTransactionID(event.target.value);
    if (event.target.value == "bank" || event.target.value == "upi") {
      setTransactionID(true);
    } else {
      setTransactionID(false);
    }
  };

  const [PaymentType, setPaymentType] = React.useState(true);

  const handlePaymentType = (event) => {
    console.log(event.target.value);
    if (event.target.value == "salaryAdvance") {
      setPaymentType(true);
    } else {
      setPaymentType(false);
    }
  };

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid container spacing={3}>
        <Grid
          textAlign="center"
          item
          className="form-lable"
          xs={12}
          lg={6}
          md={6}
        >
          <Typography className="page_title" textAlign="left">
            Add Advance Payment
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard">
              Dashboard
            </Link>
            <Typography color="text.primary">Advance Payment</Typography>
            <Typography color="text.primary">Add</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          textAlign="right"
          item
          className="form-lable"
          xs={12}
          lg={6}
          md={6}
        >
           <LoadingButton
                size="medium"
                variant="contained"
                color="light_Purple"
                className="button_first"
                href="/advance-payment/list/"
              >
                List Advance Payment
              </LoadingButton>
          <LoadingButton
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => nav(-1)}
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </LoadingButton>
        </Grid>
      </Grid>
      <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} lg={12} md={12}>
            <Grid className="employee-form-struct">
              <Grid className="login-text-background">
                <Typography variant="h5" textAlign="center">
                  Advance Payment
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Employee Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="EmployeeName"
                      id="demo-simple-select"
                      value={txtClientName}
                      {...register("intEmployeeID")}
                      onChange={(e) => {
                        setClientName(e.target.value);
                        setValue("intEmployeeID", e.target.value);
                      }}
                    >
                      {eligibleEmployeeName.map((row, index) => (
                        <MenuItem value={row.ID} key={index}>
                          {row.employee_name} ({row.employee_no})
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="number"
                    label="Advance Amount"
                    fullWidth
                    InputProps={{ inputProps: { min: 1 } }}
                    error={errors.intAmount ? true : false}
                    {...register("intAmount")}
                    helperText={errors.intAmount?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    format="DD-MM-YYYY"
                  >
                    <MobileDatePicker
                      label="Date of Advance"
                      value={DateofAdvance}
                      inputFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      fullWidth
                      // {...register("txtDateofAdvance")}
                      onChange={(newValue) => {
                        setDateofAdvance(newValue);
                        setValue(
                          "txtDateofAdvance",
                          dayjs(newValue).format("YYYY-MM-DD")
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          helperText={errors.txtDateofAdvance?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="text"
                    fullWidth
                    label="Reason"
                    error={errors.txtReason ? true : false}
                    {...register("txtReason")}
                    helperText={errors.txtReason?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Payment Mode
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="PaymentMode"
                      {...register("txtPaymentMode")}
                      onChange={handleChange}
                    >
                      <MenuItem value="cash">Cash</MenuItem>
                      <MenuItem value="upi">UPI</MenuItem>
                      <MenuItem value="bank">Bank Transfer</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {!txtTransactionID ? (
                  <></>
                ) : (
                  <>
                    <Grid item className="form-lable" xs={12} lg={6} md={6}>
                      <TextField
                        type="text"
                        fullWidth
                        label="Transaction ID"
                        error={errors.txtTransactionID ? true : false}
                        {...register("txtTransactionID")}
                      />
                    </Grid>
                  </>
                )}
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Advance Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="PaymentType"
                      {...register("txtPaymentType")}
                      onChange={handlePaymentType}
                    >
                      <MenuItem value="salaryAdvance">Salary Advance </MenuItem>
                      <MenuItem value="personalAdvance">
                        Personal Advance{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {PaymentType ? (
                  <></>
                ) : (
                  <>
                    <Grid item className="form-lable" xs={12} lg={6} md={6}>
                      <TextField
                        type="number"
                        fullWidth
                        inputProps={{ min: 1, max: 12 }}
                        label="No of installment"
                        error={errors.intNoOfInstallments ? true : false}
                        {...register("intNoOfInstallments")}
                        helperText={errors.intNoOfInstallments?.message}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid
                item
                className="form-lable"
                xs={12}
                lg={12}
                md={12}
                marginTop="20px"
                textAlign="right"
              >
                <Button
                  className={`btn-primary`}
                  type="submit"
                  disabled={btnFlag}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
}

export default AdvancePaymentAdd;
