import { Grid, TextField, Typography, FormControl } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuthContext } from "../hooks/useAuthContext";
import React from "react";
import "../assets/css/login.css";

import companyLogo from "../assets/images/Logo/logo.png";
import loginBackgroundImage from "../assets/images/login/login.jpg";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import {
  config,
  adminLoginAPI,
  forgotPasswordAPI,
  resetPasswordAPI,
  verifyOTPAPI,
  resetPasswordEmailAPI,
} from "../config";
import { AlertMessage } from "../components/AlertMessage";
import moment from "moment";

function Login() {
  const navigate = useNavigate();
  const childRef = useRef();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      if (user.role == "user") {
        navigate("/user/ticket/list");
      } else if (user.role === "agent" || user.role === "Admin") {
        navigate("/agent/dashboard");
      }
    }

    return () => true;
  }, []);

  const [data, setData] = useState({});

  const validationSchema = Yup.object().shape({
    txtPassword: Yup.string().required("Password is required"),
    txtEmail: Yup.string()
      .required("Email is required")
      .email("Please enter valid email format"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState } = useForm(formOptions);

  const { errors } = formState;

  const [alertType, setAlertType] = useState("success");
  const [errMessage, setErrMessage] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [intOTP, setOTP] = useState("");

  const [txtResetPassword, setResetPassword] = useState("");
  const [txtResetConfirmPassword, setResetConfirmPassword] = useState("");

  const [open, setOpen] = useState(false);
  const [isLoadingSubmit, setLodingSubmit] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isVerifiedOTP, setIsVerifiedOTP] = useState(false);

  const [isSendOTP, setIsSendOTP] = useState(false);
  // const { login, isLoding, error } = useLogin();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useAuthContext();

  // const navigate = useNavigate();
  function onSubmit(data) {
    setLodingSubmit(true);
    setError(null);
    var reqData = JSON.stringify({
      txtUsername: data.txtEmail,
      txtPassword: data.txtPassword,
      txtDate: moment().format("YYYY-MM-DD"),
      intType: 1,
    });

    config.method = "POST";
    config.url = adminLoginAPI;
    config.data = reqData;

    axios(config)
      .then(function (response) {
        console.log(1, response.data.result);
        if (response.data.result != 0) {
          console.log(response.data.result);
          localStorage.setItem("user", JSON.stringify(response.data.result[0]));

          //Remove existing local storage
          localStorage.removeItem("isProjectLoggedIn");
          localStorage.removeItem("projectID");
          localStorage.removeItem("projectAutoID");
          localStorage.removeItem("projectName");
          localStorage.removeItem("projectCheckInTime");
          localStorage.removeItem("userAttandenceLogin");
          console.log(
            response.data.result[1] &&
              response.data.result[1].working_hours == ""
          );

          if (
            response.data.result[1] &&
            response.data.result[1].working_hours == ""
          ) {
            if (
              !response.data.result[1].end_time ||
              (response.data.result[1].end_time = "0000-00-00 00:00:00")
            ) {
              console.log("working", response.data.result[1]);
              localStorage.setItem("isProjectLoggedIn", true);
              localStorage.setItem(
                "projectID",
                response.data.result[1].project_id
                  ? response.data.result[1].project_id
                  : 0
              );
              localStorage.setItem(
                "projectAutoID",
                response.data.result[1].id ? response.data.result[1].id : 0
              );
              localStorage.setItem(
                "projectName",
                response.data.result[1].project_name
                  ? response.data.result[1].project_name
                  : ""
              );
              localStorage.setItem(
                "projectCheckInTime",
                response.data.result[1].start_time
                  ? response.data.result[1].start_time
                  : ""
              );
              localStorage.setItem("userAttandenceLogin", "true");
            }
          }

          dispatch({ type: "LOGIN", payload: "jsonData" });

          let pageURL = "/dashboard";
          window.location.href = pageURL;
        } else {
          console.log("working");
        }
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/adminlogin";
        }
        console.log(error);
        setErrMessage("Invalid Username or Password");
      });
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      if (user.role_id === 36) {
        navigate("/user/attendance");
      } else if (user.role_id === 34 || user.role_id === 35) {
        navigate("/dashboard");
      }
    }

    return () => true;
  }, []);

  const handleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
  };

  const handleSendOTP = () => {
    setErrMessage("");
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(forgotPasswordEmail)
    ) {
      setErrMessage("Please enter valid email");
      return false;
    }

    var reqData = JSON.stringify({
      txtUsername: forgotPasswordEmail,
      intType: 5,
    });
    config.method = "post";
    config.url = resetPasswordEmailAPI;
    config.data = reqData;
    // debugger;
    axios(config)
      .then(function (response) {
        if (response.data.message === "Invalid Email") {
          setErrMessage(response.data.message);
        } else {
          setIsSendOTP(!isSendOTP);
        }
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/adminlogin";
        }
        console.log(error);
        setErrMessage("Invalid Username or Password");
      });
  };

  const handleResetPassword = () => {
    setErrMessage("");
    if (txtResetPassword === "") {
      setErrMessage("Please enter password");
      return false;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i.test(
        txtResetPassword
      )
    ) {
      setErrMessage(
        "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      );
      return false;
    }

    if (txtResetPassword !== txtResetConfirmPassword) {
      setErrMessage("Passwords must match");
      return false;
    }

    var reqData = JSON.stringify({
      txtUsername: forgotPasswordEmail,
      txtPassword: txtResetPassword,
      intType: 8,
      txtOTP: intOTP,
    });
    config.method = "post";
    config.url = resetPasswordAPI;
    config.data = reqData;

    // debugger;
    axios(config)
      .then(function (response) {
        if (response.data.message === "Password updated") {
          childRef.current.triggerAlert(
            JSON.stringify({
              message: "Password Reset Successful",
              messageType: "success",
            })
          );
          setErrMessage("");
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          // setIsVerifiedOTP(!isVerifiedOTP);
        }
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/adminlogin";
        }
        console.log(error);
        setErrMessage("");
      });
  };

  const handleVerifyOTP = () => {
    var reqData = JSON.stringify({
      txtUsername: forgotPasswordEmail,
      intType: 10,
      intOTP: intOTP,
    });
    config.method = "post";
    config.url = verifyOTPAPI;
    config.data = reqData;

    // debugger;
    axios(config)
      .then(function (response) {
        if (response.data.message === "Invalid Otp") {
          setErrMessage(response.data.message);
        } else {
          setIsVerifiedOTP(!isVerifiedOTP);
          setErrMessage("");
        }
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/adminlogin";
        }
        console.log(error);
        setErrMessage("Invalid Username or Password");
      });
  };

  return (
    <>
      <AlertMessage ref={childRef} />
      <Grid container className="full-height">
        <Grid
          className="padding-10 login-background"
          item
          xs={12}
          lg={3}
          md={3}
          alignItems="center"
        >
          <Grid textAlign="center">
            <img
              src={companyLogo}
              alt="LogRamcides Ticketing System (RTS)"
              className="logo-image"
            />
            <Typography variant="h4" mb={1} className="color-white">
              Ramcides Ticketing System (RTS)
            </Typography>
          </Grid>
          <Grid textAlign="Center">
            <img
              src={loginBackgroundImage}
              width={`130px`}
              alt="Login Background"
              className="login-background-img"
            />
          </Grid>
        </Grid>
        <Grid
          className="login-center"
          item
          xs={12}
          lg={9}
          md={9}
          alignItems="center"
        >
          <Grid className="inner-login-box  inner-login-admin">
            <Container>
              {isVerifiedOTP ? (
                <>
                  <Grid className="login-text-background">
                    <Typography variant="h4" textAlign="center">
                      Reset Password
                    </Typography>
                    <div className="empty-space-20"></div>
                  </Grid>
                  <Grid>
                    <TextField
                      name="txtPassword"
                      type="password"
                      label="Password"
                      fullWidth
                      value={txtResetPassword}
                      onChange={(e) => setResetPassword(e.target.value)}
                    />
                    <Typography className="invalid-feedback">
                      {errors.txtEmail?.message}
                    </Typography>
                  </Grid>
                  <div className="empty-space-20"></div>
                  <Grid>
                    <TextField
                      name="txtPassword"
                      type="password"
                      label="Confirm Password"
                      fullWidth
                      value={txtResetConfirmPassword}
                      onChange={(e) => setResetConfirmPassword(e.target.value)}
                    />
                    <Typography className="invalid-feedback">
                      {errors.txtPassword?.message}
                    </Typography>
                  </Grid>
                  <div className="empty-space-20"></div>
                  <Typography className="invalid-feedback">
                    {errMessage}
                  </Typography>
                  <FormControl
                    sx={{ m: 0, width: "-webkit-fill-available" }}
                    variant="standard"
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={12} lg={6} md={6}>
                        <LoadingButton
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={handleResetPassword}
                          disabled={formState.isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </FormControl>
                </>
              ) : isSendOTP ? (
                <>
                  <Grid className="login-text-background">
                    <Typography variant="h4" textAlign="center">
                      Enter OTP
                    </Typography>
                    <div className="empty-space-20"></div>
                  </Grid>
                  <Grid>
                    <TextField
                      name="txtEmail"
                      type="text"
                      label="Enter OTP"
                      fullWidth
                      value={intOTP}
                      onChange={(e) => setOTP(e.target.value)}
                    />
                    <Typography className="invalid-feedback">
                      {errors.txtEmail?.message}
                    </Typography>
                  </Grid>

                  <Typography className="invalid-feedback">
                    {errMessage}
                  </Typography>
                  <div className="empty-space-20"></div>
                  <FormControl
                    sx={{ m: 0, width: "-webkit-fill-available" }}
                    variant="standard"
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={12} lg={6} md={6}>
                        <LoadingButton
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={handleVerifyOTP}
                          disabled={formState.isSubmitting}
                        >
                          Verify OTP
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={12} lg={6} md={6} textAlign="right">
                        <a
                          onClick={() => setIsSendOTP(!isSendOTP)}
                          className="pointer"
                        >
                          Change email
                        </a>
                      </Grid>
                    </Grid>
                  </FormControl>
                </>
              ) : isForgotPassword ? (
                <>
                  <Grid className="login-text-background">
                    <Typography variant="h4" textAlign="center">
                      Forgot Password
                    </Typography>
                    <div className="empty-space-20"></div>
                  </Grid>
                  <Grid>
                    <TextField
                      name="txtEmail"
                      type="text"
                      label="Email"
                      fullWidth
                      value={forgotPasswordEmail}
                      onChange={(e) => setForgotPasswordEmail(e.target.value)}
                    />
                    <Typography className="invalid-feedback">
                      {errors.txtEmail?.message}
                    </Typography>
                  </Grid>

                  <Typography className="invalid-feedback">
                    {errMessage}
                  </Typography>
                  <div className="empty-space-20"></div>
                  <FormControl
                    sx={{ m: 0, width: "-webkit-fill-available" }}
                    variant="standard"
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={12} lg={6} md={6}>
                        <LoadingButton
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={handleSendOTP}
                          disabled={formState.isSubmitting}
                        >
                          Send OTP
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={12} lg={6} md={6} textAlign="right">
                        <a onClick={handleForgotPassword} className="pointer">
                          Cancel
                        </a>
                      </Grid>
                    </Grid>
                  </FormControl>
                </>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid className="login-text-background">
                    <Typography variant="h4" textAlign="center">
                      Login
                    </Typography>
                  </Grid>
                  <Grid textAlign="center">
                    <div className="empty-space-40"></div>
                    <Typography variant="body" textAlign="center">
                      Login Using Your Registered Email ID and Password
                    </Typography>
                    <div className="empty-space-40"></div>
                    <TextField
                      name="txtEmail"
                      type="text"
                      label="Email"
                      fullWidth
                      {...register("txtEmail")}
                      className={`form-control ${
                        errors.txtEmail ? "is-invalid" : ""
                      }`}
                    />
                    <Typography className="invalid-feedback">
                      {errors.txtEmail?.message}
                    </Typography>
                  </Grid>
                  <div className="empty-space-20"></div>
                  <Grid>
                    <TextField
                      name="txtPassword"
                      type="password"
                      label="Password"
                      fullWidth
                      {...register("txtPassword")}
                      className={`form-control ${
                        errors.txtPassword ? "is-invalid" : ""
                      }`}
                    />
                    <Typography className="invalid-feedback">
                      {errors.txtPassword?.message}
                    </Typography>
                  </Grid>
                  <div className="empty-space-20"></div>
                  <Typography className="invalid-feedback">
                    {errMessage}
                  </Typography>
                  <FormControl
                    sx={{ m: 0, width: "-webkit-fill-available" }}
                    variant="standard"
                  >
                    <div className="empty-space-20"></div>
                    <Grid container alignItems="center">
                      <Grid item xs={12} lg={6} md={6}>
                        <LoadingButton
                          size="large"
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={formState.isSubmitting}
                        >
                          Login
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={12} lg={6} md={6} textAlign="right">
                        <a onClick={handleForgotPassword} className="pointer">
                          Forgot Password?
                        </a>
                      </Grid>
                    </Grid>
                  </FormControl>
                </form>
              )}
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
