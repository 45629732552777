import React, { useEffect, useRef, useState } from "react";

//design imports from material
import {
  Grid,
  TextField,
  FormControl,
  Typography,
  Button,
} from "@mui/material";

//layout import
import Layout from "../../../layout/Layout";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";

//form imports
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import {
  config,
  _numberOnly,
  projectMasterAPI,
  statusMasterAPI,
  vendorAPI,
  purchaseOrderAPI,
  termsAndConditionsAPI,
  userData,
  manageCompaniesAPI,
} from "../../../config";
import { AlertMessage } from "../../../components/AlertMessage";
import { getValue } from "@mui/system";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// const names = ["KG Terms & Conditions", "Validity"];
let total = 0;
let cgst = 0;
let igst = 0;
let sgst = 0;
let totalAmount = 0;

function PurchaseOrder() {
  const childRef = useRef();
  const navigate = useNavigate();

  // add field
  const [serviceList, setServiceList] = useState([
    { txtItemName: "", txtUnits: "" },
  ]);
  const [totalWithoutGST, setTotalWithoutGST] = useState(0);
  const [totalCgst, settotalCgst] = useState(0);
  const [totalSgst, setTotalSgst] = useState(0);
  const [totalIgst, setTotalIgst] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const [unitsFlag, setUnitsFlag] = useState(false);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];

    console.log({ name: name, value: value })

    console.log({ "list up": list });

    list[index][name] = value;
    // list[index]["intLineItemTotalWithGST"] = 20;
    let tempGST = parseInt(list[index]["intGST"]) / 2;
    list[index]["intLineItemTotalWithoutGST"] =
      parseInt(list[index]["intAmount"]) * parseInt(list[index]["intQuantity"]);

    if (vendorDetails.state != "TamilNadu") {

      list[index]["intIGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"] + (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];

    } else {
      list[index]["intCGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
      list[index]["intSGST"] =
        (tempGST / 100) * list[index]["intLineItemTotalWithoutGST"];
    }

    list[index]["intTGST"] = list[index]["intCGST"] + list[index]["intsGST"];

    // list[index]["intLineItemTotalWithGST"] =
    //   list[index]["intLineItemTotalWithoutGST"] +
    //   list[index]["intCGST"] +
    //   list[index]["intSGST"];

    if (vendorDetails.state != "TamilNadu") {
      list[index]["intLineItemTotalWithGST"] =
        list[index]["intLineItemTotalWithoutGST"] +
        list[index]["intIGST"];
    } else {
      list[index]["intLineItemTotalWithGST"] =
        list[index]["intLineItemTotalWithoutGST"] +
        list[index]["intCGST"] +
        list[index]["intSGST"];
    }

    total = 0;
    for (var i = 0; i < list.length; i++) {
      total = total + list[i]["intLineItemTotalWithoutGST"];
    }
    igst = 0;
    for (var i = 0; i < list.length; i++) {
      igst = igst + list[index]["intIGST"];
    }
    cgst = 0;
    for (var i = 0; i < list.length; i++) {
      cgst = cgst + list[index]["intCGST"];
    }
    sgst = 0;
    for (var i = 0; i < list.length; i++) {
      sgst = sgst + list[index]["intSGST"];
    }
    totalAmount = 0;
    for (var i = 0; i < list.length; i++) {
      totalAmount = totalAmount + list[i]["intLineItemTotalWithGST"];
    }

    console.log({ "list down": list });

    setTotalWithoutGST(total);
    setServiceList(list);

    if (igst > 0) {
      settotalCgst(0);
      setTotalSgst(0);
      setTotalIgst(igst);
    } else if (cgst > 0 && sgst > 0) {
      setTotalIgst(0);
      settotalCgst(cgst);
      setTotalSgst(sgst);
    }
    // settotalCgst(cgst);
    // setTotalSgst(sgst);
    // setTotalIgst(igst);
    setTotalAmount(totalAmount);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { txtItemName: "", txtUnits: "" }]);
  };
  const validationSchema = Yup.object().shape({
    txtCompanyName: Yup.string().required("Company Name is required"),
    intVendorID: Yup.string().required("Vendor Name is required"),
    intProjectID: Yup.string().required("Project Name is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);

  const { errors } = formState;

  const [PoDate, setPoDate] = React.useState(dayjs().format("YYYY-MM-DD"));

  // Vendor List
  const [vendorList, setVendorList] = useState([]);

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 3,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = vendorAPI;

      axios(config)
        .then(function (response) {
          console.log({ vendor: response.data });
          // return;
          setVendorList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  let tempRefs = useRef(false);

  const [companyName, setCompanyName] = React.useState("");

  //Marital Details
  const [ProjectNameList, setProjectNameList] = useState([]);
  const [intProjectID, setProjectName] = useState(0);
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 6,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = projectMasterAPI;

      axios(config)
        .then(function (response) {
          setProjectNameList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  //Terms Of Payments
  const [termsofPayments, setTermsofPayments] = useState([]);
  const [txtTermsOfPayments, setTermsofPayment] = useState("");
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 59,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setTermsofPayments(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // dispatch
  const [dispatch, setDispatch] = useState([]);
  const [txtDispatchThrough, setDispatchThrough] = useState("");
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 65,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setDispatch(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  // Purchase
  const [unitsList, setUnitsList] = useState([]);
  const [units, setUnits] = useState("");
  tempRefs.current = false;
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 88,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setUnitsList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);
  console.log(units);

  // Purchase
  const [Purchase, setPurchase] = useState([]);
  const [txtPurchaseClassify, settxtPurchase] = useState("");
  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 68,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setPurchase(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  tempRefs.current = false;

  // Vendor Details
  const [vendorDetails, setVendorDetails] = useState({});
  const [intVendorID, setVendorID] = useState(0);

  const handleVendor = (e) => {
    // console.log({ selected_vendorId: e.target.value })
    setVendorID(e.target.value);
    setValue("intVendorID", e.target.value);

    const data = {
      intType: 7,
      intvendorAutoID: e.target.value,
      intLimit: 1,
    };

    console.log({ data: data })

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = vendorAPI;

    axios(config)
      .then(function (response) {
        console.log({ full_Vendor: response.data })
        setVendorDetails(response.data.result[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // console.log({ full_Vendor: vendorDetails });
  // console.log({ selected_vendorId: intVendorID })


  tempRefs.current = false;

  // Purchase
  const [termsNames, setTermsNames] = useState([]);

  tempRefs.current = false;

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 4,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = termsAndConditionsAPI;

      axios(config)
        .then(function (response) {
          setTermsNames(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  tempRefs.current = false;

  const [termsName, setTermsName] = useState("");
  const [terms, setTermsandConditions] = useState("");

  const handleChangeterm = (event) => {
    setTermsName(event.target.value);

    const data = {
      intType: 3,
      intID: event.target.value,
      intLimit: 1,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = termsAndConditionsAPI;

    axios(config)
      .then(function (response) {
        setTermsandConditions(response.data.result[0]);
        window.tinymce.init({
          selector: "#textarea",
          width: "100%",
          menubar: false,
          plugins:
            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
          toolbar:
            "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
        });
        setTimeout(function () {
          window.tinymce
            .get("textarea")
            .setContent(response.data.result[0].description);
        }, 1150);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // axios(config)
  // .then(function (response) {
  //   console.log(response.data.result[0].title);
  //   setTermsNames(response.data.result[0].title);
  //   window.tinymce.init({
  //     selector: "#textarea",
  //     width: "100%",
  //     menubar: false,
  //     plugins:
  //       "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
  //     toolbar:
  //       "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
  //   });
  //   setTimeout(function () {
  //     window.tinymce
  //       .get("textarea")
  //       .setContent(response.data.result[0].description);
  //   }, 1150);
  // })

  const [transportGST, setTransportGST] = useState(0);
  const [transportAmount, setTransportAmount] = useState(0)
  const [transportCharge, setTransportCharge] = useState(0);

  const handleTransportCharge = (price) => {
    let GstValue = (price / 100) * parseInt(transportGST);
    console.log("GstValue", GstValue)
    let total = isNaN(parseInt(GstValue) + parseInt(price))
      ? 0
      : parseInt(GstValue) + parseInt(price);
    console.log("total", total);
    setTransportCharge(total);
    console.log("totalAmount", totalAmount)
    setTotalAmount(totalAmount + total);
  };
  console.log(totalamount)
  const [baseuploadagreement, setBaseUploadAgreement] = useState("");

  const uploadagreement = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseUploadAgreement(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [companyNames, setCompanyNames] = React.useState("");
  // getting all companies
  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 5,
        // intParentId: 42,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = manageCompaniesAPI;

      axios(config)
        .then(function (response) {
          // console.log({ coData: response.data.result });
          setCompanyNames(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const twoDecimal = (x) => {
    return Number.parseFloat(x).toFixed(2);
  };

  // getting gst data
  // const [gstListData, setGstListData] = useState([])
  // useEffect(() => {
  //   // if (tempRefs.current === false) {
  //   const data = {
  //     intType: 2,
  //     intParentId: 77,
  //   };

  //   config.method = "POST";
  //   config.data = JSON.stringify(data);
  //   config.url = statusMasterAPI;

  //   axios(config)
  //     .then(function (response) {
  //       console.log({ gstData: response.data.result });
  //       // setGstListData(response.data.result)
  //       // setCompanyNames(response.data.result);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   return () => {
  //     tempRefs.current = true;
  //   };
  //   // }
  // }, []);



  const [PoTerm, setPOTerm] = React.useState(0)
  function onSubmit(data) {
    // editing text area items
    let poDesc = window.tinymce.get("textarea").getContent();
    let newPoDesc = poDesc.replaceAll("<p>", "");
    let finalPoDesc = newPoDesc.replaceAll("</p>", "");

    data.lineItems = serviceList;
    data.intTotalWithoutGST = totalWithoutGST;
    // data.intTotalIGST = totalIgst;
    // data.intTotalSGST = totalSgst;
    // data.intTotalCGST = totalCgst;

    if (totalIgst > 0) {
      data.intTotalSGST = 0;
      data.intTotalCGST = 0;
      data.intTotalIGST = twoDecimal(totalIgst);
    } else if (totalCgst > 0 && totalSgst > 0) {
      data.intTotalIGST = 0;
      data.intTotalSGST = twoDecimal(totalSgst);
      data.intTotalCGST = twoDecimal(totalCgst);
    }
    data.txtPOValidTo = PoDate;
    data.intTotalWithGST = twoDecimal(totalamount);
    data.intCreatedBy = userData.emp_id;
    data.intVendorID = intVendorID;
    data.intStatus = 98;
    data.intTransportGst = transportGST;
    data.intTransportAmount = transportAmount;
    data.intTransportCharge = transportCharge;
    data.txtTermsHeading = PoTerm;
    data.txtTerms = finalPoDesc;

    data.intType = 1;

    // console.log(data);
    // return;
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = purchaseOrderAPI;

    axios(config)
      .then(function (response) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setTimeout(function () {
          window.location.reload(true);
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid item xs={12} lg={12} md={12}>
          <Grid container spacing={3}>
            <Grid
              textAlign="center"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <Typography className="page_title" textAlign="left">
                Purchase Order
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Typography color="text.primary">purchase order</Typography>
                <Typography color="text.primary">create</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              textAlign="right"
              item
              className="form-lable"
              xs={12}
              lg={6}
              md={6}
            >
              <LoadingButton
                size="medium"
                variant="contained"
                color="secondary"
                className="button_first"
                href="/purchase-order/list"
              >
                List purchase order
              </LoadingButton>
              <LoadingButton
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
              >
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <div className="empty-space-40"></div>
        <Grid className="purchaseorder">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="popading" spacing={2}>
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("txtOrderType")}
                    label="Order Type"
                    onChange={(e) => {
                      setValue("txtOrderType", e.target.value);
                    }}
                  >
                    <MenuItem value="1">Purchase Order</MenuItem>
                    <MenuItem value="2">Work Order</MenuItem>
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtOrderType?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Vendor Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={intVendorID}
                    label="Vendor Name"
                    onChange={(e) => {
                      handleVendor(e);
                    }}
                  >
                    {vendorList &&
                      vendorList.length > 0 &&
                      vendorList.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.name + " (" + row.id + ")"}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.intVendorID?.message}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Company Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={companyName}
                    label="Company Name"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setValue("txtCompanyName", e.target.value);
                    }}
                  >
                    {companyNames &&
                      companyNames.length > 0 &&
                      companyNames.map((row, index) => (
                        <MenuItem value={row.id} key={index}>
                          {row.company_name}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.txtCompanyName?.message}
                  </Typography>
                </FormControl>
              </Grid>

              <Grid
                item
                className="form-lable po_lebleterm"
                xs={12}
                lg={3}
                md={3}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Project
                  </InputLabel>
                  <Select
                    labelId="Select Project"
                    id="demo-simple-select"
                    value={intProjectID}
                    label="Select Project"
                    onChange={(e) => {
                      setProjectName(e.target.value);
                      setValue("intProjectID", e.target.value);
                    }}
                  >
                    {ProjectNameList.map((row, index) => (
                      <MenuItem value={row.id} key={index}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography className="invalid-feedback">
                    {errors.intProjectID?.message}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="pomargin table_po">
              <Grid item className="form-lable" xs={12} lg={7} md={7}>
                {vendorDetails.hasOwnProperty("name") ? (
                  <>
                    <Grid className="info_details">
                      <Typography>To</Typography>
                      <Typography className="companyna">
                        {vendorDetails.name}
                      </Typography>
                      <Typography>{vendorDetails.mobile_no}</Typography>
                      <Typography>
                        {vendorDetails.street_address}, {vendorDetails.city}
                      </Typography>
                      <Typography>
                        {vendorDetails.pincode} {vendorDetails.state}
                      </Typography>
                      <Typography>India</Typography>
                      <Typography>GSTIN {vendorDetails.gst}</Typography>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item className="po_table table_po" xs={12} lg={5} md={5}>
                <Typography className="align_center">Employee No</Typography>
                <hr></hr>
                <Grid
                  container
                  spacing={2}
                  justifyContent={`center`}
                  className="po_leble"
                >
                  <Grid item className="lable_text" xs={12} lg={5} md={5}>
                    <Typography>Mode/Terms of Payment : </Typography>
                  </Grid>
                  <Grid item xs={12} lg={7} md={7}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={txtTermsOfPayments}
                        onChange={(e) => {
                          setTermsofPayment(e.target.value);
                          setValue("txtTermsOfPayments", e.target.value);
                        }}
                      >
                        {/* {termsofPayments && termsofPayments.length > 0 ? ( */}
                        {termsofPayments.map((row, index) => (
                          <MenuItem value={row.name} key={index}>
                            {row.name}
                          </MenuItem>
                        ))}
                        {/* ) : (
                        <></>
                      )} */}
                      </Select>
                      <Typography className="invalid-feedback">
                        {errors.txtTermscondition?.message}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent={`center`}
                  className="po_leble"
                >
                  <Grid item className="lable_text" xs={12} lg={5} md={5}>
                    <Typography>Supplier Ref./Quotation No.:</Typography>
                  </Grid>
                  <Grid item xs={12} lg={7} md={7}>
                    <TextField
                      name="familyname"
                      type="text"
                      {...register("txtQuotationNo")}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent={`center`}
                  className="po_leble"
                >
                  <Grid item className="lable_text" xs={12} lg={5} md={5}>
                    <Typography>Purchase Classification : </Typography>
                  </Grid>
                  <Grid item xs={12} lg={7} md={7}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={txtPurchaseClassify}
                        onChange={(e) => {
                          settxtPurchase(e.target.value);
                          setValue("txtPurchaseClassify", e.target.value);
                        }}
                      >
                        {/* {Purchase && Purchase.length > 0 ? ( */}
                        {Purchase.map((row, index) => (
                          <MenuItem value={row.id} key={index}>
                            {row.name}
                          </MenuItem>
                        ))}
                        {/* ) : (
                        <></>
                      )} */}
                      </Select>
                      <Typography className="invalid-feedback">
                        {errors.txtPurchaseClassify?.message}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent={`center`}
                  className="po_leble"
                >
                  <Grid item className="lable_text" xs={12} lg={5} md={5}>
                    <Typography>Choose Valid upto Date :</Typography>
                  </Grid>
                  <Grid item xs={12} lg={7} md={7}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      format="DD-MM-YYYY"
                    >
                      <MobileDatePicker
                        value={PoDate}
                        inputFormat="DD/MM/YYYY"
                        fullWidth
                        {...register("PoDate")}
                        onChange={(newValue) => {
                          setPoDate(newValue);
                          setValue(
                            "PoDate",
                            dayjs(newValue).format("YYYY-MM-DD")
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            helperText={errors.PoDate?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="form-lable" xs={12} lg={12} md={12}>
                <table className={`table`}>
                  <thead>
                    <th>
                      <Typography>S. No.</Typography>
                    </th>
                    <th>
                      <Typography>Description</Typography>
                    </th>
                    <th>
                      <Typography>Units </Typography>
                    </th>
                    <th>
                      <Typography>Rate </Typography>
                    </th>
                    <th>
                      <Typography>Total Amount</Typography>
                    </th>
                    <th>
                      <Typography>GST </Typography>
                    </th>
                    {vendorDetails.state != "TamilNadu" ? (
                      <>
                        <th>
                          <Typography>IGST </Typography>
                        </th>
                      </>
                    ) : (
                      <>
                        <th>
                          <Typography>CGST </Typography>
                        </th>
                        <th>
                          <Typography>SGST </Typography>
                        </th>
                      </>
                    )}
                    <th>
                      <Typography>Total Amount + GST </Typography>
                    </th>
                    <th className="add_button">
                      <small style={{ fontSize: "16px", fontWeight: "100" }}>Add New</small>
                      <Button
                        variant="outlined"
                        onClick={handleServiceAdd}
                        startIcon={<AddIcon />}
                        color="success"
                      ></Button>
                    </th>
                  </thead>
                  <tbody>
                    {serviceList.map((singleService, index) => (
                      <tr className="table_padding">
                        <td className={`text-center`}>
                          <Typography>{index + 1}</Typography>
                        </td>
                        <td className="po_leble">
                          <input
                            name="txtItemName"
                            type="text"
                            id="txtItemName"
                            value={singleService.txtItemName}
                            onChange={(e) => handleServiceChange(e, index)}
                            required
                          />
                        </td>
                        <td className="po_leble">
                          <Select
                            name="txtUnits"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={singleService.txtUnits}
                            onChange={(e) => {
                              setUnitsFlag(true);
                              handleServiceChange(e, index);
                            }}
                          >
                            {unitsList.length > 0 &&
                              unitsList.map((row, index) => (
                                <MenuItem value={row.id} key={index}>
                                  {row.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {unitsFlag && (
                            <TextField
                              name="intQuantity"
                              type="number"
                              value={singleService.intQuantity}
                              onChange={(e) => handleServiceChange(e, index)}
                            />
                          )}
                        </td>
                        <td className="po_leble">
                          <TextField
                            name="intAmount"
                            type="number"
                            // {...register("intAmount")}
                            value={singleService.intAmount}
                            onChange={(e) => handleServiceChange(e, index)}
                          />
                        </td>
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {isNaN(singleService.intLineItemTotalWithoutGST)
                              ? `0.00`
                              : singleService.intLineItemTotalWithoutGST}
                          </Typography>
                        </td>
                        <td className="po_leble">
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              name="intGST"
                              id="demo-simple-select"
                              value={singleService.intGST}
                              onChange={(e) => {
                                handleServiceChange(e, index);
                              }}
                            >

                              <MenuItem value={`5`} key={index}>
                                GST 5%
                              </MenuItem>
                              <MenuItem value={`12`} key={index}>
                                GST 12%
                              </MenuItem>
                              <MenuItem value={`18`} key={index}>
                                GST 18%
                              </MenuItem>
                              <MenuItem value={`28`} key={index}>
                                GST 28%
                              </MenuItem>
                            </Select>
                            <Typography className="invalid-feedback">
                              {errors.intGST?.message}
                            </Typography>
                          </FormControl>
                        </td>
                        {vendorDetails.state != "TamilNadu" ? (
                          <>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intIGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intIGST)}
                              </Typography>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intCGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intCGST)}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {isNaN(singleService.intSGST)
                                  ? `0.00`
                                  : twoDecimal(singleService.intSGST)}
                              </Typography>
                            </td>
                          </>
                        )}
                        {/* <td className="po_leble">
                          <Typography>{singleService.intSGST}</Typography>
                        </td> */}
                        <td className="po_leble">
                          <Typography textAlign="right">
                            {isNaN(singleService.intLineItemTotalWithGST)
                              ? `0.00`
                              : twoDecimal(
                                singleService.intLineItemTotalWithGST
                              )}
                          </Typography>
                        </td>
                        <td className="cancel_button">
                          {serviceList.length !== 1 && (

                            <>
                              <div style={{ textAlign: "center", display: "flex", flexDirection: "column" }}>
                                <Button
                                  variant="outlined"
                                  onClick={(e) => {
                                    handleServiceChange(e, index);
                                    handleServiceRemove(index);
                                  }}
                                  startIcon={<DeleteIcon />}
                                  color="error"
                                ></Button>

                                <small style={{ fontSize: "13px", fontWeight: "90" }}>Remove</small>
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className="tbody_col">
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td>
                        <Typography>Total</Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={total}>
                          {isNaN(total) ? `0.00` : twoDecimal(total)}
                        </Typography>
                      </td>
                    </tr>
                    {vendorDetails.state != "TamilNadu" ? (
                      <>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td>
                            <Typography>IGST </Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography value={igst}>
                              {isNaN(igst) ? `0.00` : twoDecimal(igst)}
                            </Typography>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td>
                            <Typography>CGST </Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography value={cgst}>
                              {isNaN(cgst) ? `0.00` : twoDecimal(cgst)}
                            </Typography>
                          </td>
                        </tr>
                        <tr className="table_padding_40">
                          <td className={`text-center`}>
                            <Typography></Typography>
                          </td>
                          <td>
                            <Typography>SGST </Typography>
                          </td>
                          <td colSpan={7}>
                            <Typography value={sgst}>
                              {isNaN(sgst) ? `0.00` : twoDecimal(sgst)}
                            </Typography>
                          </td>
                        </tr>
                      </>
                    )}
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td>
                        <Typography>Transport Charges</Typography>
                      </td>
                      <td className="po_leble">
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            name="intTransportGST"
                            id="demo-simple-select"
                            {...register("intTransportGST")}
                            onChange={(e) => {
                              setValue("intTransportGST", e.target.value);
                              setTransportGST(e.target.value);
                            }}
                          >
                            <MenuItem value={`5`} key={1}>
                              GST 5%
                            </MenuItem>
                            <MenuItem value={`12`} key={2}>
                              GST 12%
                            </MenuItem>
                            <MenuItem value={`18`} key={3}>
                              GST 18%
                            </MenuItem>
                            <MenuItem value={`28`} key={4}>
                              GST 28%
                            </MenuItem>
                          </Select>
                          <Typography className="invalid-feedback">
                            {errors.intGST?.message}
                          </Typography>
                        </FormControl>
                      </td>
                      <td className="po_leble">
                        <TextField
                          name="intTransportAmount"
                          type="number"
                          {...register("intTransportAmount")}
                          onChange={(e) => {
                            setTransportAmount(e.target.value);
                            handleTransportCharge(e.target.value);
                          }}
                        />
                      </td>
                      <td colSpan={5}>
                        <Typography value={transportCharge}>
                          {isNaN(transportCharge)
                            ? `0.00`
                            : twoDecimal(transportCharge)}
                        </Typography>
                      </td>
                    </tr>
                    <tr className="table_padding_40">
                      <td className={`text-center`}>
                        <Typography></Typography>
                      </td>
                      <td>
                        <Typography>Total amount with GST </Typography>
                      </td>
                      <td colSpan={7}>
                        <Typography value={totalamount}>
                          {isNaN(totalamount)
                            ? `0.00`
                            : twoDecimal(totalamount)}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
            <div className="empty-space-20"></div>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              textAlign="left"
              className="po_lebleterm"
            >
              <FormControl className="form_control" sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Terms & Conditions
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={termsName}
                  onChange={handleChangeterm}
                  input={<OutlinedInput label="Terms & Conditions" />}
                  MenuProps={MenuProps}
                >
                  {termsNames.map((terms) => (
                    <MenuItem key={terms.title} value={terms.id}>
                      <ListItemText
                        primary={terms.title}
                        onClick={() => {
                          setPOTerm(terms.id);
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              textAlign={`right`}
              display={`flex`}
              justifyContent={`flex-end`}
            >

              <TextField fullWidth id="textarea" />
            </Grid>
            <Grid></Grid>
            <Grid item className="form-lable" xs={12} lg={4} md={4}>
              <label> Document : </label>
              <br></br>
              <Button variant="contained" component="label">
                <input
                  name="agreementdocument"
                  type="file"
                  accept="image/jpeg,image/png,application/pdf"
                  fullWidth
                  onChange={(e) => {
                    uploadagreement(e);
                  }}
                />
              </Button>
            </Grid>
            <div className="empty-space-20"></div>
            <Grid item xs={12} md={12} lg={12} textAlign="right">
              <Button variant="primary" className={`btn-primary`} type="submit">
                Submit
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default PurchaseOrder;
