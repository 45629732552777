import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import {
  config,
  positionMasterAPI,
  statusMasterAPI,
  departmentMasterAPI,
  handleHrMasterAPI,
  TeamLeaderAPI,
} from "../config";
import { AlertMessage } from "./AlertMessage";

export const AddTeamLeader = forwardRef(({ ...props }, ref) => {
  const [Status, setStatus] = useState(2);
  const [departmentID, setDepartmentname] = useState(0); //Load Data
  const [employeeID, setEmployeeID] = useState(0);
  const [teamLeaderId, setTeamLeaderId] = useState(0);

  const [departmentList, setDepartmentList] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const childRef = useRef();
  let tempRefs = useRef(false);
  let tempRefs2 = useRef(false);
  tempRefs.current = false;

  useImperativeHandle(ref, () => ({
    setData(data) {
      const tempData = JSON.parse(data);
      console.log("tempData", tempData);
      setStatus(tempData.status);
      setDepartmentname(tempData.intDepartmentId);
      setEmployeeID(tempData.intEmployeeId);
      setTeamLeaderId(tempData.intTeamLeaderId);
    },
  }));

  useEffect(() => {
    const fetchDepartmentData = async () => {
      //   if (tempRefs.current === false) {
      const data = {
        intType: 3,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = departmentMasterAPI;

      axios(config)
        .then(function (response) {
          setDepartmentList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
      //   }
    };

    const fetchEmployeeData = async () => {
      //   if (tempRefs2.current === false) {
      const data = {
        intType: 7,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          setEmployeeList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs2.current = true;
      };
      //   }
    };

    const fetchStatusData = async () => {
      //   if (tempRefs.current === false) {
      const data = {
        intType: 2,
        intParentId: 1,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          setStatusList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
      //   }
    };

    fetchDepartmentData();
    fetchEmployeeData();
    fetchStatusData();
  }, []);

  console.log("Department", departmentList);
  console.log("EmployeeList", EmployeeList);
  console.log("Status", statusList);

  //Department Details

  //On Submit
  //   const handleSubmit = () => {
  //     const data = JSON.stringify({
  //       txtPositionName: positionName,
  //       intPositionStatus: Status,
  //       intDepartmentID: intDepartmentID,
  //     //   intRoleID: roleID,
  //       intPositionID: positionID,
  //       intType: 1,
  //     });

  //     if ("" == positionName) {
  //       childRef.current.triggerAlert(
  //         JSON.stringify({
  //           message: "Position Name Can't be empty",
  //           messageType: "error",
  //         })
  //       );
  //       return false;
  //     } else if (!intDepartmentID) {
  //       childRef.current.triggerAlert(
  //         JSON.stringify({
  //           message: "Department Name Can't be empty",
  //           messageType: "error",
  //         })
  //       );
  //       return false;
  //     } else if (!roleID) {
  //       childRef.current.triggerAlert(
  //         JSON.stringify({
  //           message: "Role Can't be empty",
  //           messageType: "error",
  //         })
  //       );
  //       return false;
  //     }

  //     config.method = "POST";
  //     config.data = data;
  //     config.url = positionMasterAPI;
  //     axios(config)
  //       .then(function (response) {
  //         window.location.reload(false);
  //         const tempData = response.data.message;
  //         childRef.current.triggerAlert(
  //           JSON.stringify({
  //             message: response.data.message,
  //             messageType: "success",
  //           })
  //         );

  //         setPositionName("");
  //         setRoleID(0);
  //         setDepartmentname(0);
  //         setStatus(2);
  //         props.fetchData(1);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         childRef.current.triggerAlert(
  //           JSON.stringify({
  //             message: error.data.message,
  //             messageType: "error",
  //           })
  //         );
  //       });
  //   };

  const handleSubmit = (data) => {
    // console.log("data", data);
    if (!departmentID) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Department Name can't be empty",
          messageType: "error",
        })
      );
      return false;
    } else if (!employeeID) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Employee Name must be selected",
          messageType: "error",
        })
      );
      return false;
    }

    const newData = JSON.stringify({
      intDepartmentId: departmentID,
      intEmployeeId: employeeID,
      intStatus: Status,
      intTeamLeaderId: teamLeaderId,
      intType: 1,
    });

    config.method = "POST";
    config.data = newData;
    config.url = TeamLeaderAPI;
    axios(config)
      .then(function (response) {
        window.location.reload(false);
        const tempData = response.data.message;
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        // setPositionName("");
        // setRoleID(0);
        // setDepartmentname(0);
        // setStatus(2);
        props.fetchData(1);
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: error.data.message,
            messageType: "error",
          })
        );
      });
  };

  return (
    <Grid container spacing={2} className={`bg-white`}>
      <AlertMessage ref={childRef} />
      <Grid item xs={12} md={3} lg={3}>
        <FormControl className={`form-control`} fullWidth>
          <InputLabel id="demo-simple-select-label">Department</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Department"
            // {...register("txtGender")}
            value={departmentID}
            onChange={(e) => {
              setDepartmentname(e.target.value);
              console.log(e.target.value);
            }}
            name="ddlType"
          >
            {departmentList.map((row, index) => (
              <MenuItem value={row.id}>{row.department_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Employee List</InputLabel>
          <Select
            labelId="Select Project"
            id="demo-simple-select"
            value={employeeID}
            label="Maristal Status"
            onChange={(e) => {
              setEmployeeID(e.target.value);
            }}
          >
            {EmployeeList.map((row, index) => (
              <MenuItem value={row.id} key={index}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <FormControl className={`form-control`} fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            label="Status"
            value={Status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            name="ddlType"
          >
            <MenuItem value={`2`}>Active</MenuItem>
            <MenuItem value={`3`}>Inactive</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12} lg={12} textAlign="right">
        <Button
          variant="primary"
          className={`btn-primary`}
          onClick={() => handleSubmit({ departmentID, employeeID, Status })}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
});
