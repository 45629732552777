import React, { useEffect, useState } from "react";
import companyLogo from "../assets/images/Logo/IMAGINET LOGO.png";
import Layout from "../layout/Layout";
import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import DashBoardAttendance from "./DashBoardAttendance/DashBoardAttendance";
import ProjectChart from "./DashBoardAttendance/ProjectChart";
import moment from "moment";

import { startOfMonth, endOfMonth, format } from "date-fns";

import Attendance from "./Dashboard/Attendance.jsx";
import ProjectPaymentChart from "./Dashboard/projectPaymentChart";
import PaymentRequextChart from "./Dashboard/PaymentRequextChart.jsx";
import AttendanceChart from "../../src/pages/Dashboard/attendanceChart.jsx";
import revenueImg from "../assets/images/dashboard/activity.svg";
import profileImg from "../assets/images/dashboard/profile.svg";
import infoImg from "../assets/images/dashboard/info.svg";

import { _getDropdownListData } from "../service";
import { config, dashboardAPI, manageCompaniesAPI } from "../config";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

function Dashboard() {
  const [fromDate, setfromDate] = useState(
    moment().utcOffset("+05:30").format("YYYY-MM-DD")
  );
  const [toDate, settoDate] = useState(
    moment().utcOffset("+05:30").format("YYYY-MM-DD")
  );

  const [type, setType] = useState("Today");

  const [companyList, setComanyList] = useState([]);
  const [companyID, setCompanyID] = useState(24);
  const [company, setCompany] = useState("All");
  const [totalList, settotalList] = useState({});
  const [paymentRequestList, setPaymentRequestList] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState({});

  useEffect(() => {
    let fromDate1 = moment().utcOffset("+05:30").format("YYYY-MM-DD"),
      toDate1 = moment().utcOffset("+05:30").format("YYYY-MM-DD");

    setfromDate(moment().utcOffset("+05:30").format("YYYY-MM-DD"));
    settoDate(moment().utcOffset("+05:30").format("YYYY-MM-DD"));

    if (type === "Month") {
      const currentDate = new Date();
      setfromDate(format(startOfMonth(currentDate), "yyyy-MM-dd"));
      settoDate(format(endOfMonth(currentDate), "yyyy-MM-dd"));

      fromDate1 = format(startOfMonth(currentDate), "yyyy-MM-dd");
      toDate1 = format(endOfMonth(currentDate), "yyyy-MM-dd");
    } else if (type === "This Week") {
      const currentDate = moment();

      fromDate1 = currentDate.clone().startOf("week");
      toDate1 = currentDate.clone().endOf("week");
      setfromDate(fromDate1);
      settoDate(toDate1);
    } else if (type === "Last Week") {
      const currentDate = moment();

      // Calculate the start and end dates of the last week
      fromDate1 = currentDate.clone().subtract(1, "weeks").startOf("week");

      toDate1 = currentDate.clone().subtract(1, "weeks").endOf("week");

      // Set the state with the calculated dates
      setfromDate(fromDate1);
      settoDate(toDate1);
    } else if (type === "This Year") {
      const currentDate = moment();

      // Calculate the start and end dates of the current year
      fromDate1 = currentDate.clone().startOf("year");
      toDate1 = currentDate.clone().endOf("year");

      // Set the state with the calculated dates
      setfromDate(fromDate1);
      settoDate(toDate1);
    }

    let requestData = {
      intType: 3,
      intID: companyID,
    };

    console.log("requestData", requestData);

    config.method = "POST";
    config.data = JSON.stringify(requestData);
    config.url = dashboardAPI;

    axios(config)
      .then(function (response) {
        settotalList(response.data.result[0]);
      })
      .catch(function (error) {
        console.log(error);
      });

       requestData = {
         intType: 1,
         intStatus: 57,
         txtFromDate: fromDate1,
         txtToDate: toDate1,
         intID: companyID,
       };
       config.method = "POST";
       config.data = JSON.stringify(requestData);
       config.url = dashboardAPI;
       axios(config)
         .then(function (response) {
           console.log(response.data.result);
           setPaymentRequestList(response.data.result);
         })
         .catch(function (error) {
           console.log(error);
         });

       //Project amount Summary
       requestData = {
         intType: 5,
         txtFromDate: fromDate1,
         txtToDate: toDate1,
         intID: companyID,
       };

       config.method = "POST";
       config.data = JSON.stringify(requestData);
       config.url = dashboardAPI;
       axios(config)
         .then(function (response) {
           let data = {
             total_amount: response.data.result[0].total_value
               ? response.data.result[0].total_value
               : 0,
             total_amount_spent: response.data.result[0].total_amount_spent
               ? response.data.result[0].total_amount_spent
               : 0,
             amount_spent: response.data.result[0].amout_spent
               ? response.data.result[0].amout_spent
               : 0,
           };

           setTotalRevenue(data);
         })
         .catch(function (error) {
           console.log(error);
         });
  }, [type, companyID]);

  console.log("companyID", companyID);
  console.log("totalLiat", totalList);

  //Get company Names
  useEffect(() => {
    let data = {
      requestData: {
        intType: 6,
      },
      API: manageCompaniesAPI,
      setData: setComanyList,
    };

    _getDropdownListData(data);
  }, []);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return (
    <Layout>
      {/* <img
        src={companyLogo}
        alt="LogRamcides Ticketing System (RTS)"
        className="logo-image"
      /> */}

      <DashBoardAttendance />
      <div style={{ marginTop: "20px" }}>
        <ProjectChart />
      </div>

      <Box className="top_sticky" sx={{ padding: "10px" }}>
        <Grid container spacing={1.5}>
          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            className="text-center"
            display={"flex"}
            alignItem={"center"}
            justifyContent={"center"}
          >
            <Typography fontSize={"1.8rem"} fontWeight={700} color={"#440a67"}>
              {fromDate === toDate
                ? moment(fromDate).format("DD/MM/YYYY")
                : moment(fromDate).format("DD/MM/YYYY") +
                  " to " +
                  moment(toDate).format("DD/MM/YYYY")}
            </Typography>
          </Grid>

          <Grid item xs={6} md={6} lg={6}>
            <Select
              className="button_elect"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={companyID}
              label="State"
              onChange={(e) => {
                
                setCompanyID(e.target.value);
                setCompany(e.target.value);
              }}
            >
              {companyList.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.company_name}
                </MenuItem>
              ))}
            </Select>
            <Select
              className="button_elect"
              defaultValue={"Today"}
              onChange={handleChange}
            >
              <MenuItem value={"Today"}>Today</MenuItem>
              <MenuItem value={"This Week"}>This Week</MenuItem>
              <MenuItem value={"Last Week"}>Last Week</MenuItem>
              <MenuItem value={"Month"}>Current Month</MenuItem>
              <MenuItem value={"Last Month"}>Last Month</MenuItem>
              <MenuItem value={"This Year"}>This Year</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ padding: "10px" }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} md={4} lg={6}>
            <Grid className="dashboard-card-3 d-green" margin={1}>
              <Typography variant="p">Active Employees</Typography>
              <Typography variant="h2">{totalList.active_users}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} lg={6}>
            <Grid className="dashboard-card-3 d-green-1" margin={1}>
              <Typography variant="p">Active Projects</Typography>
              <Typography variant="h2">{totalList.active_projects}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid className="dashboard-card-3 d-red-1" margin={1}>
              <Typography variant="p">Total Projects</Typography>
              <Typography variant="h2">{totalList.all_projects}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid className="dashboard-card-3 d-pink" margin={1}>
              <Typography variant="p">Completed Projects</Typography>
              <Typography variant="h2">
                {totalList.completed_projects}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Grid className="dashboard-card-3 d-red" margin={1}>
              <Typography variant="p">Hold Projects</Typography>
              <Typography variant="h2">{totalList.hold_projects}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ padding: "10px" }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ padding: "10px" }}>
              <Grid container spacing={1.5}>
                <Grid item xs={12} md={12} lg={8}>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12} md={6} lg={12}>
                      <Typography className=" top-selling ">
                        Attendance
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Grid
                        style={{
                          borderRadius: 5,
                          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                          height: 350,
                          padding: 15,
                        }}
                      >
                        <AttendanceChart />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Grid
                        style={{
                          borderRadius: 5,
                          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                          height: 350,
                          padding: 15,
                        }}
                      >
                        <Attendance
                          fromDate={fromDate}
                          toDate={toDate}
                          type={type}
                          companyID={companyID}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <Grid container spacing={1.5}>
                    <Grid item xs={12} md={6} lg={12}>
                      <Typography className="top-selling">
                        Completed Payment Requests ({type})
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={12}
                      style={{ maxHeight: 350, overflowY: "auto" }}
                    >
                      {paymentRequestList.length > 0 ? (
                        <Table>
                          <TableBody>
                            {paymentRequestList.map((obj, index) => (
                              <TableRow className="no-border" key={obj.name}>
                                <TableCell className="dashboard-td">
                                  <Typography className="td_t">
                                    {obj.project_name}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className="dashboard-td text-center"
                                  style={{ fontWeight: 800, width: 100 }}
                                >
                                  <Typography
                                    style={{ color: "rgb(16, 185, 129)" }}
                                  >
                                    {obj.formatted_amount}
                                  </Typography>
                                </TableCell>
                                <TableCell className="dashboard-td text-center">
                                  <Typography className="ranking">
                                    #{index + 1}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <>No records found</>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ padding: "10px" }}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              style={{ fontSize: "20px", fontWeight: 600, color: "black" }}
            >
              Projects Payment
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={1.5}>
              <Grid item xs={12} md={4} lg={4}>
                <Grid className="dashboard-card_2 pink">
                  <Grid>
                    <img
                      src={revenueImg}
                      alt="Ramcides"
                      width={`70px`}
                      height={`70px`}
                    />
                  </Grid>
                  <Grid style={{ margin: "0px 0px 0px 16px" }}>
                    <Typography className="dashborad-card-title">
                      Active Projects Value
                    </Typography>
                    <Typography className="dashborad-card-data">
                      {totalRevenue.total_amount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Grid className="dashboard-card_2 orange">
                  <Grid>
                    <img
                      src={profileImg}
                      alt="KG"
                      width={`70px`}
                      height={`70px`}
                    />
                  </Grid>
                  <Grid style={{ margin: "0px 0px 0px 16px" }}>
                    <Typography className="dashborad-card-title">
                      Total Amount Spent
                    </Typography>
                    <Typography className="dashborad-card-data">
                      {totalRevenue.total_amount_spent}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <Grid className="dashboard-card_2 light_green">
                  <Grid>
                    <img
                      src={infoImg}
                      alt="KG"
                      width={`70px`}
                      height={`70px`}
                    />
                  </Grid>

                  <Grid style={{ margin: "0px 0px 0px 16px" }}>
                    <Grid style={{ margin: "0px 0px 0px 16px" }}>
                      <Typography className="dashborad-card-title">
                        Amount Spend Today
                      </Typography>
                      <Typography className="dashborad-card-data">
                        {totalRevenue.amount_spent}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Grid
                  style={{
                    borderRadius: 5,
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                    height: 600,
                    padding: 15,
                  }}
                >
                  <ProjectPaymentChart
                    fromDate={fromDate}
                    toDate={toDate}
                    type={type}
                    companyID={companyID}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        
        </Grid>
      </Box>
    </Layout>
  );
}
export default Dashboard;
