import React, { useEffect, useRef } from "react";

//design imports from material
import {
  Grid,
  FormLabel,
  TextField,
  Typography,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";

//layout import
import Layout from "../../layout/Layout";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

//form imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StateList, { stateList } from "../../components/StateList";

//date picker
import dayjs from "dayjs";

import axios from "axios";
import { config, clientMasterAPI, statusMasterAPI } from "../../config";
import { AlertMessage } from "../../components/AlertMessage";
import { useParams, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

function ClientMasterEdit() {
  const navigate = useNavigate();
  const { clientid } = useParams();
  const validationSchema = Yup.object().shape({
    txtClientName: Yup.string().required("Client Name is required"),
    txtEmail: Yup.string().required("Emali ID is required"),
    intMobileNumber: Yup.string().required("Mobile Number is required"),
    // intSecMobileNumber: Yup.string().required(
    //   "Secondary Mobile Number is required"
    // ),
    // txtVendorCode: Yup.string().required("Vendor Code is required"),
    // txtClientType: Yup.string().required("Client Type is required"),
    // txtClientGST: Yup.string().required("Client GST Number is required"),
    // txtClientPAN: Yup.string().required("Client PAN Number is required"),
    txtAddress: Yup.string().required("Address is required"),
    txtCity: Yup.string().required("State / City is required"),
    intPincode: Yup.string().required("Pincode is required"),
  });

  const [stateName, setStateName] = React.useState("");

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {

    // console.log(data)
    // return;
    data.intType = 2;
    data.intStatus = clientStatus;
    data.intClientID = clientid;

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = clientMasterAPI;

    axios(config)
      .then(function (response) {
        // handleFormReset();
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setTimeout(function () {
          navigate("/client/list");
        }, 2000);

        // setTimeout(function () {
        //   navigate(`/client-master/report`);
        // }, 3000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let assetsRef = useRef(false);
  const clientId = parseInt(clientid, 10)

  useEffect(() => {
    if (assetsRef.current === false) {
      const data = JSON.stringify({
        intClientID: clientId,
        intType: 4,
      });

      config.method = "POST";
      config.data = data;
      config.url = clientMasterAPI;

      axios(config)
        .then(function (response) {
          setStateName(response.data.result);
          console.log({ rsData: response.data.result });
          handleFormReset(response.data.result[0]);
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        assetsRef.current = true;
      };
    }
  }, []);

  const handleFormReset = (data) => {
    console.log({ data: data })
    let defaultValues = {};
    setStateName(data.state);
    setClientStatus(parseInt(data.status))

    defaultValues.txtClientName = data.client_name;
    defaultValues.txtEmail = data.email;
    defaultValues.intMobileNumber = data.primary_number;
    defaultValues.intSecMobileNumber = data.secondary_number;
    // defaultValues.txtVendorCode = data.vendor_code;
    // defaultValues.txtClientType = data.client_type;
    // defaultValues.txtClientGST = data.gst_no;
    // defaultValues.txtClientPAN = data.pan_number;
    defaultValues.txtAddress = data.address;
    defaultValues.txtCity = data.city;
    defaultValues.intPincode = data.pincode;
    reset({ ...defaultValues });
  };

  const [clientStatusList, setClientStatusList] = React.useState([]);
  const [clientStatus, setClientStatus] = React.useState("");

  useEffect(() => {
    if (assetsRef.current === false) {
      const data = {
        intType: 2,
        intParentId: 1,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          // console.log({ "response": response.data.result.slice(2,4) })
          setClientStatusList(response.data.result);
          console.log(response.data.result, "client status")
          // console.log(clientStatusList);
          // return;
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        assetsRef.current = true;
      }
    }
  }, [])


  const childRef = useRef();

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Edit Client
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Client</Typography>
              <Typography color="text.primary">Edit</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="light_Purple"
              className="button_first"
              href="/client/add"
              startIcon={<AddIcon />}
            >
              Add Client
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} lg={12} md={12}>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    name="txtClientName"
                    type="text"
                    label="Client Name"
                    fullWidth
                    focused
                    {...register("txtClientName")}
                    error={errors.txtClientName ? true : false}
                    helperText={errors.txtClientName?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    name="txtEmail"
                    type="email"
                    label="Email ID"
                    fullWidth
                    focused
                    {...register("txtEmail")}
                    error={errors.txtEmail ? true : false}
                    helperText={errors.txtEmail?.message}
                  />
                </Grid>
              </Grid>
              <div className="empty-space-20"></div>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="number"
                    fullWidth
                    focused
                    label="Primary Mobile Number"
                    error={errors.intMobileNumber ? true : false}
                    {...register("intMobileNumber")}
                    helperText={errors.intMobileNumber?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="number"
                    fullWidth
                    focused
                    label="Secondary Mobile Number"
                    error={errors.intSecMobileNumber ? true : false}
                    {...register("intSecMobileNumber")}
                    helperText={errors.intSecMobileNumber?.message}
                  />
                </Grid>
              </Grid>
              {/* <div className="empty-space-20"></div>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="number"
                    fullWidth
                    focused
                    label="Vendor Code"
                    error={errors.txtVendorCode ? true : false}
                    {...register("txtVendorCode")}
                    helperText={errors.txtVendorCode?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="text"
                    fullWidth
                    focused
                    label="Client Type"
                    error={errors.txtClientType ? true : false}
                    {...register("txtClientType")}
                    helperText={errors.txtClientType?.message}
                  />
                </Grid>
              </Grid>
              <div className="empty-space-20"></div>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="text"
                    fullWidth
                    focused
                    label="Client GST No. "
                    error={errors.txtClientGST ? true : false}
                    {...register("txtClientGST")}
                    helperText={errors.txtClientGST?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    type="text"
                    fullWidth
                    focused
                    label="Client PAN Number"
                    error={errors.txtClientPAN ? true : false}
                    {...register("txtClientPAN")}
                    helperText={errors.txtClientPAN?.message}
                  />
                </Grid>
              </Grid> */}
              <div className="empty-space-20"></div>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    name="address"
                    type="text"
                    label="Street address"
                    fullWidth
                    focused
                    {...register("txtAddress")}
                    error={errors.txtAddress ? true : false}
                    helperText={errors.txtAddress?.message}
                  />
                </Grid>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    name="address"
                    type="text"
                    label="Town / City"
                    fullWidth
                    focused
                    {...register("txtCity")}
                    error={errors.txtCity ? true : false}
                    helperText={errors.txtCity?.message}
                  />
                </Grid>
              </Grid>
              <div className="empty-space-20"></div>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={stateName}
                      label="State"
                      focused
                      onChange={(e) => {
                        setStateName(e.target.value);
                        setValue("txtState", e.target.value);
                      }}
                    >
                      {stateList.map((row) => (
                        <MenuItem value={row.state_name}>
                          {row.state_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <TextField
                    name="address"
                    type="text"
                    label="Pincode"
                    fullWidth
                    focused
                    {...register("intPincode")}
                    error={errors.intPincode ? true : false}
                    helperText={errors.intPincode?.message}
                  />
                </Grid>
              </Grid>

              {/* Client status */}
              <div className="empty-space-20"></div>
              <Grid container spacing={2}>
                <Grid item className="form-lable" xs={12} lg={6} md={6}>
                  <FormControl
                    fullWidth
                    error={errors.intClientStatus ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">Client Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Client Status"
                      id="demo-simple-select"
                      {...register("intClientStatus")}
                      value={clientStatus}
                      onChange={(e) => {
                        setClientStatus(e.target.value);
                        setValue("intClientStatus", e.target.value);
                      }}
                    >
                      {clientStatusList.map((row) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="invalid-feedback">
                      {errors.intClientStatus?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>


              <Grid
                item
                className="form-lable"
                xs={12}
                lg={12}
                md={12}
                marginTop="20px"
                textAlign="right"
              >
                <Button className={`btn-primary`} type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Layout>
  );
}

export default ClientMasterEdit;
