import {
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import FlutterDashIcon from "@mui/icons-material/FlutterDash";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TaskIcon from "@mui/icons-material/Task";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import ToggleOffRoundedIcon from "@mui/icons-material/ToggleOffRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import Collapse from "@mui/material/Collapse";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import BadgeIcon from "@mui/icons-material/Badge";
import SettingsIcon from "@mui/icons-material/Settings";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SourceIcon from "@mui/icons-material/Source";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import PersonAddTwoToneIcon from "@mui/icons-material/PersonAddTwoTone";
import PersonSearchTwoToneIcon from "@mui/icons-material/PersonSearchTwoTone";
import PaymentsTwoToneIcon from "@mui/icons-material/PaymentsTwoTone";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import CoPresentTwoToneIcon from "@mui/icons-material/CoPresentTwoTone";
import NoteAddTwoToneIcon from "@mui/icons-material/NoteAddTwoTone";
import FindInPageTwoToneIcon from "@mui/icons-material/FindInPageTwoTone";

import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import SupervisorAccountTwoToneIcon from "@mui/icons-material/SupervisorAccountTwoTone";
import Groups3TwoToneIcon from "@mui/icons-material/Groups3TwoTone";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import DisplaySettingsTwoToneIcon from "@mui/icons-material/DisplaySettingsTwoTone";

import EventSeatTwoToneIcon from "@mui/icons-material/EventSeatTwoTone";
import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import AddCardTwoToneIcon from "@mui/icons-material/AddCardTwoTone";
import OfflinePinTwoToneIcon from '@mui/icons-material/OfflinePinTwoTone';
import SensorOccupiedTwoToneIcon from "@mui/icons-material/SensorOccupiedTwoTone";
import "../assets/css/style.css";

function Sidenav() {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const activeUrl = window.location.pathname;
  const [generalMenu, setGeneralMenu] = useState([]);
  const [MenuManagement, setmenuManagement] = useState([]);
  const [UserMenu, setuserMenu] = useState([]);
  const useRefApp = useRef(false);
  const userData = JSON.parse(localStorage.getItem("user"));
  const handleClick = (id) => {
    if (selectedIndex === id) {
      setSelectedIndex("");
    } else {
      setSelectedIndex(id);
    }
  };

  const findIndex = (arr, path) => {
    let foundIndex = -1;

    arr.forEach((item, index) => {
      if (item.url == path) {
        foundIndex = index;
      } else if (item.childMenu) {
        item.childMenu.forEach((childItem, childIndex) => {
          if (childItem.url == path) {
            foundIndex = index;
          }
        });
      }
    });

    return foundIndex + 1;
  };

  useEffect(() => {
    if (useRefApp.current === false) {
      setGeneralMenu([
        {
          title: "Dashboard",
          url: "/dashboard",
          icon: <DashboardOutlinedIcon />,
          childMenuFlag: false,
        },
      ]);
      setuserMenu([
        {
          title: "Attendance",
          url: "/user/attendance",
          icon: <SupervisedUserCircleIcon />,
          childMenuFlag: false,
        },
        {
          title: "Apply Leave",
          url: "/user/apply-leave",
          icon: <TaskIcon />,
          childMenuFlag: false,
        },
        // {
        //   title: "Payment Request",
        //   url: "/user/payment/request",
        //   // icon: <CurrencyRupeeIcon />,
        //   childMenuFlag: false,
        // },
        // {
        //   title: "Payment List",
        //   url: "/user/payment/list",
        //   // icon: <ReceiptIcon />,
        //   childMenuFlag: false,
        // },
        // {
        //   title: "Labour Attendance",
        //   url: "/user/labour-attend",
        //   icon: <TaskIcon />,
        //   childMenuFlag: false,
        // },
      ]);

      const variable = [
        {
          title: "HR Management",
          url: "#",
          icon: <BadgeIcon />,
          childMenuFlag: true,
          menuOpenFlag: false,
          childMenu: [
            {
              title: "Add Employee",
              url: "/employee/add",
              icon: <PersonAddTwoToneIcon />,
            },
            {
              title: "List Employees",
              url: "/employee/list",
              icon: <PersonSearchTwoToneIcon />,
            },
            {
              title: "Employee Attendance",
              url: "/employee-attendance",
              icon: <CoPresentTwoToneIcon />,
            },
            {
              title: "PaySlip",
              url: "/payslip",
              icon: <PaymentsTwoToneIcon />,
            },
            {
              title: "PaySlip Report",
              url: "/payslip-report",
              icon: <PaymentsTwoToneIcon />,
            },
            {
              title: "Leave Approval",
              url: "/Leave/approval",
              icon: <DriveFileRenameOutlineTwoToneIcon />,
            },
          ],
        },
        {
          title: "Masters",
          url: "#",
          icon: <SourceIcon />,
          childMenuFlag: true,
          menuOpenFlag: false,
          childMenu: [
            {
              title: "Add Project",
              url: "/project/add",
              icon: <NoteAddTwoToneIcon />,
            },
            {
              title: "List Projects",
              url: "/project/list",
              icon: <FindInPageTwoToneIcon />,
            },
            {
              title: "Add Client",
              url: "/client/add",
              icon: <SupervisorAccountTwoToneIcon />,
            },
            {
              title: "List Clients",
              url: "/client/list",
              icon: <AccountCircleTwoToneIcon />,
            },
            {
              title: "Team Leader",
              url: "/team/team-leader-master",
              icon: <Groups3TwoToneIcon />,
            },
            // { title: "List Vendors", url: "/vendor/list" },
            {
              title: "List Payment Request",
              url: "/payment-request/list",
              icon: <ReceiptTwoToneIcon />,
            },
          ],
        },
        {
          title: "Configurations",
          url: "#",
          icon: <SettingsIcon />,
          childMenuFlag: true,
          menuOpenFlag: false,
          childMenu: [
            {
              title: "Configuration",
              url: "/config/configuration",
              icon: <DisplaySettingsTwoToneIcon />,
            },
            {
              title: "Manage Departments",
              url: "/config/departments-master",
              icon: <ApartmentTwoToneIcon />,
            },
            {
              title: "Manage Positions",
              url: "/config/positions-master",
              icon: <EventSeatTwoToneIcon />,
            },
            {
              title: "Manage Allowances",
              url: "/manage-allowances",
              icon: <AddCardTwoToneIcon />,
            },
            {
              title: "Manage Holidays",
              url: "/add-holiday",
              icon: <OfflinePinTwoToneIcon />,
            },
            {
              title: "Manage Companies",
              url: "/manage-companies/add",
              icon: <BusinessTwoToneIcon />,
            },
            {
              title: "List Companies",
              url: "/manage-companies/list",
              icon: <BusinessTwoToneIcon />,
            },
            {
              title: "Manage Labour Wages",
              url: "/config/labour-wages",
              icon: <SensorOccupiedTwoToneIcon />,
            },
          ],
        },
        {
          title: "Reports",
          url: "#",
          icon: <LeaderboardIcon />,
          childMenuFlag: true,
          menuOpenFlag: false,
          childMenu: [
            {
              title: "Project Summary Report",
              url: "/reports/project-summary",
            },
            {
              title: "Project Timeline Report",
              url: "/reports/project-report",
            },
            {
              title: "Project Payment Report",
              url: "/reports/project-payment-report",
            },
            {
              title: "Employee Activity Report",
              url: "/reports/employee-activity-report",
            },
            {
              title: "Employee Payment Report",
              url: "/payment/list",
            },
            {
              title: "Employee Attendance Report",
              url: "/reports/employee-attendance-report",
            },
            {
              title: "Leave Report",
              url: "/reports/leave-report",
            },
            {
              title: "EOD Report",
              url: "/reports/eod-report",
            },
            {
              title: "Advance Payment Report",
              url: "/advance-payment/list",
            },
          ],
        },
      ];
      setmenuManagement(variable);

      const pathname = window.location.pathname;
      const idx = findIndex(variable, pathname);
      setSelectedIndex(idx);
    }
    return () => {
      useRefApp.current = true;
    };
  }, []);
  return (
    <>
      {userData.role_id === 35 || userData.role_id === 34 ? (
        <>
          <Grid>
            {generalMenu.map((row, id) =>
              row.childMenuFlag ? (
                <>
                  <ListItemButton
                    className="menu_id"
                    onClick={() => {
                      handleClick(id);
                    }}
                  >
                    <ListItemIcon>{row.icon}</ListItemIcon>
                    <ListItemText primary={row.title} />
                    {id === selectedIndex ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={id === selectedIndex}
                    timeout="auto"
                    unmountOnExit
                  >
                    {row.childMenu.map((submenu, idx) => (
                      <Link
                        to={submenu.url}
                        key={idx}
                        className={`sidemenu--item ${
                          activeUrl === "" + submenu.url ? `active` : ``
                        }`}
                      >
                        <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                          <ListItemText primary={submenu.title} />
                        </ListItemButton>
                      </Link>
                    ))}
                  </Collapse>
                </>
              ) : (
                <Link
                  to={row.url}
                  key={id}
                  className={`sidemenu--item ${
                    activeUrl === "" + row.url ? `active` : ``
                  }`}
                >
                  <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                    <ListItemIcon
                      sx={{ minWidth: 0, justifyContent: "center" }}
                    >
                      {row.icon}
                    </ListItemIcon>
                    <ListItemText primary={row.title} />
                  </ListItemButton>
                </Link>
              )
            )}
          </Grid>
          <Grid>
            {MenuManagement.map((row, id) =>
              row.childMenuFlag ? (
                <>
                  <ListItemButton
                    // className="menu_id"
                    className={`menu_id ${
                      selectedIndex - 1 == id ? "active" : ""
                    }`}
                    onClick={() => {
                      handleClick(id + generalMenu.length);
                    }}
                  >
                    <ListItemIcon>{row.icon}</ListItemIcon>
                    <ListItemText primary={row.title} />
                    {id + generalMenu.length == selectedIndex ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={id + generalMenu.length == selectedIndex}
                    timeout="auto"
                    unmountOnExit
                  >
                    {row.childMenu.map((submenu, idx) => (
                      <Link
                        to={submenu.url}
                        key={idx}
                        className={`sidemenu--item ${
                          activeUrl === submenu.url ? `active` : ``
                        }`}
                      >
                        <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                          <ListItemIcon
                            sx={{ minWidth: "auto", fontSize: "5px" }}
                          >
                            {submenu.icon}
                          </ListItemIcon>
                          <ListItemText primary={submenu.title} />
                        </ListItemButton>
                      </Link>
                    ))}
                  </Collapse>
                </>
              ) : (
                <Link
                  to={row.url}
                  key={id}
                  className={`sidemenu--item ${
                    activeUrl === "" + row.url ? `active` : ``
                  }`}
                >
                  <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                    <ListItemIcon
                      sx={{ minWidth: 0, justifyContent: "center" }}
                    >
                      {row.icon}
                    </ListItemIcon>
                    <ListItemText primary={row.title} />
                  </ListItemButton>
                </Link>
              )
            )}
          </Grid>
        </>
      ) : (
        <></>
      )}

      {userData.role_id === 36 ? (
        <Grid>
          {UserMenu.map((row, id) =>
            row.childMenuFlag ? (
              <>
                <ListItemButton
                  className="menu_id"
                  onClick={() => {
                    handleClick(id);
                  }}
                >
                  <ListItemIcon>{row.icon}</ListItemIcon>
                  <ListItemText primary={row.title} />
                  {/* Menu */}
                  {id === selectedIndex ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={id === selectedIndex}
                  timeout="auto"
                  unmountOnExit
                >
                  {row.childMenu.map((submenu, id) => (
                    <Link
                      to={submenu.url}
                      key={id}
                      className={`sidemenu--item ${
                        activeUrl === "" + submenu.url ? `active` : ``
                      }`}
                    >
                      <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                        <ListItemText primary={submenu.title} />
                      </ListItemButton>
                    </Link>
                  ))}
                </Collapse>
              </>
            ) : (
              <Link
                to={row.url}
                key={id}
                className={`sidemenu--item ${
                  activeUrl === "" + row.url ? `active` : ``
                }`}
              >
                <ListItemButton sx={{ minHeight: 48, px: 2.5 }}>
                  <ListItemIcon sx={{ minWidth: 0, justifyContent: "center" }}>
                    {row.icon}
                  </ListItemIcon>
                  <ListItemText primary={row.title} />
                </ListItemButton>
              </Link>
            )
          )}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
export default Sidenav;
