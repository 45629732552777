import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { employeeAttandenceAPI, config } from "../../config";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

function ProjectChart() {
  const [chartData, setChartData] = useState([]);
  const [options, setOptions] = useState({
    series: [],
    chart: {
      type: "bar",
      height: 700,
      stacked: true,
      stackType: "90%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    title: {
      text: "Employee Project Work Percentage",
    },
    xaxis: {
      categories: [],
      labels: {
        formatter: function (val) {
          return "";
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      let requestData = {
        intType: 25,
      };

      config.method = "POST";
      config.data = JSON.stringify(requestData);
      config.url = employeeAttandenceAPI;

      try {
        const response = await axios(config);
        const data = response.data.result;
        const data1 = response.data.result;

        const uniqueNames = [
          ...new Set(data.map((item) => item.employee_names)),
        ];

        let categories = [];
        let seriesData = [];

        const employeeData = {};

        const projectData = {};

        // Step 2: Populate the projectData object
        data1.forEach((item) => {
          const projectName = item.project_name;
          const workPercentage = parseFloat(item.work_percentage);

          if (!projectData[projectName]) {
            projectData[projectName] = [];
          }

          projectData[projectName].push(workPercentage);
        });

        // Step 3: Find the unique employee names
        const employeeNames = Array.from(
          new Set(data.map((item) => item.employee_names))
        );

        // Step 4: Convert projectData into the desired format
        const outputData = Object.keys(projectData).map((projectName) => ({
          name: projectName,
          data: employeeNames.map((employeeName) => {
            const index = data.findIndex(
              (item) =>
                item.employee_names === employeeName &&
                item.project_name === projectName
            );
            if (index !== -1) {
              const workPercentage =
                parseFloat(data[index].work_percentage).toFixed(2) + "%";

              return workPercentage;
            } else {
              return "0%";
            }
          }),
        }));

        console.clear();
        // console.log(data1);
        // console.log(outputData);
        // console.log(uniqueNames);

        setOptions({
          ...options,
          xaxis: {
            categories: uniqueNames,
          },
        });

        setChartData(outputData);

        // )setChartData(seriesData;
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Card>
      <CardContent style={{ height: "600px" }}>
        <ReactApexChart
          options={options}
          series={chartData}
          type="bar"
          height={600}
        />
      </CardContent>
    </Card>
  );
}

export default ProjectChart;
