import React, { useEffect, useRef } from "react";

//design imports from material
import {
  Grid,
  FormLabel,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  Button,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";

//layout import
import Layout from "../../layout/Layout";

//form imports
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

//date picker
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import axios from "axios";
import {
  clientMasterAPI,
  config,
  convertBase64,
  projectMasterAPI,
  _numberOnly,
  statusMasterAPI,
  manageCompaniesAPI,
} from "../../config";
import { AlertMessage } from "../../components/AlertMessage";

import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { _getDropdownListData } from "../../service";
import StateList, { stateList } from "../../components/StateList";

let defaultValues = {};
function ProjectMasterEdit() {
  const { projectid } = useParams();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    // txtProjectName: Yup.string().required("Project Name is required"),
    // // txtLocation: Yup.string().required("Project Location is required"),
    // txtTypeOfProject: Yup.string().required("Type of Project is required"),
    // txtTypeOfActivity: Yup.string().required("Type of Activity is required"),
    // txtProjectAddress: Yup.string().required("Project Address is required"),
    // // txtClientName: Yup.string().required("Client / Owner Name is required"),
    // txtClientGst: Yup.string().required("Client GST No is required"),
    // txtAgreementNo: Yup.string().required("Agreement No is required"),
    // txtStartDate: Yup.string().required("Start Date is required"),
    // txtEndDate: Yup.string().required("End Date is required"),
    // txtProjectValue: Yup.string().required("Project Value is required"),
    // txtPaymentStages: Yup.string().required("Payment Stages is required"),
    // txtProjectName: Yup.string().required("Project Name is required"),
    // txtTypeOfProject: Yup.string().required("Type of Project is required"),
    // txtTypeOfActivity: Yup.string().required("Type of Activity is required"),
    // txtProjectAddress: Yup.string().required("Project Address is required"),
    // txtAgreementNo: Yup.string().required("Agreement No is required"),
    // txtProjectValue: Yup.string().required("Project Value is required"),
    // intPaymentStages: Yup.string().required("Payment Stages is required"),
    // txtCity: Yup.string().required("City is required"),
    // txtState: Yup.string().required("State is required"),
    // intPincode: Yup.string().required("Pin code is required"),
  });

  const [txtState, setStateName] = useState("");
  const [txtTypeOfProject, setTypeOfProject] = useState("");
  const [txtTypeOfActivity, setTypeOfActivity] = useState("");

  //Set Client Name
  const [ClientName, setClientName] = useState("");
  const [clientList, setClientList] = useState([]);

  const [projectStatus, setProjectStatus] = useState("");
  const [projectStatusList, setProjectStatusList] = useState([]);

  const [companyName, setCompanyName] = useState("");
  const [lstCompany, setCompanyList] = useState([]);

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const [txtStartDate, setStartDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(dayjs().format("YYYY-MM-DD"));

  let tempRefs = useRef(false);

  useEffect(() => {
    if (tempRefs.current === false) {
      let data = {
        intType: 5,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = manageCompaniesAPI;

      axios(config)
        .then(function (response) {
          setCompanyList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });

      data = {
        intType: 2,
        intParentId: 114,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = statusMasterAPI;

      axios(config)
        .then(function (response) {
          // console.log({ "response": response.data.result.slice(1,5) })
          setProjectStatusList(response.data.result);
          console.log(projectStatusList);
          // return;
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  let tempRef = useRef(false);
  useEffect(() => {
    if (tempRef.current === false) {
      const data = {
        intType: 5,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = clientMasterAPI;

      axios(config)
        .then(function (response) {
          setClientList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef.current = true;
      };
    }
  }, []);

  // image Conver to base64
  const [basedrawing, setBaseDrawing] = useState("");
  const [basemeasurement, setBaseMeasurement] = useState("");
  const [baseuploadagreement, setBaseUploadAgreement] = useState("");

  const uploaddrawing = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseDrawing(base64);
  };
  const uploadmeasurement = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseMeasurement(base64);
  };
  const uploadagreement = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseUploadAgreement(base64);
  };

  function onSubmit(data) {
    // console.log("its here");
    data.txtProjectDrawing = basedrawing;
    data.txtMeasurementSheet = basemeasurement;
    data.txtAgreement = baseuploadagreement;
    data.intType = 5;
    data.intStatus = projectStatus;
    data.intProjectID = projectid;

    console.log({ data: data });
    // return;

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = projectMasterAPI;

    axios(config)
      .then(function (response) {
        //handleFormReset();
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setTimeout(function () {
          navigate(`/project/list`);
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let assetsRef = useRef(false);
  useEffect(() => {
    if (assetsRef.current === false) {
      const data = JSON.stringify({
        intProjectID: projectid,
        intType: 2,
      });

      config.method = "POST";
      config.data = data;
      config.url = projectMasterAPI;

      axios(config)
        .then(function (response) {
          // console.clear();
          console.log(
            "⛔ ➡️ file: ProjectMasterEdit.jsx:235 ➡️ response:",
            response.data.result[0]
          );
          handleFormReset(response.data.result[0]);
        })
        .catch(function (error) {
          console.log(error);
        });

      return () => {
        assetsRef.current = true;
      };
    }
  }, []);

  const [project, setProject] = useState({
    agreement: "",
    measurement_sheet: "",
    project_drawing: "",
  });

  const handleFormReset = (data) => {
    console.log({ data: data });
    setClientName(parseInt(data.client_name));
    setCompanyName(parseInt(data.company_name));
    setStateName(data.state);
    setProjectStatus(parseInt(data.status));

    setTypeOfProject(data.type_of_project);
    setTypeOfActivity(data.type_of_activity);
    defaultValues.txtTypeOfProject = data.type_of_project;
    defaultValues.txtTypeOfActivity = data.type_of_activity;

    defaultValues.txtEndDate = data.project_end_date;
    defaultValues.txtStartDate = data.project_start_date;
    defaultValues.txtProjectName = data.name;
    defaultValues.txtProjectAddress = data.project_address;
    defaultValues.txtClientGst = data.client_gst;
    defaultValues.txtAgreementNo = data.agreement_no;
    defaultValues.txtProjectValue = data.project_value;
    defaultValues.intPaymentStages = data.payment_stage;
    defaultValues.txtCity = data.city;
    defaultValues.txtState = data.state;
    defaultValues.intPincode = data.pincode;

    setBaseDrawing(data?.agreement);
    setBaseMeasurement(data?.measurement_sheet);
    setBaseUploadAgreement(data?.project_drawing);

    setProject({
      agreement: data?.agreement,
      measurement_sheet: data?.measurement_sheet,
      project_drawing: data?.project_drawing,
    });

    reset({ ...defaultValues });
  };

  const childRef = useRef();

  const [projectTypeOfActivityList, setProjectTypeOfActivityList] = useState(
    []
  );
  useEffect(() => {
    let data = {
      requestData: {
        intType: 2,
        intParentId: 124,
      },
      API: statusMasterAPI,
      setData: setProjectTypeOfActivityList,
    };

    _getDropdownListData(data);
  }, []);

  const [projectTypeList, setProjectTypeList] = useState([]);
  useEffect(() => {
    let data = {
      requestData: {
        intType: 2,
        intParentId: 120,
      },
      API: statusMasterAPI,
      setData: setProjectTypeList,
    };

    _getDropdownListData(data);
  }, []);

  const [isCancelProjectDrawingPopup, setIsCancelProjectDrawingPopup] =
    useState(false);
  const [isCancelMeasurementPopup, setIsCancelMeasurementPopup] =
    useState(false);
  const [isCancelPopup, setIsCancelPopup] = useState(false);

  const handleCancelProjectDrawingFile = async () => {
    setBaseDrawing("");
    document.getElementById("projectdrawing").value = "";
    setIsCancelProjectDrawingPopup(false);
  };

  const handleCancelMeasurementFile = async () => {
    setBaseMeasurement("");
    document.getElementById("measurementsheet").value = "";
    setIsCancelMeasurementPopup(false);
  };

  const handleCancelFile = async () => {
    setBaseUploadAgreement("");
    document.getElementById("agreementdocument").value = "";
    setIsCancelPopup(false);
  };

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Project Edit
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Typography color="text.primary">Project</Typography>
              <Typography color="text.primary">Edit</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="light_Purple"
              className="button_first"
              href="/project/add"
              startIcon={<AddIcon />}
            >
              Add Project
            </LoadingButton>
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>
        <form className="form_design" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="txtName"
                type="text"
                label="Project Name"
                fullWidth
                {...register("txtProjectName")}
                error={errors.txtProjectName ? true : false}
                helperText={errors.txtProjectName?.message}
                focused
              />
            </Grid>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <FormControl
                fullWidth
                error={errors.txtCompanyName ? true : false}
              >
                <InputLabel id="demo-simple-select-label">Company</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Company"
                  id="demo-simple-select"
                  {...register("txtCompanyName")}
                  focused
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                    setValue("txtCompanyName", e.target.value);
                  }}
                >
                  {lstCompany.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.company_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="invalid-feedback">
                  {errors.txtCompanyName?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <div className="empty-space-20"></div>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="txtProjectValue"
                type="text"
                label="Project Value"
                fullWidth
                onKeyPress={_numberOnly}
                {...register("txtProjectValue")}
                error={errors.txtProjectValue ? true : false}
                helperText={errors.txtProjectValue?.message}
                focused
              />
            </Grid>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <FormControl
                fullWidth
                error={errors.txtClientName ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Client Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Client Name"
                  id="demo-simple-select"
                  {...register("txtClientName")}
                  value={ClientName}
                  onChange={(e) => {
                    setClientName(e.target.value);
                    setValue("txtClientName", e.target.value);
                  }}
                >
                  {clientList.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.client_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="invalid-feedback">
                  {errors.txtClientName?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <div className="empty-space-20"></div>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="intPaymentStages"
                type="text"
                label="Payment Stages"
                fullWidth
                onKeyPress={_numberOnly}
                {...register("intPaymentStages")}
                error={errors.intPaymentStages ? true : false}
                helperText={errors.intPaymentStages?.message}
                focused
              />
            </Grid>

            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="txtAgreementNo"
                type="text"
                label="Agreement / PO Number"
                fullWidth
                {...register("txtAgreementNo")}
                error={errors.txtAgreementNo ? true : false}
                helperText={errors.txtAgreementNo?.message}
                focused
              />
            </Grid>
          </Grid>

          <div className="empty-space-20"></div>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                format="DD-MM-YYYY"
              >
                <MobileDatePicker
                  label="Start Date"
                  value={defaultValues.txtStartDate}
                  inputFormat="DD/MM/YYYY"
                  fullWidth
                  onChange={(newValue) => {
                    setStartDate(newValue);
                    setValue(
                      "txtStartDate",
                      dayjs(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={errors.txtStartDate?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <FormControl
                fullWidth
                error={errors.txtTypeOfProject ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Project Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Project Type"
                  id="demo-simple-select"
                  value={txtTypeOfProject}
                  focused
                  onChange={(e) => {
                    setTypeOfProject(e.target.value);
                    setValue("txtTypeOfProject", e.target.value);
                  }}
                >
                  {projectTypeList.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="invalid-feedback">
                  {errors.txtTypeOfProject?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <div className="empty-space-20"></div>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <FormControl
                fullWidth
                error={errors.txtTypeOfActivity ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Type of Activites
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Type of Activites"
                  id="demo-simple-select"
                  value={txtTypeOfActivity}
                  focused
                  onChange={(e) => {
                    setTypeOfActivity(e.target.value);
                    setValue("txtTypeOfActivity", e.target.value);
                  }}
                >
                  {projectTypeOfActivityList.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="invalid-feedback">
                  {errors.txtTypeOfActivity?.message}
                </FormHelperText>
              </FormControl>
            </Grid>

            {/* <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="address"
                type="text"
                label="Project Address"
                fullWidth
                {...register("txtProjectAddress")}
                error={errors.txtProjectAddress ? true : false}
                helperText={errors.txtProjectAddress?.message}
                focused
              />
            </Grid> */}

            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <FormControl
                fullWidth
                error={errors.intProjectStatus ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Project Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Project Status"
                  id="demo-simple-select"
                  {...register("intProjectStatus")}
                  value={projectStatus}
                  onChange={(e) => {
                    setProjectStatus(e.target.value);
                    setValue("intProjectStatus", e.target.value);
                  }}
                >
                  {projectStatusList.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="invalid-feedback">
                  {errors.intProjectStatus?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          {/* <div className="empty-space-20"></div>
          <Grid container spacing={2}>

            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="address"
                type="text"
                label="Town / City"
                fullWidth
                {...register("txtCity")}
                error={errors.txtCity ? true : false}
                helperText={errors.txtCity?.message}
                focused
              />
            </Grid>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <FormControl fullWidth error={errors.txtState ? true : false}>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={txtState}
                  label="State"
                  onChange={(e) => {
                    setStateName(e.target.value);
                    setValue("txtState", e.target.value);
                  }}
                >
                  {stateList.map((row) => (
                    <MenuItem value={row.state_name}>{row.state_name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText className="invalid-feedback">
                  {errors.txtState?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid> */}

          <div className="empty-space-20"></div>
          <Grid container spacing={2}>
            {/* <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="address"
                type="text"
                label="Pincode"
                fullWidth
                className="numberonly"
                onKeyPress={_numberOnly}
                {...register("intPincode")}
                error={errors.intPincode ? true : false}
                helperText={errors.intPincode?.message}
                focused
              />
            </Grid> */}
          </Grid>

          <div className="empty-space-20"></div>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={12} md={12}>
              <Typography className={`sub-title-text`}>
                Upload Document
              </Typography>
            </Grid>
            <Grid item className="form-lable" xs={12} lg={4} md={4}>
              <label>Project Drawing : </label>
              <br></br>
              <Box display={`flex`}>
                <Button variant="contained" component="label">
                  <input
                    id="projectdrawing"
                    name="projectdrawing"
                    type="file"
                    accept=".doc,.docx,application/pdf"
                    fullWidth
                    onChange={(e) => {
                      uploaddrawing(e);
                    }}
                  />
                </Button>
                {basedrawing != "" && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setIsCancelProjectDrawingPopup(true)}
                    style={{ marginLeft: "10px" }}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid item className="form-lable" xs={12} lg={4} md={4}>
              <label>Measurement Sheet : </label>
              <br></br>
              <Box display={`flex`}>
                <Button variant="contained" component="label">
                  <input
                    id="measurementsheet"
                    name="measurementsheet"
                    type="file"
                    accept=".doc,.docx,application/pdf"
                    fullWidth
                    onChange={(e) => {
                      uploadmeasurement(e);
                    }}
                  />
                </Button>
                {basemeasurement != "" && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setIsCancelMeasurementPopup(true)}
                    style={{ marginLeft: "10px" }}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid item className="form-lable" xs={12} lg={4} md={4}>
              <label>Agreement / PO Document : </label>
              <br></br>
              <Box display={`flex`}>
                <Button variant="contained" component="label">
                  <input
                    id="agreementdocument"
                    name="agreementdocument"
                    type="file"
                    accept=".doc,.docx,application/pdf"
                    fullWidth
                    onChange={(e) => {
                      uploadagreement(e);
                    }}
                  />
                </Button>

                {baseuploadagreement != "" && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setIsCancelPopup(true)}
                    style={{ marginLeft: "10px" }}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            className="form-lable"
            xs={12}
            lg={12}
            md={12}
            marginTop="20px"
            textAlign="right"
          >
            <Button className={`btn-primary`} type="submit">
              Submit
            </Button>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
            {basedrawing != "" && (
              <Grid item className="form-lable" xs={12} sm={12} md={6}>
                <Typography variant="h6">Project drawing</Typography>

                {basedrawing.endsWith(".pdf") ? (
                  <iframe
                    src={basedrawing}
                    title="document"
                    width="100%"
                    height="600"
                  ></iframe>
                ) : basedrawing.startsWith("data:application/pdf;base64,") ? (
                  <iframe
                    src={`data:application/pdf;base64,${
                      basedrawing.split(",")[1]
                    }`}
                    title="document"
                    width="100%"
                    height="600"
                  ></iframe>
                ) : (
                  <img
                    src={basedrawing}
                    alt="document"
                    style={{ maxWidth: "100%" }}
                  />
                )}
              </Grid>
            )}

            {basemeasurement != "" && (
              <Grid item className="form-lable" xs={12} sm={12} md={6}>
                <Typography variant="h6">Measurement sheet</Typography>

                {basemeasurement.endsWith(".pdf") ? (
                  <iframe
                    src={basemeasurement}
                    title="document"
                    width="100%"
                    height="600"
                  ></iframe>
                ) : basemeasurement.startsWith(
                    "data:application/pdf;base64,"
                  ) ? (
                  <iframe
                    src={`data:application/pdf;base64,${
                      basemeasurement.split(",")[1]
                    }`}
                    title="document"
                    width="100%"
                    height="600"
                  ></iframe>
                ) : (
                  <img
                    src={basemeasurement}
                    alt="document"
                    style={{ maxWidth: "100%" }}
                  />
                )}
              </Grid>
            )}

            {baseuploadagreement != "" && (
              <Grid item className="form-lable" xs={12} sm={12} md={6}>
                <Typography variant="h6">Agreement / PO Document</Typography>

                {baseuploadagreement.endsWith(".pdf") ? (
                  <iframe
                    src={baseuploadagreement}
                    title="document"
                    width="100%"
                    height="600"
                  ></iframe>
                ) : baseuploadagreement.startsWith(
                    "data:application/pdf;base64,"
                  ) ? (
                  <iframe
                    src={`data:application/pdf;base64,${
                      baseuploadagreement.split(",")[1]
                    }`}
                    title="document"
                    width="100%"
                    height="600"
                  ></iframe>
                ) : (
                  <img
                    src={baseuploadagreement}
                    alt="document"
                    style={{ maxWidth: "100%" }}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>

      {/* project drawing */}
      {/* <Dialog
        open={isCancelProjectDrawingPopup}
        onClose={() => setIsCancelProjectDrawingPopup(false)}
        maxWidth="xl"
      >
        <DialogTitle sx={{ backgroundColor: "#FFF2D7" }}>
          <Typography
            variant="h5"
            style={{
              color: "#FF6868",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Warning!!
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "16px" }}>
          <Typography variant="h6">
            Would you like to remove the Project drawing?
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#f44336", fontWeight: "bold" }}
          >
            **This step cannot be undone
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", padding: "16px" }}>
          <Button color="success" onClick={handleCancelProjectDrawingFile}>
            Yes
          </Button>
          <Button
            color="error"
            onClick={() => setIsCancelProjectDrawingPopup(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* measurement */}
      {/* <Dialog
        open={isCancelMeasurementPopup}
        onClose={() => setIsCancelMeasurementPopup(false)}
        maxWidth="xl"
      >
        <DialogTitle sx={{ backgroundColor: "#FFF2D7" }}>
          <Typography
            variant="h5"
            style={{
              color: "#FF6868",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Warning!!
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "16px" }}>
          <Typography variant="h6">
            Would you like to remove the Measurement sheet?
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#f44336", fontWeight: "bold" }}
          >
            **This step cannot be undone
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", padding: "16px" }}>
          <Button color="success" onClick={handleCancelMeasurementFile}>
            Yes
          </Button>
          <Button
            color="error"
            onClick={() => setIsCancelMeasurementPopup(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* agreement po doc */}
      <Dialog
        open={isCancelPopup}
        onClose={() => setIsCancelPopup(false)}
        maxWidth="xl"
      >
        <DialogTitle sx={{ backgroundColor: "#FFF2D7" }}>
          <Typography
            variant="h5"
            style={{
              color: "#FF6868",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Warning!!
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "16px" }}>
          <Typography variant="h6">
            Would you like to remove the project attachment?
          </Typography>
          <Typography
            variant="body2"
            style={{ color: "#f44336", fontWeight: "bold" }}
          >
            **This step cannot be undone
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", padding: "16px" }}>
          <Button color="success" onClick={handleCancelFile}>
            Yes
          </Button>
          <Button color="error" onClick={() => setIsCancelPopup(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

export default ProjectMasterEdit;
