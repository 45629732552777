import axios from "axios";
import { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import { projectMasterAPI, config, dashboardAPI } from "../../config";

function ProjectPaymentChart(props) {
  const [txtType, setType] = useState(props.type);

  const [projectNameList, setProjectNameList] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [options, setOptions] = useState({});
  const [totalAmount, setTotalAmount] = useState({});
  const [totalSpent, setTotalSpent] = useState({});
  const [totalReceived, setTotalReceived] = useState({});

  const fetchData = async (id) => {
    let requestData = {
      intType: 6,
      intProjectID: id ? id : 0,
      txtFromDate: props.fromDate,
      txtToDate: props.toDate,
      intID: props.companyID,
    };

    config.method = "POST";
    config.data = JSON.stringify(requestData);
    config.url = dashboardAPI;
    await axios(config)
      .then(function (response) {
        const dataPoints = response.data.result;

        setPaymentData(dataPoints);

        const label = dataPoints.map((dataPoint) =>
          dataPoint.project_name ? dataPoint.project_name : ""
        );

        const totalAmount = dataPoints.map((dataPoint) =>
          dataPoint.project_value ? parseFloat(dataPoint.project_value) : 0
        );
        const totalSpent = dataPoints.map((dataPoint) =>
          dataPoint.amount_spent ? parseFloat(dataPoint.amount_spent) : 0
        );
        setTotalAmount(totalAmount);
        setTotalSpent(totalSpent);
        // setTotalReceived({});
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "INR",
        });
        const options = {
          series: [
            {
              name: "Total Spend",
              data: totalSpent,
              dataLabels: { enabled: false },
            },
            {
              name: "Project Value",
              data: totalAmount,
              dataLabels: { enabled: false },
            },
          ],
          chart: {
            type: "bar", // Use "bar" for a vertical stacked column chart
            height: 1000,
            stacked: true, // Enable stacking
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          xaxis: {
            categories: label,
            labels: {
              show: false, // Hide x-axis labels
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 5,
              barWidth: 40,
            },
          },
          legend: {
            position: "right",
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return (
                '<div class="custom-tooltip">' +
                "<span>Project Name</span>: " +
                label[dataPointIndex] +
                "<span><br>" +
                w.globals.seriesNames[seriesIndex] +
                "</span>: " +
                formatter.format(series[seriesIndex][dataPointIndex]) +
                "</div>"
              );
            },
          },
        };

        setOptions(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let tempRefs = useRef(false);

  useEffect(() => {
    fetchData();

    setType(props.type);
    if (tempRefs.current === false) {
      const data = {
        intType: 6,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = projectMasterAPI;

      axios(config)
        .then(function (response) {
          setProjectNameList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, [props]);

  return (
    <Grid item xs={12}>
      <ReactApexChart
        options={options}
        series={[
          {
            name: "Total Spend",
            data: totalSpent,
          },
          {
            name: "Project Value",
            data: totalAmount,
          },

          // {
          //   name: "Total Received",
          //   data: totalReceived,
          // },
        ]}
        type="bar"
        height={500}
      />
    </Grid>
  );
}

export default ProjectPaymentChart;
