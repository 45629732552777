import {
  Grid,
  FormLabel,
  TextField,
  FormControl,
  Typography,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { config, holidaydetailsAPI } from "../config";
import axios from "axios";
import React, { useEffect, useRef, useState, forwardRef, lazy } from "react";
import { AlertMessage } from "../components/AlertMessage";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useImperativeHandle } from "react";

export const AddHoliday = forwardRef(({ ...props }, ref) => {
  // console.log(data)
  const validationSchema = Yup.object().shape({});
  const formOptions = { resolver: yupResolver(validationSchema) };

  const [Date, setDate] = React.useState(dayjs()); //.format("YYYY-MM-DD"));
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const childRef = useRef();

  const [holidayReason, setHolidayReason] = useState("");
  const [holidayId, setHolidayId] = useState(null);
  const [update, setUpdate] = useState("");

  useImperativeHandle(ref, () => ({
    setData(data) {
      const tempData = JSON.parse(data);
      console.log(tempData);
      setHolidayId(tempData.intHolidayId);
      setDate(tempData.txtHolidayDate);
      setHolidayReason(tempData.txtHolidayReason);
      setUpdate(tempData.update);
    },
  }));
  console.log(Date);

  // Login
  const [HolidayDate, setHolidayDate] = useState([]);

  function onSubmit(data) {
    console.log(data);
    // setLodingSubmit(true);
    if (!holidayReason) {
      childRef.current.triggerAlert(
        JSON.stringify({
          message: "Holiday Reason Can't be empty",
          messageType: "error",
        })
      );
      return false;
    }

    let data1 = {};
    if (update == "yes") {
      data1 = JSON.stringify({
        txtHolidayDate: Date,
        txtHolidayReason: holidayReason,
        intHolidayId: holidayId,
        intType: 4,
        intOffset: 0,
        intLimit: 0,
      });
    } else {
      data1 = JSON.stringify({
        txtHolidayDate: Date,
        txtHolidayReason: holidayReason,
        intHolidayId: holidayId,
        intType: 1,
        intOffset: 0,
        intLimit: 0,
      });
    }

    config.method = "POST";
    config.data = data1;
    config.url = holidaydetailsAPI;

    axios(config)
      .then(function (response) {
        setHolidayDate(response.data.result);
        window.location.reload(false);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );

        setDate(dayjs());
        setHolidayReason("");
        props.fetchData(1);
      })
      .catch(function (error) {
        console.log(error);
        childRef.current.triggerAlert(
          JSON.stringify({
            message: "Something went wrong",
            messageType: "error",
          })
        );
      });
  }

  return (
    <Grid className="employee-form">
      <AlertMessage ref={childRef} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Grid container spacing={2}>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                format="DD-MM-YYYY"
              >
                <MobileDatePicker
                  label="Holiday Date"
                  value={Date}
                  inputFormat="DD/MM/YYYY"
                  fullWidth
                  focused
                  // {...register("txtHolidayDate")}
                  onChange={(newValue) => {
                    setDate(newValue);
                    setValue(
                      "txtHolidayDate",
                      dayjs(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={errors.txtHolidayDate?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item className="form-lable" xs={12} lg={6} md={6}>
              <TextField
                name="reason"
                type="text"
                label="Reason"
                value={holidayReason}
                fullWidth
                onChange={(e) => {
                  setHolidayReason(e.target.value);
                }}
                error={errors.txtHolidayReason ? true : false}
                helperText={errors.txtHolidayReason?.message}
              />
            </Grid>
          </Grid>
          <FormControl
            sx={{ m: 0, width: "-webkit-fill-available" }}
            variant="standard"
          >
            <Grid container className="submit-button" alignItems="center">
              <Grid item xs={12} lg={12} md={12}>
                <LoadingButton
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={formState.isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </form>
    </Grid>
  );
});
