import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Button,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import Layout from "../../layout/Layout";
import {
  config,
  createPdfAPI,
  getPdfAPI,
  invoiceItemsAPI,
  manageInvoiceAPI,
  manageInvoiceTermsAndConditionsAPI,
  positionMasterAPI,
  termsAndConditionsAPI,
} from "../../config";
import Pdf from "react-to-pdf";
import companyLogo from "./../../assets/images/Logo/logo.png";
import kgFurnTechLogo from "./../../assets/images/Logo/Kg_furn_logo.jpg";
import kgConstructionLogo from "./../../assets/images/Logo/kg_const_logo.jpg";
import kgTradeTechLogo from "./../../assets/images/Logo/kg_trade_logo.png";
import axios from "axios";
// import NotFound from "../../../components/NotFount";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useParams, useNavigate } from "react-router-dom";
// import { saveAs } from "file-saver";

import numberToWords from "number-to-words";
import { ToWords } from 'to-words';

import moment from 'moment';
import 'moment/locale/en-in';

const toWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: { // can be used to override defaults for the selected locale
      name: 'Rupee',
      plural: 'Rupees',
      symbol: '₹',
      fractionalUnit: {
        name: 'Paisa',
        plural: 'Paise',
        symbol: '',
      },
    }
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const twoDecimal = (x) => {
  return Number.parseFloat(x).toFixed(2);
};

function InvoiceView() {
  const { poid } = useParams();
  // console.log(poid)

  const [poDetails, setPODetails] = useState([]);
  const [paymentList, setPaymentList] = useState("");

  const [termsName, setTermsName] = useState("");
  const [terms, setTermsandConditions] = useState("");
  const [termsNames, setTermsNames] = useState([]);
  let tempRefs = useRef(false);

  useEffect(() => {
    if (tempRefs.current === false) {
      const data = {
        intType: 4,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = manageInvoiceTermsAndConditionsAPI;

      axios(config)
        .then(function (response) {
          setTermsNames(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs.current = true;
      };
    }
  }, []);

  const handleChangeterm = (event) => {
    setTermsName(event.target.value);

    const data = {
      intType: 3,
      intID: event.target.value,
      intLimit: 1,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = termsAndConditionsAPI;

    axios(config)
      .then(function (response) {
        setTermsandConditions(response.data.result[0]);
        window.tinymce.init({
          selector: "#textarea",
          width: "100%",
          menubar: false,
          plugins:
            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
          toolbar:
            "undo redo | fontsize | bold italic underline strikethrough | align lineheight | numlist bullist indent outdent",
        });
        setTimeout(function () {
          window.tinymce
            .get("textarea")
            .setContent(response.data.result[0].description);
        }, 1150);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let tempRef = useRef(false);
  useEffect(() => {
    if (tempRef.current === false) {
      const data = {
        intType: 2,
        intInvoiceId: poid,
        intLimit: 1,
        intOffset: 0,
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = manageInvoiceAPI;

      axios(config)
        .then(function (response) {
          // console.clear();
          console.log({ poDetails: response.data.result[0] });
          console.log(response.data.result);
          // return;

          setPODetails(response.data.result[0]);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef.current = true;
      };
    }
  }, []);

  // need to change in this useeffect
  useEffect(() => {
    if (tempRef.current === false) {
      const data = {
        intIONo: poid,
        intType: 2,
        intLimit: 1,
        intOffset: 0,
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = invoiceItemsAPI;

      axios(config)
        .then(function (response) {
          console.log({ items: response.data.result });
          // return;
          setPaymentList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef.current = true;
      };
    }
  }, []);

  const ref = React.createRef();
  const setPOStatus = (status) => {
    const data = {
      intType: 2,
      intStatus: status,
      intInvoiceId: poid,
    };
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = manageInvoiceAPI;

    axios(config)
      .then(function (response) { })
      .catch(function (error) {
        console.log(error);
      });
    return () => {
      tempRef.current = true;
    };
  };

  // const amountInWords = (amount = 1627997.22) => {
  //   let integer = Math.floor(amount);
  //   let fraction = Math.round((amount % 1) * 100);

  //   let integerInWords = numberToWords.toWords(integer);
  //   let fractionalInWords = numberToWords.toWords(fraction);

  //   let intWords = integerInWords.split(" ");
  //   for (let i = 0; i < intWords.length; i++) {
  //     intWords[i] =
  //       intWords[i][0].toUpperCase() + intWords[i].substring(1).toLowerCase();
  //   }
  //   integerInWords = intWords.join(" ");

  //   let fracWords = fractionalInWords.split(" ");
  //   for (let i = 0; i < fracWords.length; i++) {
  //     fracWords[i] =
  //       fracWords[i][0].toUpperCase() + fracWords[i].substring(1).toLowerCase();
  //   }
  //   fractionalInWords = fracWords.join(" ");

  //   return `${integerInWords} Rupees and ${fractionalInWords} Paise only`;

  // };

  const twoDecimal = (x) => {
    return Number.parseFloat(x).toFixed(2);
  };

  const splitAddressLine = (address) => {
    console.log(address.split(', '))
    const parts = address.split(', ');
    if (parts.length >= 2) {
      console.log(parts[0] + '\n' + parts[1] + '\n' + parts[2])
      return parts[0] + '\n' + parts[1] + '\n' + parts[2];
    }
    return 'Plot No 108, Perumal Koil Street, Alapakkam';
  }


  return (
    <Layout>
      <Grid className="login-text-background list_advance">
        <Typography variant="h5" textAlign="center">
          Invoice View
        </Typography>
      </Grid>
      <Grid container spacing={2} className={`bg-white`}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid className="element-to-po" ref={ref}>
            <Grid className="page">
              <Typography textAlign="" className={``} style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <h1 style={{ color: '#0066cc' }}>
                  {poDetails.invoice_type == "1"
                    ? "INVOICE"
                    : poDetails.invoice_type == "2"
                      ? "PROFORMA INVOICE"
                      : "CREDIT NOTE"}
                </h1>
              </Typography>
              <table className="tablepad">
                <tr className="no-border">
                  <td colSpan={4}>
                    <tr>
                      <td className="no-border">

                        <Typography textAlign="left" className={`td text-bold`}>
                          Invoice Ref No.: {poDetails.invoice_no}
                        </Typography>
                      </td>
                    </tr>
                    <hr />
                    <tr className="no-border">
                      {/* kgFurnTechLogo */}
                      <td className="no-border imgtd">
                        <img
                          src={
                            poDetails.company_name == "K.G BUILD TECH"
                              ? companyLogo
                              : poDetails.company_name == "K.G CONSTRUCTION"
                                ? kgConstructionLogo
                                : poDetails.company_name == "KG FURNTECH"
                                  ? kgFurnTechLogo
                                  : kgTradeTechLogo
                          }
                          alt="KG"
                          width={`200px`}
                          height={`auto`}
                        />
                      </td>
                      <td width={`100%`} className="no-border">
                        <table>
                          <tr>
                            <td className="no-border">
                              {/* className={`td text-bold`} */}
                              <Typography textAlign="right">
                                <b>OFFICE ADDRESS</b>
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails.company_name}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? <div>
                                    <Typography
                                      textAlign="right"
                                      className={`td text-bold`}
                                    >{poDetails.address?.split(', ')[0]}
                                    </Typography>
                                    <Typography
                                      textAlign="right"
                                      className={`td text-bold`}
                                    >{poDetails.address?.split(', ')[1]}
                                    </Typography>
                                  </div>
                                  //splitAddressLine(poDetails.address ?? '')
                                  : `Plot No 108, Perumal Koil Street, Alapakkam`
                                }
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? `${poDetails.city} - ${poDetails.pincode}`
                                  : `Chennai - 600116`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? `${poDetails.state}, India`
                                  : `Tamil Nadu, India`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? `${poDetails.primary_contact} / ${poDetails.secondary_contact}`
                                  : `044 2476 5544 / +91 95001 55544`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? poDetails.email_id
                                  : `venkat@kgbuildtech.com`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? poDetails.website_link
                                  : `https://kgbuildtech.com`}
                              </Typography>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <hr></hr>
                    <table>
                      <tr>
                        <td colSpan={3} width={`50%`} className="no-border">
                          <table>
                            <tr>
                              <td className="no-border">
                                {/* className={`td text-bold`} */}
                                <Typography>
                                  <b>Billed To :</b>
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography
                                  className={`td text-bold companyna`}
                                >
                                  {poDetails.client_name}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.client_mobile}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.client_address}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.client_pincode}{" "}
                                  {poDetails.client_state}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.client_city}, India
                                </Typography>
                              </td>
                            </tr>

                            {/* <tr>
                              <td className="no-border">
                                <Typography
                                  className={`td text-bold`}
                                ></Typography>
                              </td>
                            </tr>  */}

                            {/* blank space */}
                            <br />

                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  GSTIN {poDetails.client_gst}
                                </Typography>
                              </td>
                            </tr>

                            {/* Proforma extra fields */}
                            {poDetails.invoice_type == "2" ? (
                              <>
                                <tr>
                                  <td className="no-border">
                                    <Typography className={`td text-bold`}>
                                      CIN: U63010TN1993PTC062664
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography className={`td text-bold`}>
                                      Work Order Date: 26-02-2023
                                    </Typography>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              ""
                            )}

                            {/* Credit Note extra fields */}
                            {poDetails.invoice_type == "3" ? (
                              <>
                                <tr>
                                  <td className="no-border">
                                    <Typography className={`td text-bold`}>
                                      Purchase Order No: 301048669
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography className={`td text-bold`}>
                                      Purchase Order Date: 22-03-2022
                                    </Typography>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              ""
                            )}

                            {/* <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Company Name : {poDetails.company_name}
                                </Typography>
                              </td>
                            </tr> */}
                          </table>
                        </td>

                        {/* right side fields */}
                        <td width={`50%`} className="no-border imgtd">
                          {poDetails.company_name == "K.G CONSTRUCTION" && (
                            <>
                              <table>
                                <tr>
                                  <td className="no-border">
                                    {/* className={`td text-bold`} */}
                                    <Typography textAlign="right">
                                      <b>FACTORY ADDRESS</b>
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography
                                      textAlign="right"
                                      className={`td text-bold`}
                                    >
                                      S No 59/1B, NEELAVATHI GARDEN,
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography textAlign="right" className={`td text-bold`}>
                                      MARIMUTHU ROAD, CHETTIYAR AGARAM,
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography textAlign="right" className={`td text-bold`}>
                                      CHENNAI - 600 077
                                    </Typography>
                                  </td>
                                </tr>

                                {/* blank space */}
                                <br />
                                <br />
                                <br />
                                <br />
                              </table>
                            </>
                          )}

                          {poDetails.company_name == "KG TRADETECH" && (
                            <>
                              <table>
                                <tr>
                                  <td className="no-border">
                                    {/* className={`td text-bold`} */}
                                    <Typography textAlign="right">
                                      <b>SHOP ADDRESS</b>
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography
                                      textAlign="right"
                                      className={`td text-bold`}
                                    >
                                      SHOP NO.2, 95/5, GROUND FLOOR,
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography textAlign="right" className={`td text-bold`}>
                                      KUNDRATHUR MAIN ROAD,
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="no-border">
                                    <Typography textAlign="right" className={`td text-bold`}>
                                      GERUGAMBAKKAM, CHENNAI  - 600 122.
                                    </Typography>
                                  </td>
                                </tr>

                                {/* blank space */}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                              </table>
                            </>
                          )}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                  {/* items form */}
                  <table>
                    <thead>
                      <th>
                        <Typography>S.No</Typography>
                      </th>
                      <th>
                        <Typography>Description of Goods </Typography>
                      </th>
                      <th>
                        <Typography>Quantity (nos.)</Typography>
                      </th>
                      <th>
                        <Typography>Amount (₹)</Typography>
                      </th>
                      <th>
                        <Typography>Total Amount (₹)</Typography>
                      </th>
                      <th>
                        <Typography>GST </Typography>
                      </th>
                      {poDetails.total_cgst == 0 && poDetails.total_sgst == 0
                        ? <th>
                          <Typography>IGST </Typography>
                        </th>

                        : <>
                          <th>
                            <Typography>CGST </Typography>
                          </th>
                          <th>
                            <Typography>SGST </Typography>
                          </th>
                        </>
                      }

                      <th>
                        <Typography>Total Amount + GST (INR)</Typography>
                      </th>
                    </thead>
                    <tbody>
                      {paymentList.length > 0 ? (
                        paymentList.map((row, index) => (
                          <tr className="table_padding">
                            <td className={`text-center`}>
                              <Typography>{index + 1}</Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {row.item_name}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {row.quantity}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">{row.rate}</Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {twoDecimal(row.total_amount_without_gst)}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {(row.gst)}%
                              </Typography>
                            </td>

                            {row.cgst == 0 && row.sgst == 0
                              ? <>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {twoDecimal(row.igst)}
                                  </Typography>
                                </td>
                              </>
                              : <>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {twoDecimal(row.cgst)}
                                  </Typography>
                                </td>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {twoDecimal(row.sgst)}
                                  </Typography>
                                </td>
                              </>
                            }



                            <td className="po_leble">
                              <Typography textAlign="right">
                                {twoDecimal(row.total_amount_with_gst)}
                              </Typography>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>

                    {/* items form data lower part*/}
                    <tbody className="tbody_col">
                      <tr className="table_padding_40">
                        {poDetails.total_cgst != 0 && poDetails.total_sgst != 0
                          ? <>
                            <td className={`text-center`}>
                              <Typography></Typography>
                            </td>
                          </>
                          : <></>
                        }

                        <td colSpan={7}>
                          <Typography>Total</Typography>
                        </td>
                        <td>
                          <Typography>
                            {twoDecimal(poDetails.total_without_gst)}
                          </Typography>
                        </td>
                      </tr>
                      <tr className="table_padding_40">
                        {poDetails.total_cgst != 0 && poDetails.total_sgst != 0
                          ? <>
                            <td className={`text-center`}>
                              <Typography></Typography>
                            </td>
                          </>
                          : <></>
                        }

                        {poDetails.total_cgst == 0 && poDetails.total_sgst == 0
                          ? <>
                            <td colSpan={7}>
                              <Typography>IGST </Typography>
                            </td>
                            <td>
                              <Typography>
                                {twoDecimal(poDetails.total_igst)}
                              </Typography>
                            </td>
                          </>
                          :
                          <>
                            <td colSpan={7}>
                              <Typography>CGST </Typography>
                            </td>
                            <td>
                              <Typography>
                                {twoDecimal(poDetails.total_cgst)}
                              </Typography>
                            </td>
                          </>
                        }
                      </tr>
                      {poDetails.total_sgst != 0 &&
                        <>
                          <tr className="table_padding_40">
                            <td className={`text-center`}>
                              <Typography></Typography>
                            </td>
                            <td colSpan={7}>
                              <Typography>SGST </Typography>
                            </td>
                            <td>
                              <Typography>
                                {twoDecimal(poDetails.total_sgst)}
                              </Typography>
                            </td>
                          </tr>

                        </>
                      }

                      <tr className="table_padding_40">
                        {poDetails.total_cgst != 0 && poDetails.total_sgst != 0
                          ? <>
                            <td className={`text-center`}>
                              <Typography></Typography>
                            </td>
                          </>
                          : <></>
                        }
                        <td colSpan={7}>
                          <Typography>Total amount with GST (INR) </Typography>
                        </td>
                        <td>
                          <Typography>
                            {twoDecimal(poDetails.total_with_gst)}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* Invoice terms and condition */}
                  <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                    <table>
                      <thead>
                        {/* <th>
                          <Typography>Invoice Terms & Condition</Typography>
                        </th> */}
                        <th>
                          <Typography>Terms & Conditions Details </Typography>
                        </th>
                      </thead>
                      <tbody>
                        {/* items form */}
                        {
                          <tr className="table_padding">
                            {/* <td className="po_leble">
                              <Typography textAlign="right">
                                {poDetails.invoice_terms_id}
                              </Typography>
                            </td> */}
                            <td className="po_leble">
                              <Typography textAlign="center">
                                {poDetails.invoice_terms_description}
                              </Typography>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </Grid>

                  {/* Amount in words */}
                  <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                    <table>
                      <thead>
                        <strong>TOTAL INVOICE VALUE (IN WORDS)</strong>
                      </thead>
                      <tr>
                        <h5 style={{ marginTop: "10px", marginBottom: "1rem" }}>
                          {poDetails && (poDetails.total_with_gst > 0) &&
                            `${toWords.convert((poDetails.total_with_gst))}`}
                        </h5>
                      </tr>
                    </table>
                  </Grid>

                  {/* proprietor */}
                  <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                    <table>
                      <thead>
                        <b>Proprietor:</b>
                      </thead>
                      <tr>
                        <td className="no-border">
                          <Typography
                            textAlign="left"
                            className={`td text-bold`}
                          >
                            {poDetails
                              ? `Please make all cheques payable to ${poDetails.company_name}`
                              : `Please make all cheques payable to ABC Bank`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`} fontWeight={poDetails.invoice_type == "2" ? "bold" : ""}>
                            {poDetails.client_name == "TITAN COMPANY LIMITED" && `VENDOR CODE: Demo2017677`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`} fontWeight={poDetails.invoice_type == "2" ? "bold" : ""}>
                            {poDetails
                              ? `GST REGN NO: ${poDetails.gst_no}`
                              : `GST REGN NO:`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`} fontWeight={poDetails.invoice_type == "2" ? "bold" : ""}>
                            {poDetails ? `PAN: ${poDetails.pan_no}` : `PAN: `}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`} fontWeight={poDetails.invoice_type == "2" ? "bold" : ""}>
                            {poDetails ? `TAN: ${poDetails.tan_no}` : `TAN: `}
                          </Typography>
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="no-border">
                          <Typography
                            textAlign="left"
                            className={`td text-bold`}
                          >
                            {poDetails
                              ? `IFSC Code: ${poDetails.ifsc_code}`
                              : `IFSC1234`}
                          </Typography>
                        </td>
                      </tr> */}
                    </table>
                  </Grid>

                  {/* Bank Details */}
                  <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                    <table>
                      <thead>
                        <b>Bank Details:</b>
                      </thead>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `Bank Name: ${poDetails.bank_name}`
                              : `ABC Bank`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `Branch: ${poDetails.branch}`
                              : `ABC Branch`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `Account Number: ${poDetails.account_number}`
                              : `11111111`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `Account Name: ${poDetails.account_name}`
                              : `abc account name`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `IFSC Code: ${poDetails.ifsc_code}`
                              : `IFSC1234`}
                          </Typography>
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="no-border">
                          <Typography
                            textAlign="left"
                            className={`td text-bold`}
                          >
                            https://kgbuildtech.com
                          </Typography>
                        </td>
                      </tr> */}
                    </table>
                  </Grid>
                </Grid>
              </table>
            </Grid>
          </Grid>

          {/* the buttons */}
          <div className="empty-space-20"></div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {poDetails.status == 91 ? (
              <div
                style={{
                  position: "absolute",
                  right: "100px",
                  paddingBottom: "2rem",
                  marginTop: "3rem",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#2F58CD",
                    marginRight: "10px",
                    color: "white",
                  }}
                  className="button"
                  onClick={() => setPOStatus(91)}
                >
                  Approve
                </Button>

                <Button
                  style={{ backgroundColor: "#2F58CD", color: "white" }}
                  className="button"
                  onClick={() => setPOStatus(92)}
                >
                  Deny
                </Button>
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  right: "100px",
                  paddingBottom: "2rem",
                  marginTop: "3rem",
                }}
              >
                {/* <Pdf targetRef={ref} filename={`P.O/${poDetails.invoice_no}/${moment().locale('en-in').format('LLL')}.pdf`} y={5.5} x={15} scale={.7}>
                */}
                {/* "test.pdf"   P.O-${poDetails.po_no}-${moment().locale('en-in').format('LLL')}.pdf*/}

                <Pdf targetRef={ref} filename={`Invoice/${poDetails.invoice_no}/${moment().locale('en-in').format('LLL')}.pdf`} y={5.5} x={15} scale={.63}>
                  {({ toPdf }) => (
                    <Button
                      className="button"
                      onClick={toPdf}
                      style={{
                        backgroundColor: "#2F58CD",
                        marginRight: "10px",
                        color: "white",
                        padding: "15px",
                      }}
                    >
                      <PictureAsPdfIcon />
                      Generate Pdf
                    </Button>
                  )}
                </Pdf>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default InvoiceView;
