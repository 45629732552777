import React, { useEffect, useRef, useState } from "react";
import { Grid, Button, FormControl, Typography } from "@mui/material";
import Layout from "../../../layout/Layout";
import {
  config,
  purchaseOrderAPI,
  purchaseOrderItemsAPI,
} from "../../../config";
import Pdf from "react-to-pdf";
import companyLogo from "./../../../assets/images/Logo/logo.png";
import axios from "axios";
import NotFound from "../../../components/NotFount";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useParams, useNavigate } from "react-router-dom";
// import numberToWords from "number-to-words";
import moment from 'moment';
import 'moment/locale/en-in';
import { ToWords } from 'to-words';

const toWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: { // can be used to override defaults for the selected locale
      name: 'Rupee',
      plural: 'Rupees',
      symbol: '₹',
      fractionalUnit: {
        name: 'Paisa',
        plural: 'Paise',
        symbol: '',
      },
    }
  }
});

function PurchaseOrderView() {
  const { poid } = useParams();
  console.log((poid))

  const [poDetails, setPODetails] = useState([]);
  const [paymentList, setPaymentList] = useState("");

  let tempRef = useRef(false);

  // other all details
  useEffect(() => {
    if (tempRef.current === false) {
      const data = {
        intType: 2,
        intPOId: poid,
        intLimit: 1,
        intOffset: 0,
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = purchaseOrderAPI;

      axios(config)
        .then(function (response) {

          console.log({ poDetails: response.data.result[1][0] });
          // return;
          setPODetails(response.data.result[1][0]);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef.current = true;
      };
    }
  }, []);

  // purchase order items
  useEffect(() => {
    if (tempRef.current === false) {
      const data = {
        intPONo: poid,
        intType: 2,
        // intLimit: 1,
        // intOffset: 0,
      };
      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = purchaseOrderItemsAPI;

      axios(config)
        .then(function (response) {
          console.log({ pO_Items: response.data.result });
          // return;
          setPaymentList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRef.current = true;
      };
    }
  }, []);

  const ref = React.createRef();
  const setPOStatus = (status) => {
    const data = {
      intType: 2,
      intStatus: status,
      intPOId: poid,
    };
    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = purchaseOrderAPI;

    axios(config)
      .then(function (response) { })
      .catch(function (error) {
        console.log(error);
      });
    return () => {
      tempRef.current = true;
    };
  };

  const twoDecimal = (x) => {
    return Number.parseFloat(x).toFixed(2);
  };

  return (
    <Layout>
      <Grid className="login-text-background list_advance">
        <Typography variant="h5" textAlign="center">
          Purchase Order View
        </Typography>
      </Grid>
      <Grid container spacing={2} className={`bg-white`}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid className="element-to-po" ref={ref}>
            <Grid className="page">
              <Typography textAlign="" className={``} style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <h1 style={{ color: '#0066cc' }}>
                  {poDetails.order_type == "1"
                    ? "Purchase Order"
                    : "Work Order"}
                </h1>
              </Typography>
              <table className="tablepad">
                <tr className="no-border">
                  <td colSpan={4}>
                    <tr>
                      <td className="no-border">
                        {/* <Typography textAlign="" className={``}>
                          <h1>
                            {poDetails.order_type == "1"
                              ? "Purchase Order"
                              : "Work Order"}
                          </h1>
                        </Typography> */}
                        <Typography textAlign="left" className={`td text-bold`}>
                          Purchase Order No.: {poDetails.po_no}
                        </Typography>
                      </td>
                    </tr>

                    <hr />

                    <tr className="no-border">
                      <td className="no-border imgtd">
                        <img
                          src={companyLogo}
                          alt="KG"
                          width={`200px`}
                          height={`auto`}
                        />
                      </td>
                      <td width={`100%`} className="no-border">
                        <table>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails.company_Name}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? poDetails.company_address
                                  : `Plot No 108, Perumal Koil Street, Alapakkam
                                Porur`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? `${poDetails.company_city} - ${poDetails.company_pincode}`
                                  : `Chennai - 600116`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? `${poDetails.company_state}, India`
                                  : `Tamil Nadu, India`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? `${poDetails.company_primary_contact} / ${poDetails.company_secondary_contact}`
                                  : `044 2476 5544 / +91 95001 55544`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? poDetails.company_emailId
                                  : `venkat@kgbuildtech.com`}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography
                                textAlign="right"
                                className={`td text-bold`}
                              >
                                {poDetails
                                  ? poDetails.company_website
                                  : `https://kgbuildtech.com`}
                              </Typography>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <hr></hr>
                    <table>
                      <tr>
                        <td colSpan={3} width={`50%`} className="no-border">
                          <table>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Bill To
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography
                                  className={`td text-bold companyna`}
                                >
                                  {poDetails.vendor_name}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.vendor_mobile}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.vendor_street}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.vendor_pincode}{" "}
                                  {poDetails.vendor_state}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  {poDetails.vendor_city}, India
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  GSTIN {poDetails.vendor_gst}
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography
                                  className={`td text-bold`}
                                ></Typography>
                              </td>
                            </tr>
                            <tr>
                              <td className="no-border">
                                <Typography className={`td text-bold`}>
                                  Place of Supply : {poDetails.vendor_gst}
                                </Typography>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td width={`50%`} className="no-border imgtd">
                          <tr>
                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                Purchace Order No :
                              </Typography>
                            </td>
                            <td className="no-border">
                              <Typography
                                className={`td text-bold`}
                              ></Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                Dispatch Through :
                              </Typography>
                            </td>

                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                {poDetails.dispatch_through}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                Mode/Terms of Payment :
                              </Typography>
                            </td>

                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                {poDetails.terms_of_payment}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                Supplier Ref./Quotation No.:
                              </Typography>
                            </td>

                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                {poDetails.quotation_no}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                Packing Charge :
                              </Typography>
                            </td>

                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                {poDetails.packing_charge}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                Purchase Classification :
                              </Typography>
                            </td>

                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                {poDetails.purchase_classfication}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                Purchace Date :
                              </Typography>
                            </td>

                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                {poDetails.po_date}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                Choose Valid upto Date :
                              </Typography>
                            </td>

                            <td className="no-border">
                              <Typography className={`td text-bold`}>
                                {poDetails.po_validate_upto}
                              </Typography>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                  <table>
                    <thead>
                      <th>
                        <Typography>S.No</Typography>
                      </th>
                      <th>
                        <Typography>Description of Goods </Typography>
                      </th>
                      <th>
                        <Typography>Quantity (nos.)</Typography>
                      </th>
                      <th>
                        <Typography>Amount (₹)</Typography>
                      </th>
                      <th>
                        <Typography>Total Amount (₹)</Typography>
                      </th>
                      <th>
                        <Typography>GST </Typography>
                      </th>
                      {poDetails.total_cgst == 0 && poDetails.total_sgst == 0
                        ? <th>
                          <Typography>IGST </Typography>
                        </th>

                        : <>
                          <th>
                            <Typography>CGST </Typography>
                          </th>
                          <th>
                            <Typography>SGST </Typography>
                          </th>
                        </>
                      }
                      <th>
                        <Typography>Total Amount + GST (INR)</Typography>
                      </th>
                    </thead>
                    <tbody>
                      {paymentList.length > 0 ? (
                        paymentList.map((row, index) => (
                          <tr className="table_padding">
                            <td className={`text-center`}>
                              <Typography>{index + 1}</Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {row.item_name}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {row.quantity}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {row.amount}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {row.total_amount_without_gst}
                              </Typography>
                            </td>
                            <td className="po_leble">
                              <Typography textAlign="right">
                                {row.gst}%
                              </Typography>
                            </td>

                            {row.cgst == 0 && row.sgst == 0
                              ? <>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {twoDecimal(row.igst)}
                                  </Typography>
                                </td>
                              </>
                              : <>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {twoDecimal(row.cgst)}
                                  </Typography>
                                </td>
                                <td className="po_leble">
                                  <Typography textAlign="right">
                                    {twoDecimal(row.sgst)}
                                  </Typography>
                                </td>
                              </>
                            }

                            <td className="po_leble">
                              <Typography textAlign="right">
                                {twoDecimal(row.total_amount_with_gst)}
                              </Typography>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                    <tbody className="tbody_col">
                      <tr className="table_padding_40">
                        {poDetails.total_cgst != 0 && poDetails.total_sgst != 0
                          ? <>
                            <td className={`text-center`}>
                              <Typography></Typography>
                            </td>
                          </>
                          : <></>
                        }
                        <td colSpan={7}>
                          <Typography>Total</Typography>
                        </td>
                        <td>
                          <Typography>{poDetails.total_without_gst}</Typography>
                        </td>
                      </tr>
                      <tr className="table_padding_40">
                        {poDetails.total_cgst != 0 && poDetails.total_sgst != 0
                          ? <>
                            <td className={`text-center`}>
                              <Typography></Typography>
                            </td>
                          </>
                          : <></>
                        }
                        {poDetails.total_cgst == 0 && poDetails.total_sgst == 0
                          ? <>
                            <td colSpan={7}>
                              <Typography>IGST </Typography>
                            </td>
                            <td>
                              <Typography>
                                {twoDecimal(poDetails.total_igst)}
                              </Typography>
                            </td>
                          </>
                          :
                          <>
                            <td colSpan={7}>
                              <Typography>CGST </Typography>
                            </td>
                            <td>
                              <Typography>
                                {twoDecimal(poDetails.total_cgst)}
                              </Typography>
                            </td>
                          </>
                        }
                      </tr>

                      {poDetails.total_sgst != 0 &&
                        <>
                          <tr className="table_padding_40">
                            <td className={`text-center`}>
                              <Typography></Typography>
                            </td>
                            <td colSpan={7}>
                              <Typography>SGST </Typography>
                            </td>
                            <td>
                              <Typography>
                                {twoDecimal(poDetails.total_sgst)}
                              </Typography>
                            </td>
                          </tr>

                        </>
                      }

                      {/* <tr className="table_padding_40">
                        {poDetails.total_cgst != 0 && poDetails.total_sgst != 0
                          ? <>
                            <td className={`text-center`}>
                              <Typography></Typography>
                            </td>
                          </>
                          : <></>
                        }
                        <td colSpan={7}>
                          <Typography>Transport Gst</Typography>
                        </td>
                        <td>
                          <Typography>{poDetails.transport_gst}% </Typography>
                        </td>
                      </tr> */}

                      <tr className="table_padding_40">
                        <td className={`text-center`}>
                          <Typography></Typography>
                        </td>
                        <td colSpan={2}>
                          <Typography>Transport Gst</Typography>
                        </td>

                        <td className="po_leble">
                          <Typography>{poDetails.transport_gst}% </Typography>
                        </td>

                        {/* <td>
                          <Typography>Transport amount</Typography>
                        </td>
                        <td colSpan={1}>
                          <Typography>{poDetails.transport_amount} </Typography>
                        </td> */}
                        {poDetails.total_igst > 0
                          ? <td colSpan={3}>
                            <Typography>Transport charge (INR)</Typography>
                          </td>

                          : <td colSpan={4}>
                            <Typography>Transport charge (INR)</Typography>
                          </td>
                        }

                        <td colSpan={2}>
                          <Typography>{twoDecimal(poDetails.transport_charge)} </Typography>
                        </td>
                      </tr>



                      <tr className="table_padding_40">
                        {poDetails.total_cgst != 0 && poDetails.total_sgst != 0
                          ? <>
                            <td className={`text-center`}>
                              <Typography></Typography>
                            </td>
                          </>
                          : <></>
                        }
                        <td colSpan={7}>
                          <Typography>Total amount with GST (INR)</Typography>
                        </td>
                        <td>
                          <Typography>{twoDecimal(poDetails.total_with_gst)}</Typography>
                        </td>
                      </tr>



                    </tbody>
                  </table>

                  {/* Invoice terms and condition */}
                  <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                    <table>
                      <thead>
                        {/* <th>
                          <Typography>
                            Purchase Order Terms & Condition
                          </Typography>
                        </th> */}
                        <th>
                          <Typography>
                            Purchase Order Terms & Condition{" "}
                          </Typography>
                        </th>
                      </thead>
                      <tbody>
                        {/* items form */}
                        <tr className="table_padding">
                          {/* <td className="po_leble">
                              <Typography textAlign="right">
                                {poDetails.invoice_terms_id}
                              </Typography>
                            </td> */}
                          <td className="po_leble">
                            <Typography textAlign="center">
                              {poDetails.terms}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>

                  {/* Amount in words */}
                  <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                    <table>
                      <thead>
                        <b>TOTAL INVOICE VALUE (IN WORDS)</b>
                      </thead>
                      <tr>
                        <h5 style={{ marginTop: "10px", marginBottom: "1rem" }}>
                          {poDetails && (poDetails.total_with_gst > 0) &&
                            `${toWords.convert((poDetails.total_with_gst))}`}
                        </h5>
                      </tr>
                    </table>
                  </Grid>

                  {/* proprietor */}
                  <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                    <table>
                      <thead>
                        <b>Proprietor:</b>
                      </thead>
                      <tr>
                        <td className="no-border">
                          <Typography
                            textAlign="left"
                            className={`td text-bold`}
                          >
                            {poDetails
                              ? `Please make all cheques payable to ${poDetails.company_name}`
                              : `Please make all cheques payable to ABC Bank`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `VENDOR CODE: Demo2017677`
                              : `VENDOR CODE: Demo2017677`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `GST REGN NO: ${poDetails.gst_no}`
                              : `GST REGN NO:`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails ? `PAN: ${poDetails.pan_no}` : `PAN: `}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails ? `TAN: ${poDetails.tan_no}` : `TAN: `}
                          </Typography>
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="no-border">
                          <Typography
                            textAlign="left"
                            className={`td text-bold`}
                          >
                            {poDetails
                              ? `IFSC Code: ${poDetails.ifsc_code}`
                              : `IFSC1234`}
                          </Typography>
                        </td>
                      </tr> */}
                    </table>
                  </Grid>

                  {/* Bank Details */}
                  <Grid className="table_pdf" item xs={12} lg={12} md={12}>
                    <table>
                      <thead>
                        <b>Bank Details:</b>
                      </thead>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `Bank Name: ${poDetails.bank_name}`
                              : `ABC Bank`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `Branch: ${poDetails.branch}`
                              : `ABC Branch`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `Account Number: ${poDetails.account_number}`
                              : `11111111`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `Account Name: ${poDetails.account_name}`
                              : `abc account name`}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="no-border">
                          <Typography textAlign="left" className={`td`}>
                            {poDetails
                              ? `IFSC Code: ${poDetails.ifsc_code}`
                              : `IFSC1234`}
                          </Typography>
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="no-border">
                          <Typography
                            textAlign="left"
                            className={`td text-bold`}
                          >
                            https://kgbuildtech.com
                          </Typography>
                        </td>
                      </tr> */}
                    </table>
                  </Grid>
                </Grid>
              </table>
            </Grid>
          </Grid>

          {/* Lower Buttons */}
          <div className="empty-space-20"></div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {poDetails.status == 91 ? (
              <div
                style={{
                  position: "absolute",
                  right: "100px",
                  paddingBottom: "2rem",
                  marginTop: "3rem",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#2F58CD",
                    marginRight: "10px",
                    color: "white",
                  }}
                  className="button"
                  onClick={() => setPOStatus(91)}
                >
                  Approve
                </Button>
                <Button
                  style={{ backgroundColor: "#2F58CD", color: "white" }}
                  className="button"
                  onClick={() => setPOStatus(92)}
                >
                  Deny
                </Button>
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  right: "100px",
                  paddingBottom: "2rem",
                  marginTop: "3rem",
                }}
              >
                {/* "test.pdf"   P.O-${poDetails.po_no}-${moment().locale('en-in').format('LLL')}.pdf*/}
                <Pdf targetRef={ref} filename={`P.O/${poDetails.po_no}/${moment().locale('en-in').format('LLL')}.pdf`} y={5.5} x={15} scale={.63}>
                  {({ toPdf }) => (
                    <Button
                      className="button"
                      onClick={toPdf}
                      style={{
                        backgroundColor: "#2F58CD",
                        marginRight: "10px",
                        color: "white",
                        padding: "15px",
                      }}
                    >
                      <PictureAsPdfIcon />
                      Generate Pdf
                    </Button>
                  )}
                </Pdf>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
}
export default PurchaseOrderView;
