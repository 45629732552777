import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";

import {
  Button,
  Grid,
  Typography,
  Tooltip,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import axios from "axios";
import Layout from "../../layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import {
  config,
  employeeAttandenceAPI,
  attandenceAPI,
  handleHrMasterAPI,
} from "../../config";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Map from "../Map";

import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { _getDropdownListData } from "../../service";
import { LoadingButton } from "@mui/lab";
import DataTable from "react-data-table-component";

function EmployeeAttendance() {
  const { id } = useParams();
  const [zoom] = useState(15);
  // const [center, setCenter] = useState({
  //   lat: 13.0417385,
  //   lng: 80.1660521,
  // });
  const childRef = useRef();

  const navigate = useNavigate();
  const [employeeAttendanceReport, setEmployeeAttendanceReport] = useState([]);

  const [date, setDate] = React.useState(dayjs().format("YYYY-MM-DD"));
  const [employeeID, setEmployeeID] = useState(0);
  const [loading, setLoading] = useState(false);
  const [employeeName, setEmployeeName] = useState("");

  let temprefs1 = useRef(false);
  useEffect(() => {
    if (temprefs1.current === false) {
      // console.log(id);
      const splitData = id.split("-");
      setEmployeeID(splitData[0]);
      onSearch(splitData[0], dayjs(splitData[1]));
      getEmployeeName(splitData[0]);
      setDate(dayjs(splitData[1]));
    }
  }, []);

  const getEmployeeName = (id) => {
    const data = JSON.stringify({
      intEmployeeAutoID: id,
      intType: 8,
    });

    config.method = "POST";
    config.data = data;
    config.url = handleHrMasterAPI;
    axios(config)
      .then(function (response) {
        console.log(response.data["result"]);
        setEmployeeName(response.data["result"][0].employee_name);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onSearch = (id, selectedDate) => {
    const data = JSON.stringify({
      txtStartTime: selectedDate
        ? dayjs(selectedDate).format("YYYY-MM-DD")
        : dayjs(date).format("YYYY-MM-DD"),
      intEmployeeID: employeeID == 0 ? id : employeeID,
      intType: 7,
    });

    config.method = "POST";
    config.data = data;
    config.url = attandenceAPI;
    axios(config)
      .then(function (response) {
        setEmployeeAttendanceReport(response.data["result"]);
        childRef.current.googleMap(response.data["result"]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let tempRefs = useRef(false);

  const columns = useMemo(() => [
    {
      name: "Project Name",
      field: "name",
      sortable: false,
      cell: (row) => (row.project_name ? row.project_name : "-"),
      center: true,
    },
    {
      name: "Start Time",
      field: "name",
      sortable: false,
      cell: (row) => (row.project_start_time ? row.project_start_time : "-"),
      center: true,
    },
    {
      name: "End Time",
      field: "name",
      sortable: false,
      cell: (row) => (row.project_end_time ? row.project_end_time : "-"),
      center: true,
    },

    {
      name: "Hours of working",
      field: "name",
      sortable: false,
      cell: (row) => (row.hours_of_working ? row.hours_of_working : "-"),
      center: true,
    },
  ]);

  return (
    <Layout>
      <Grid className="employee-form">
        <Grid container spacing={3}>
          <Grid
            textAlign="center"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <Typography className="page_title" textAlign="left">
              Employee Attendance View
            </Typography>
          </Grid>
          <Grid
            textAlign="right"
            item
            className="form-lable"
            xs={12}
            lg={6}
            md={6}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
            >
              Back
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          margin={`15px 0px`}
          padding={`10px 15px 0px 0px`}
        >
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              name="employeename"
              label="Employee Name"
              type="email"
              fullWidth
              value={employeeName}
              contentEditable={false}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select Date"
                value={date}
                inputFormat="DD/MM/YYYY"
                fullWidth
                // minDate={new Date("02-01-2020")}
                // maxDate={new Date("02-29-2020")}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    // helperText={errors.txtDob?.message}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              onClick={() => {
                onSearch();
              }}
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid container spacing={1.5} marginTop={2}>
          <Grid item xs={12} md={12} lg={6}>
            <Map
              zoom={zoom}
              // center={center}
              markerData={employeeAttendanceReport}
              ref={childRef}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            {/* <div className="table-container">
            <table className="tbl">
              <thead>
                <th>
                  <Typography>Event</Typography>
                </th>
                <th>
                  <Typography>Time</Typography>
                </th>
              </thead>
              <tbody>
                {markerData?.map((row) => (
                  <tr>
                    <td>
                      <Typography>{row.event_name}</Typography>
                    </td>
                    <td>
                      <Typography>
                        {moment(row.start_time).format("MMM DD, YYYY")}
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
            <Grid className="form_design">
              <DataTable
                pagination
                paginationServer
                // fixedHeader
                fixedHeaderScrollHeight="550px"
                columns={columns}
                data={employeeAttendanceReport}
                progressPending={loading}
                // paginationTotalRows={totalCount}
                // paginationDefaultPage={currentPage}
                // paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
                // paginationPerPage={rowsPerPage}
                // onChangePage={handlePageChange}
                // onSort={handleSort}
                // onChangeRowsPerPage={handleRowsPerPageChange}
                // expandableRows
                // expandableRowsComponent={ExpandedComponent}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default EmployeeAttendance;
