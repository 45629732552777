import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  lazy,
  useMemo,
} from "react";
import {
  Grid,
  Tooltip,
  Typography,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import axios from "axios";
import Layout from "../../../layout/Layout";
import { advancePaymentAPI, config, userData } from "../../../config";
import NotFound from "../../../components/NotFount";
import { Create } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TextField from "@mui/material/TextField";
import { AlertMessage } from "../../../components/AlertMessage";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment/moment";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TaskAltSharpIcon from "@mui/icons-material/TaskAltSharp";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import RunningWithErrorsRoundedIcon from "@mui/icons-material/RunningWithErrorsRounded";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";

let field = "id",
  orderBy = "desc";

const Pagination = lazy(() => import("../../../components/ReactPagination"));

function ProjectMasterReport() {
  const navigate = useNavigate();

  const [advancePaymentList, setAdvancePaymentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [currentPage, setCurrentPage] = useState(1);

  let tempRefs = useRef(false);
  useEffect(() => {
    if (tempRefs.current === false) {
      fetchUsers(1);
    }
  }, []);

  const fetchUsers = async (page, rowsPerPage) => {
    if (tempRefs.current === false) {
      const data = JSON.stringify({
        intLimit: rowsPerPage ? rowsPerPage : 10,
        intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
        intType: 3,
      });
      config.method = "POST";
      config.data = data;
      config.url = advancePaymentAPI;
      axios(config)
        .then(function (response) {
          setAdvancePaymentList(response.data.result);
          console.log(response.data.result);
          setTotalCount(response.data.totalCount);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    tempRefs.current = true;
  };

  const handlePageChange = (page) => {
    tempRefs.current = false;
    fetchUsers(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!advancePaymentList.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    tempRefs.current = false;
    fetchUsers(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setTimeout(() => {
      field = column.field;
      orderBy = sortDirection;

      fetchUsers(1);
      setLoading(false);
    }, 100);
  };

  const handleEditRole = (id) => {
    navigate(`/advance-payment/view/${id}`);
  };

  const ExpandedComponent = ({ data }) => {
    const dueDates = data.due_date ? data.due_date.split(",") : [];
    const dueAmounts = data.due_amount ? data.due_amount.split(",") : [];
    const statusNames = data.status_name ? data.status_name.split(",") : [];

    return (
      <table>
        <thead>
          <th>
            <Typography className="tbl-data">Due Date</Typography>
          </th>
          <th>
            <Typography className="tbl-data">Due Amount</Typography>
          </th>
          <th>
            <Typography className="tbl-data">Status</Typography>
          </th>
        </thead>
        {dueDates.map((dueDate, index) => (
          <tr key={index}>
            <td>
              <Typography>
                {dueDate ? moment(dueDate).format("DD-MM-YYYY") : "-"}
              </Typography>
            </td>
            <td>
              <Typography>
                {dueAmounts[index] ? dueAmounts[index] : "-"}
              </Typography>
            </td>
            <td>
              <Typography>
                {statusNames[index] ? statusNames[index] : "-"}
              </Typography>
            </td>
          </tr>
        ))}
      </table>
    );
  };

  const columns = useMemo(() => [
    {
      name: "Employee No",
      field: "id",
      sortable: true,
      selector: (row) => (row.employee_no ? row.employee_no : "-"),
    },
    {
      name: "User Name",
      field: "id",
      sortable: true,
      selector: (row) => (row.username ? row.username : "-"),
    },
    {
      name: "Payment Type",
      field: "id",
      sortable: true,
      selector: (row) => (row.payment_type ? row.payment_type : "-"),
    },
    {
      name: "Amount",
      field: "id",
      sortable: true,
      selector: (row) => (row.amount ? row.amount : "-"),
    },
    {
      name: "Balance Amount",
      field: "id",
      sortable: true,
      selector: (row) => (row.balance_amount ? row.balance_amount : "-"),
    },
    {
      name: "Payment Mode",
      field: "id",
      // sortable: true,
      selector: (row) => (row.payment_mode ? row.payment_mode : "-"),
    },
    {
      name: "Transaction ID",
      field: "id",
      // sortable: true,
      cell: (row) => (row.transaction_id ? row.transaction_id : "-"),
    },
    {
      name: "Date of Advance",
      field: "id",
      sortable: true,
      selector: (row) =>
        row.date_of_advance
          ? moment(row.date_of_advance).format("DD-MM-YYYY")
          : "-",
    },
    {
      name: "Date of Repay",
      field: "id",
      sortable: true,
      selector: (row) =>
        row.date_of_repay
          ? moment(row.date_of_repay).format("DD-MM-YYYY")
          : "-",
    },
    {
      name: "Action",
      field: "id",
      // sortable: true,
      cell: (row) => {
        return (
          <Grid>
            {userData.role_id == 35 && (
              <>
                {row.payment_status == 91 && (
                  <Typography className="icon_colorpending">
                    <Tooltip title="Waiting for Admin Approval">
                      <RunningWithErrorsRoundedIcon />
                    </Tooltip>
                  </Typography>
                )}
                {row.payment_status == 92 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    endIcon={<SendIcon />}
                    onClick={(e) => {
                      handleactionOpen();
                      setPaymentRequestId(row.id);
                    }}
                  >
                    Action
                  </Button>
                )}
                {row.payment_status == 93 && (
                  <Typography>
                    <Tooltip title="Paid to user. Installment is ongoing">
                      <DoneIcon />
                    </Tooltip>
                  </Typography>
                )}
                {row.payment_status == 94 && (
                  <Typography>
                    <Tooltip title="Payment Done !">
                      <DoneAllIcon className="icon_coloraccept" />
                    </Tooltip>
                  </Typography>
                )}
                {row.payment_status == 95 && (
                  <Typography>
                    <Tooltip title="Admin Declined">
                      <HighlightOffRoundedIcon className="icon_colordeclined" />
                    </Tooltip>
                  </Typography>
                )}
              </>
            )}
          </Grid>
        );
      },
    },
  ]);
  let defaultValues = {
    txtName: "",
    txtLOB: "",
    txtStatus: "",
  };
  const handleEdit = (id) => {
    // defaultValues.txtName = `Central`;
    // defaultValues.txtLOB = `1`;
    // defaultValues.txtStatus = `2`;
    // reset({ ...defaultValues });
    // setIsEdit(true);
  };

  // const handleReportPagination = (e) => {
  //   console.log(e.target.textContent);
  //   // setIsDataLoading(true);
  //   intOffset =
  //     e.target.innerText === "1"
  //       ? 0
  //       : (parseInt(e.target.innerText) - 1) * limit;
  // };

  let assetsRef = useRef(false);
  const childRef = useRef();

  // useEffect(function () {
  //   if (assetsRef.current === false) {
  //     fetchData(1);
  //     return () => {
  //       assetsRef.current = true;
  //     };
  //   }
  // }, []);

  // const fetchData = (action) => {
  //   if (action === 1) {
  //     intOffset = 0;
  //   }

  //   const data = JSON.stringify({
  //     intType: 3,
  //   });

  //   config.method = "POST";
  //   config.data = data;
  //   config.url = advancePaymentAPI;

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response.data.result);
  //       setAdvancePaymentList(response.data.result);

  //       setTotalCount(Math.round(response.data.totalCount / limit));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const downloadExcel = () => {
    const data = JSON.stringify({
      intType: 8,
      txtPaymentType: advancePaymentType,
    });
    config.method = "POST";
    config.data = data;
    config.url = advancePaymentAPI;

    axios(config)
      .then(function (response) {
        const newData = response.data["result"]
          .map((row) => {
            const dueDates = row.due_date ? row.due_date.split(",") : [];
            const dueAmounts = row.due_amount ? row.due_amount.split(",") : [];
            const statusNames = row.status_name
              ? row.status_name.split(",")
              : [];
            const formattedData = [];

            for (let i = 0; i < dueDates.length; i++) {
              formattedData.push({
                Employee_No: i === 0 ? row.employee_no : "-",
                Username: i === 0 ? row.username : "-",
                Payment_Type: i === 0 ? row.payment_type : "-",
                Amount: i === 0 ? row.amount : "-",
                Balance_Amount: i === 0 ? row.balance_amount : "-",
                Payment_Mode: i === 0 ? row.payment_mode : "-",
                Transaction_ID: i === 0 ? row.transaction_id : "-",
                Date_of_Advance:
                  i === 0
                    ? moment(row.date_of_advance).format("DD-MM-YYYY")
                    : "-",
                Date_of_Repay:
                  i === 0
                    ? moment(row.date_of_repay).format("DD-MM-YYYY")
                    : "-",
                Due_Date: dueDates[i] ? dueDates[i] : "-",
                Due_Amount: dueAmounts[i] ? dueAmounts[i] : "-",
                Status: statusNames[i] ? statusNames[i] : "-",
              });
            }

            return formattedData;
          })
          .flat();

        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workBook,
          workSheet,
          "Advance Payment Report"
        );
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(
          workBook,
          `KG-Advance-Payments-${moment().format("DD-MM-YYYY")}.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };

  const [advancePaymentType, setAdvancePaymentType] = useState("");
  const [paymentMode, setPaymentMode] = useState("");

  const [reference, setTransactionReference] = useState("");
  const [accountantDescription, setAccountantDescription] = useState("");

  const [paymentRequestId, setPaymentRequestId] = useState(0);

  const onSubmit = (page = 1, rowsPerPage) => {
    const data = {
      intType: 3,
      intLimit: rowsPerPage ? rowsPerPage : 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      txtPaymentType: advancePaymentType,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = advancePaymentAPI;

    axios(config)
      .then(function (response) {
        console.log(response.data.result);
        setAdvancePaymentList(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [paymentDetailFields, setpaymentDetailFields] = React.useState(false);

  const handleactionOpen = () => {
    setpaymentDetailFields(true);
  };
  const handleactionClose = () => {
    setpaymentDetailFields(false);
  };
  const [dateofPayment, setDateofPayment] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );

  const accountantUpdate = (requestID) => {
    const data = JSON.stringify({
      intType: 7,
      intAccountantID: userData.emp_id,
      txtPaymentMode: paymentMode,
      txtAccountantPaidDatetime: moment().format("YYYY-MM-DD HH:mm:ss"),
      intAdvancePaymentID: requestID,
      txtAccountantDescription: accountantDescription,
      txtTransactionID: reference,
      intPaymentStatus: 93,
    });

    config.method = "POST";
    config.data = data;
    config.url = advancePaymentAPI;

    axios(config)
      .then(function (response) {
        childRef.current.triggerAlert(
          JSON.stringify({
            message: response.data.message,
            messageType: "success",
          })
        );
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //console.log(advancePaymentType)

  return (
    <Layout>
      <AlertMessage ref={childRef} />
      <Grid container spacing={3}>
        <Grid
          textAlign="center"
          item
          className="form-lable"
          xs={12}
          lg={6}
          md={6}
        >
          <Typography className="page_title" textAlign="left">
            List Advance Payment
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/dashboard">
              Dashboard
            </Link>
            <Typography color="text.primary">Advance Payment</Typography>
            <Typography color="text.primary">List</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid
          textAlign="right"
          item
          className="form-lable"
          xs={12}
          lg={6}
          md={6}
        >
          <LoadingButton
            size="medium"
            variant="contained"
            color="light_Purple"
            className="button_first"
            href="/advance-payment/add/"
            startIcon={<AddIcon />}
          >
            Add Advance Payment
          </LoadingButton>
          <LoadingButton
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid className="form_design">
        {advancePaymentList.length > 0 ? (
          <>
            <Grid
              container
              spacing={2}
              margin={`15px 0px`}
              padding={`10px 15px 0px 0px`}
            >
              <Grid container item xs={12} md={6} lg={6}>
                <Grid item xs={12} md={9} lg={9}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Advance Payments Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={advancePaymentType}
                      label="Advance Payments Type"
                      onChange={(e) => {
                        setAdvancePaymentType(e.target.value);
                      }}
                    >
                      <MenuItem value="salaryAdvance">Salary Advance</MenuItem>
                      <MenuItem value="personalAdvance">
                        Personal Advance
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={3}
                  textAlign="center"
                  className="align_button"
                >
                  <Button
                    variant="primary"
                    className={`btn-primary`}
                    onClick={onSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Grid textAlign="right" item xs={12} lg={6} md={6}>
                <Button className="btn-primary" onClick={downloadExcel}>
                  Export to Excel
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Grid>
          <DataTable
            pagination
            paginationServer
            fixedHeader
            fixedHeaderScrollHeight="550px"
            columns={columns}
            data={advancePaymentList}
            progressPending={loading}
            paginationTotalRows={totalCount}
            paginationDefaultPage={currentPage}
            paginationRowsPerPageOptions={[1, 10, 25, 50, 100]} //you can remove it later, just to have more pages
            paginationPerPage={rowsPerPage}
            onChangePage={handlePageChange}
            onSort={handleSort}
            onChangeRowsPerPage={handleRowsPerPageChange}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
          />
        </Grid>
        <Dialog open={paymentDetailFields}>
          <DialogTitle>Payment Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter Payment Transaction ID and Description
            </DialogContentText>
            <div className="empty-space-20"></div>
            <Grid container spacing={3}>
              <Grid item className="form-lable" xs={12} lg={6} md={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  format="DD-MM-YYYY"
                >
                  <MobileDatePicker
                    label="Date of Payment"
                    value={dateofPayment}
                    inputFormat="DD/MM/YYYY"
                    fullWidth
                    onChange={(newValue) => {
                      setDateofPayment(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid textAlign="center" item xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Mode of Payment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={paymentMode}
                    label="Mode of Payment"
                    onChange={(e) => {
                      setPaymentMode(e.target.value);
                    }}
                  >
                    <MenuItem value="Cheque">Cheque</MenuItem>
                    <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="UPI">UPI</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid textAlign="center" item xs={12} lg={6} md={6}>
                <TextField
                  type="text"
                  fullWidth
                  label="Transaction ID"
                  onChange={(e) => setTransactionReference(e.target.value)}
                />
              </Grid>
              <Grid textAlign="center" item xs={12} lg={6} md={6}>
                <TextField
                  type="text"
                  fullWidth
                  label="Description"
                  onChange={(e) => setAccountantDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ margin: "10px" }}
              className={`btn-primary`}
              type="submit"
              onClick={() => accountantUpdate(paymentRequestId)}
            >
              Update
            </Button>
            <Button
              style={{ margin: "10px" }}
              className={`btn-primary`}
              type="submit"
              // value={row.id}
              onClick={handleactionClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* <div className={`table-responsive`}>
          <table className={`table`}>
            <thead>
              <th>
                <Typography variant="h6">S.No</Typography>
              </th>
              <th>
                <Typography variant="h6">Name</Typography>
              </th>
              <th>
                <Typography variant="h6">Payment Type </Typography>
              </th>
              <th>
                <Typography variant="h6">Total Amount </Typography>
              </th>
              <th>
                <Typography variant="h6">Balance Amount </Typography>
              </th>
              <th>
                <Typography variant="h6">Action </Typography>
              </th>
            </thead>
            <tbody>
              {advancePaymentList.length > 0 ? (
                advancePaymentList.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td className={`text-center`}>
                        <Typography className={`text-center`}>
                          {index + 1}
                        </Typography>
                      </td>

                      <td>
                        <Typography className={`text-center`}>
                          {row.username}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`text-center`}>
                          {row.payment_type}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`text-center`}>
                          {row.amount}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={`text-center`}>
                          {row.balance_amount}
                        </Typography>
                      </td>
                      <td className={`text-center`}>
                        <Tooltip title={`Edit`}>
                          <Create
                            cursor={`pointer`}
                            onClick={() => handleEditRole(row.id)}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className={`text-center`}>
                    <NotFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div> */}
        {/* <div className="empty-space-20"></div> */}
        {/* <Grid
          item
          lg={6}
          md={6}
          sm={6}
          textAlign={`right`}
          display={`flex`}
          justifyContent={`flex-end`}
        >
          <Suspense fallback={<div></div>}>
            <Pagination
              totalCount={totalCount}
              handleReportPagination={handleReportPagination}
            />
          </Suspense>
        </Grid> */}
      </Grid>
    </Layout>
  );
}

export default ProjectMasterReport;
