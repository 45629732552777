// import React, { useEffect, useRef, useState, useMemo } from "react";
// import {
//   Button,
//   Grid,
//   Typography,
//   Tooltip,
//   TextField,
//   InputLabel,
//   Select,
//   MenuItem,
//   FormControl,
// } from "@mui/material";
// import { LoadingButton } from "@mui/lab";
// import axios from "axios";
// import Layout from "../../layout/Layout";
// import { useNavigate, useParams } from "react-router-dom";
// import {
//   config,
//   reportsAPI,
//   projectMasterAPI,
//   leaveAPI,
//   handleHrMasterAPI,
// } from "../../config";
// import DataTable from "react-data-table-component";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as XLSX from "xlsx/xlsx.mjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import moment from "moment";
// import { Link } from "react-router-dom";
// import dayjs from "dayjs";

// function EodReport() {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
//   const [currentPage, setCurrentPage] = useState(1);

//   let tempRefs = useRef(false);
//   const handlePageChange = (page) => {
//     tempRefs.current = false;
//     getEodReport(page, rowsPerPage);
//     setCurrentPage(page);
//   };

//   const handleRowsPerPageChange = async (newRowsPerPage) => {
//     tempRefs.current = false;
//     getEodReport(1, newRowsPerPage);
//     setRowsPerPage(newRowsPerPage);
//     setCurrentPage(1);
//   };

//   // const handleSort = (column, sortDirection) => {
//   //   setLoading(true);
//   //   setTimeout(() => {
//   //     field = column.field;
//   //     orderBy = sortDirection;
//   //     setLoading(false);
//   //   }, 100);
//   // };

//   const columns = useMemo(
//     () => [
//       {
//         name: "Department",
//         field: "id",
//         sortable: true,
//         selector: (row) => (
//           <Tooltip title={row.department_name}>
//             <span>
//               {row.department_name ? (
//                 <span>
//                   {row.department_name.split(" ").slice(0, 5).join(" ")}
//                 </span>
//               ) : (
//                 "-"
//               )}
//             </span>
//           </Tooltip>
//         ),
//         width: "270px",
//       },
//       {
//         name: "Employee Name",
//         field: "id",
//         sortable: true,
//         selector: (row) => (
//           <Tooltip title={row.employee_name}>
//             <span>
//               {row.employee_name ? (
//                 <span>
//                   {row.employee_name.split(" ").slice(0, 5).join(" ")}
//                 </span>
//               ) : (
//                 "-"
//               )}
//             </span>
//           </Tooltip>
//         ),
//         width: "220px",
//       },
//       {
//         name: "Project Name",
//         field: "id",
//         sortable: true,
//         selector: (row) => (row.project_name ? row.project_name : "-"),
//       },
//       {
//         name: "Description",
//         field: "id",
//         sortable: true,
//         cell: (row) => (
//           <Tooltip title={row.description}>
//             <span>
//               {row.description ? (
//                 <span>{row.description.split(" ").slice(0, 5).join(" ")}</span>
//               ) : (
//                 "-"
//               )}
//             </span>
//           </Tooltip>
//         ),
//         width: "220px",
//       },
//       {
//         name: "Date",
//         field: "id",
//         selector: (row) =>
//           row.date ? moment(row.date).format("DD-MM-YYYY") : "-",
//         center: true,
//         width: "150px",
//       },
//       {
//         name: "Start Time",
//         field: "id",
//         selector: (row) =>
//           row.start_time ? moment(row.start_time).format("hh:mm a") : "-",
//         center: true,
//         width: "100px",
//       },
//       {
//         name: "End Time",
//         field: "id",
//         selector: (row) =>
//           row.end_time ? moment(row.end_time).format("hh:mm a") : "-",
//         center: true,
//         width: "100px",
//       },
//       {
//         name: "Total Hours",
//         field: "id",
//         sortable: true,
//         selector: (row) => (row.hours_of_working ? row.hours_of_working : "-"),
//         center: true,
//         width: "150px",
//       },
//     ],
//     []
//   );

//   const downloadExcel = () => {
//     const data = {
//       intEmployeeID: employeeID,
//       txtFromDate: fromDate,
//       txtToDate: toDate,
//       intType: 5,
//     };
//     config.method = "POST";
//     config.data = data;
//     config.url = reportsAPI;

//     axios(config)
//       .then(function (response) {
//         const newData = response.data["result"].map((row) => {
//           return row;
//         });

//         console.log(newData);
//         const workSheet = XLSX.utils.json_to_sheet(newData);
//         const workBook = XLSX.utils.book_new();
//         XLSX.utils.book_append_sheet(workBook, workSheet, "students");
//         //Buffer
//         let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
//         //Binary string
//         XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
//         //Download
//         XLSX.writeFile(
//           workBook,
//           `KG-project-report-${moment().format("DD-MM-YYYY")}.xlsx`
//         );
//       })
//       .catch(function (error) {
//         if (error.message === "Network Error") {
//           localStorage.removeItem("user");
//           window.location.href = window.location.origin + "/";
//         }
//         console.log(error);
//       });
//   };

//   const today = new Date();
//   const [eodReport, setEodReport] = useState([]);
//   const [fromDate, setFromDate] = React.useState(dayjs());
//   const [toDate, setToDate] = React.useState(dayjs());
//   const [totalCount, setTotalCount] = React.useState(0);

//   const getEodReport = (page, rowsPerPage) => {
//     const data = {
//       intEmployeeID: employeeID,
//       txtFromDate: fromDate,
//       txtToDate: toDate,
//       intLimit: rowsPerPage ? rowsPerPage : 10,
//       intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
//       intType: 10,
//     };

//     config.method = "POST";
//     config.data = JSON.stringify(data);
//     config.url = reportsAPI;

//     axios(config)
//       .then(function (response) {
//         console.log("response.data.result", response.data.result);
//         console.log("response.data.totalCount", response.data.totalCount);
//         setEodReport(response.data.result);
//         setTotalCount(response.data.totalCount);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

//   let tempRefs1 = useRef(false);

//   useEffect(() => {
//     if (tempRefs1.current === false) {
//       getEodReport(1, rowsPerPage);
//       return () => {
//         tempRefs1.current = true;
//       };
//     }
//   }, []);

//   const [employeeID, setEmployeeID] = useState(0);
//   const [EmployeeList, setEmployeeList] = useState([]);
//   let tempRefs2 = useRef(false);
//   console.log(eodReport);

//   useEffect(() => {
//     if (tempRefs2.current === false) {
//       const data = {
//         intType: 7,
//       };

//       config.method = "POST";
//       config.data = JSON.stringify(data);
//       config.url = handleHrMasterAPI;

//       axios(config)
//         .then(function (response) {
//           setEmployeeList(response.data.result);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//       return () => {
//         tempRefs2.current = true;
//       };
//     }
//   }, []);

//   return (
//     <Layout>
//       <Grid className="employee-form">
//         <Grid
//           container
//           spacing={2}
//           margin={`15px 0px`}
//           padding={`10px 15px 0px 0px`}
//         >
//           <Grid item xs={12} lg={3} md={3}>
//             <FormControl fullWidth>
//               <InputLabel id="demo-simple-select-label">
//                 Employee List
//               </InputLabel>
//               <Select
//                 labelId="Select Project"
//                 id="demo-simple-select"
//                 value={employeeID}
//                 label="Maristal Status"
//                 onChange={(e) => {
//                   setEmployeeID(e.target.value);
//                 }}
//               >
//                 {EmployeeList.map((row, index) => (
//                   <MenuItem value={row.id} key={index}>
//                     {row.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} lg={3} md={3}>
//             <LocalizationProvider
//               dateAdapter={AdapterDayjs}
//               format="DD-MM-YYYY"
//             >
//               <MobileDatePicker
//                 label="Select From Date"
//                 value={fromDate}
//                 inputFormat="DD/MM/YYYY"
//                 fullWidth
//                 onChange={(e) => {
//                   setFromDate(e);
//                 }}
//                 renderInput={(params) => <TextField {...params} fullWidth />}
//               />
//             </LocalizationProvider>
//           </Grid>
//           <Grid item xs={12} lg={3} md={3}>
//             <LocalizationProvider
//               dateAdapter={AdapterDayjs}
//               format="DD-MM-YYYY"
//             >
//               <MobileDatePicker
//                 label="Select To Date"
//                 value={toDate}
//                 inputFormat="DD/MM/YYYY"
//                 fullWidth
//                 onChange={(e) => {
//                   setToDate(e);
//                 }}
//                 renderInput={(params) => <TextField {...params} fullWidth />}
//               />
//             </LocalizationProvider>
//           </Grid>
//           <Grid item xs={12} lg={3} md={3}>
//             <LoadingButton
//               size="large"
//               variant="contained"
//               color="primary"
//               onClick={() => {
//                 getEodReport(1, rowsPerPage);
//               }}
//             >
//               Search
//             </LoadingButton>
//           </Grid>
//         </Grid>

//         <Grid className="form_design">
//           <Grid>
//             {eodReport.length > 0 && (
//               <>
//                 <Grid container spacing={3}>
//                   <Grid item xs={12} lg={6} md={6}></Grid>
//                   <Grid item xs={12} lg={6} md={6} textAlign="right">
//                     <Button
//                       className="btn-primary"
//                       onClick={downloadExcel}
//                       style={{ marginTop: "1rem" }}
//                     >
//                       Export to Excel
//                     </Button>
//                   </Grid>
//                   <Grid item xs={12} lg={6} md={6}></Grid>
//                 </Grid>
//               </>
//             )}
//             <DataTable
//               pagination
//               paginationServer
//               fixedHeader
//               fixedHeaderScrollHeight="550px"
//               columns={columns}
//               data={eodReport}
//               progressPending={loading}
//               paginationTotalRows={totalCount}
//               paginationDefaultPage={currentPage}
//               paginationRowsPerPageOptions={[1, 10, 25, 50, 100, 500, 1000]} //you can remove it later, just to have more pages
//               paginationPerPage={rowsPerPage}
//               onChangePage={handlePageChange}
//               // onSort={handleSort}
//               onChangeRowsPerPage={handleRowsPerPageChange}
//             />
//           </Grid>
//         </Grid>
//       </Grid>
//     </Layout>
//   );
// }

// export default EodReport;

import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Layout from "../../layout/Layout";
import { useNavigate } from "react-router-dom";
import { config, reportsAPI, handleHrMasterAPI } from "../../config";
import Pagination from "@mui/material/Pagination";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import dayjs from "dayjs";
import EodReportCard from "../../components/EodReportCard";

function EodReport() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();

  let tempRefs = useRef(false);

  const handlePageChange = (event, page) => {
    tempRefs.current = false;
    setCurrentPage(page);
    getEodReport(page, rowsPerPage);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    tempRefs.current = false;
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    getEodReport(1, newRowsPerPage);
  };

  const today = new Date();
  const [eodReport, setEodReport] = useState([]);
  const [fromDate, setFromDate] = React.useState(dayjs());
  const [toDate, setToDate] = React.useState(dayjs());
  const [totalCount, setTotalCount] = React.useState(0);

  const getEodReport = (page, rowsPerPage) => {
    setLoading(true);
    const data = {
      intEmployeeID: employeeID,
      txtFromDate: fromDate,
      txtToDate: toDate,
      intLimit: rowsPerPage ? rowsPerPage : 10,
      intOffset: rowsPerPage ? (page - 1) * rowsPerPage : 0,
      intType: 10,
    };

    config.method = "POST";
    config.data = JSON.stringify(data);
    config.url = reportsAPI;

    axios(config)
      .then(function (response) {
        // console.log("response.data.result", response.data.result);
        console.log("response.data.totalCount", response.data);
        setEodReport(response.data.result);
        setTotalCount(response.data.totalCount);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let tempRefs1 = useRef(false);

  useEffect(() => {
    if (tempRefs1.current === false) {
      getEodReport(1, rowsPerPage);
      return () => {
        tempRefs1.current = true;
      };
    }
  }, []);

  const [employeeID, setEmployeeID] = useState(0);
  const [EmployeeList, setEmployeeList] = useState([]);
  let tempRefs2 = useRef(false);

  useEffect(() => {
    if (tempRefs2.current === false) {
      const data = {
        intType: 7,
      };

      config.method = "POST";
      config.data = JSON.stringify(data);
      config.url = handleHrMasterAPI;

      axios(config)
        .then(function (response) {
          setEmployeeList(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      return () => {
        tempRefs2.current = true;
      };
    }
  }, []);

  const downloadExcel = () => {
    const data = {
      intEmployeeID: employeeID,
      txtFromDate: fromDate,
      txtToDate: toDate,
      intType: 10,
    };
    config.method = "POST";
    config.data = data;
    config.url = reportsAPI;

    axios(config)
      .then(function (response) {
        const newData = response.data["result"].map(({photo, ...row}) => {
          row.date = row.date ? moment(row.date).format("DD-MM-YYYY") : "-";
          row.department_name = row.department_name ? row.department_name : "-";
          row.employee_email = row.employee_email ? row.employee_email : "-";
          row.project_name = row.project_name ? row.project_name : "-";
          row.start_time = row.start_time
            ? moment(row.start_time).format("hh:mm A")
            : "-";
          row.end_time = row.end_time
            ? moment(row.end_time).format("hh:mm A")
            : "-";
          row.project_checkin_description = row.project_checkin_description
            ? row.project_checkin_description
            : "-";
          row.description = row.description ? row.description : "-";
          return row;
        });

        console.log(newData);

        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "EOD Report");

        // Correct usage of writeFile
        XLSX.writeFile(
          workBook,
          `Tracker-EOD-report-${moment().format("DD-MM-YYYY")}.xlsx`
        );
      })
      .catch(function (error) {
        if (error.message === "Network Error") {
          localStorage.removeItem("user");
          window.location.href = window.location.origin + "/";
        }
        console.log(error);
      });
  };

  return (
    <Layout>
      <Grid className="employee-form">
        <Grid
          container
          spacing={2}
          margin={`15px 0px`}
          padding={`10px 15px 0px 0px`}
        >
          <Grid item xs={12} lg={3} md={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Employee List
              </InputLabel>
              <Select
                labelId="Select Project"
                id="demo-simple-select"
                value={employeeID}
                label="Maristal Status"
                onChange={(e) => {
                  setEmployeeID(e.target.value);
                }}
              >
                {EmployeeList.map((row, index) => (
                  <MenuItem value={row.id} key={index}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select From Date"
                value={fromDate}
                inputFormat="DD/MM/YYYY"
                fullWidth
                onChange={(e) => {
                  setFromDate(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              format="DD-MM-YYYY"
            >
              <MobileDatePicker
                label="Select To Date"
                value={toDate}
                inputFormat="DD/MM/YYYY"
                fullWidth
                onChange={(e) => {
                  setToDate(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <LoadingButton
              size="large"
              variant="contained"
              color="primary"
              onClick={() => {
                getEodReport(1, rowsPerPage);
              }}
            >
              Search
            </LoadingButton>
          </Grid>
        </Grid>

        <Grid>
          {eodReport.length > 0 ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6} md={6}></Grid>
                <Grid item xs={12} lg={6} md={6} textAlign="right">
                  <Button
                    className="btn-primary"
                    onClick={downloadExcel}
                    style={{ marginTop: "1rem" }}
                  >
                    Export to Excel
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6} md={6}></Grid>
              </Grid>
              <Grid container spacing={2}>
                {eodReport.map((row) => (
                  <Grid item key={row.id} xs={12} sm={6} md={4}>
                    <EodReportCard data={row} />
                  </Grid>
                ))}
              </Grid>
              <Grid container justifyContent="center" marginTop="20px">
                <Pagination
                  count={Math.ceil(totalCount / rowsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  progressPending={loading}
                  shape="rounded"
                  size="large"
                  color="primary"
                  showFirstButton
                  showLastButton
                  sx={{ mt: theme.spacing(4) }}
                />
              </Grid>
            </>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "400px" }}
            >
              <Typography variant="h6" color="text.secondary">
                There is no data available
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}

export default EodReport;
